import { FilterParams } from 'Admin/components/Filter';
import buildQuery from 'odata-query';
import * as Request from 'Shared/helpers/request';
import { LocationEditData } from 'types/Locations';

export const fetchLocations = async ({
  filterParams,
  searchElement,
  skip = 0,
  top,
  id,
}: {
  filterParams?: FilterParams;
  searchElement?: string;
  skip?: number;
  top?: number;
  id?: number;
}) => {
  const locationDataList =
    'LocationId,Floors,Photos,Name,Attachments,Address1,Latitude,Longtitude';
  const LocationId =
    filterParams?.locationIds && filterParams.locationIds.length
      ? {
          and: {
            or: filterParams.locationIds.map((id) => ({
              '': id,
            })),
          },
        }
      : {};

  const search = searchElement
    ? {
        or: [
          {
            [`indexof(toupper(Name), '${String(
              searchElement.toUpperCase()
            )}')`]: {
              ge: 0,
            },
          },
        ],
      }
    : {};

  const query = buildQuery({
    filter: id ? { LocationId: id } : { LocationId, '': search },
    select: locationDataList,
  });

  return await Request.GET(
    `Api/Locations/odata${query}${
      top ? `&$top=${top}&$skip=${skip}` : ''
    }&$count=true`
  );
};

export const addLocation = async (data: LocationEditData, files: any) => {
  return await Request.POST({
    url: 'Api/Data/Location',
    params: data.item,
    files,
    type: 'Location',
  });
};

export const updateLocation = async (
  data: LocationEditData,
  files: any,
  deletedImages: string[]
) => {
  return await Request.PUT({
    url: `Api/Data/Location/${data.item?.LocationId}`,
    params: {
      Name: data.item?.Name,
      Address1: data.item?.Address1,
      Latitude: data.item?.Latitude,
      Longtitude: data.item?.Longtitude,
    },
    files,
    type: 'Location',
    deletedImages,
    id: data.item?.LocationId,
  });
};

export const deleteLocation = async (LocationId: number | undefined) => {
  return await Request.DELETE('Api/Data/Location', LocationId);
};

export const fetchFloors = async ({ id }: { id: number }) => {
  const floorDataList = 'FloorId,LocationId,Name,FloorPlans,Images';
  const query = buildQuery({
    filter: id ? { LocationId: id } : {},
    select: floorDataList,
  });
  return await Request.GET(`Api/Floors/odata${query}`);
};

export const addFloor = async (
  data: LocationEditData,
  files: any,
  LocationId: number | undefined
) => {
  return await Request.POST({
    url: 'Api/Data/Floor',
    params: {
      Name: data.item?.Name,
      LocationId,
    },
    files,
    type: 'Floor',
  });
};

export const updateFloor = async (
  data: LocationEditData,
  files: any,
  deletedImages: string[]
) => {
  return await Request.PUT({
    url: `Api/Data/Floor/${data.item?.FloorId}`,
    params: { Name: data.item?.Name },
    files,
    type: 'Floor',
    deletedImages,
    id: data.item?.FloorId,
  });
};

export const deleteFloor = async (FloorId: number | undefined) => {
  return await Request.DELETE('Api/Data/Floor', FloorId);
};

export const fetchAreas = async ({ id }: { id: number }) => {
  const areaDataList = 'AreaId,FloorId,LocationId,Name,Images';
  const query = buildQuery({
    filter: id ? { LocationId: id } : {},
    select: areaDataList,
  });
  return await Request.GET(`Api/Areas/odata${query}`);
};

export const addArea = async (data: LocationEditData, files: any) => {
  return await Request.POST({
    url: 'Api/Data/Area',
    params: data.item,
    files,
    type: 'Area',
  });
};

export const updateArea = async (
  data: LocationEditData,
  files: any,
  deletedImages: string[]
) => {
  return await Request.PUT({
    url: `Api/Data/Area/${data.item?.AreaId}`,
    params: { Name: data.item?.Name },
    files,
    type: 'Area',
    deletedImages,
    id: data.item?.AreaId,
  });
};

export const deleteArea = async (AreaId: number | undefined) => {
  return await Request.DELETE('Api/Data/Area', AreaId);
};

export const locationPerformancesByMonth = async ({
  startDate,
  endDate,
  locationId,
}: {
  startDate: string;
  endDate: string;
  locationId?: number;
}) => {
  return await Request.GET(
    `Api/PerformancesByMonthApi/Location/${startDate}/${endDate}/${locationId}`
  );
};
