import { Badge, Button, Text, TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import { customScrollYCss, serachCss, tagClasses } from 'Shared/helpers/styles';
import { Bell, Search, X } from 'tabler-icons-react';
import { Reminder } from 'types/Reminders';
import { useState } from 'react';

interface ReminderSearchProps {
  searchResult: Reminder[];
  setReminders(value?: Reminder[]): void;
  lastElementRef: any;
  searchParams: any;
  setSearchParams(value: any): void;
}

const ReminderSearch = ({
  searchResult,
  setReminders,
  lastElementRef,
  searchParams,
  setSearchParams,
}: ReminderSearchProps) => {
  const navigate = useNavigate();
  const [openList, setOpenList] = useState(true);

  return (
    <div className={'relative flex justify-end w-full'}>
      <TextInput
        css={serachCss}
        placeholder="Search reminders"
        icon={<Search size="20" className={'ml-2.5'} />}
        size="md"
        rightSection={
          searchParams.get('search') ? (
            <X
              className={'text-brand cursor-pointer'}
              onClick={() => {
                searchParams.delete('search');
                setSearchParams(searchParams);
              }}
            />
          ) : (
            ''
          )
        }
        autoFocus={!!searchParams.get('search')}
        value={searchParams.get('search') || ''}
        onChange={(event) => {
          searchParams.set('search', event.currentTarget.value);
          if (!event.currentTarget.value) {
            searchParams.delete('search');
          }
          setSearchParams(searchParams);
        }}
        onFocus={() => setOpenList(true)}
      />
      {searchParams.get('search') && openList ? (
        <div
          className={
            'absolute top-[65px] w-full max-h-[70vh] overflow-y-auto max-w-[550px]  border bg-white rounded-lg justify-center'
          }
          css={customScrollYCss}
        >
          <div
            className={
              'relative flex flex-col h-fit w-full max-w-[550px] border bg-white rounded-lg justify-center'
            }
          >
            {searchResult?.map((reminder, index) => (
              <div
                className={
                  'flex w-full h-fit py-3 pl-6 pr-3 border-0 border-b cursor-pointer items-center justify-between'
                }
                ref={
                  searchResult.length === index + 1 ? lastElementRef : undefined
                }
                key={reminder.ReminderId}
                onClick={() => navigate(`/reminders/${reminder.ReminderId}`)}
              >
                <Badge
                  className={
                    'flex bg-transparent h-fit w-full p-0 text-grey600 text-xs font-normal normal-case justify-start cursor-pointer'
                  }
                  leftSection={
                    <Bell className={'mr-2.5'} size={28} strokeWidth={1.5} />
                  }
                >
                  <Text className={'text-xs'}>Title</Text>
                  <Text className={'text-sm font-medium'}>
                    {reminder.Title}
                  </Text>
                </Badge>
                <div className={'flex'}>
                  {reminder.DueDate ? (
                    <div className={'text-center'}>
                      <Text className={'text-xs text-grey600'}>Due</Text>
                      <Badge
                        className={'text-xs font-normal normal-case h-5'}
                        color={'lime'}
                        size="sm"
                        variant="filled"
                      >
                        {format(new Date(reminder.DueDate || ''), 'dd.MM.yyyy')}
                      </Badge>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className={'text-center ml-3'}>
                    <Text className={'text-xs text-grey600'}>Assignee</Text>
                    <Badge className={`${tagClasses} text-xs text-black h-6`}>
                      {reminder.AssignUser}
                    </Badge>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {!searchResult?.length ? (
            <Text className={'my-4 text-center'}>No data</Text>
          ) : (
            <Button
              className={
                'w-full text-brand sticky bottom-0 bg-white py-2 rounded-none rounded-b hover:bg-red-300 text-semibold text-base'
              }
              radius={'xl'}
              onClick={() => {
                setReminders(searchResult);
                setOpenList(false);
              }}
            >
              View all
            </Button>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReminderSearch;
