import { Badge, Text } from '@mantine/core';
import { useMemo } from 'react';
import format from 'date-fns/format';
import { printLogoCss } from 'Shared/helpers/styles';
import { Check } from 'tabler-icons-react';
import { Checklist, ChecklistResult } from 'types/Checklist';
import PrintImages from './PrintImages';
import { Ticket } from 'types/Tickets';

interface PrintPageProps {
  componentRef: any;
  checklist?: Checklist;
  tickets?: Ticket[];
}

const PrintPage = ({ componentRef, checklist, tickets }: PrintPageProps) => {
  const percentage = useMemo(() => {
    return Math.round(
      ((checklist?.TotalPassed || 0) / (checklist?.TotalQuestion || 0)) * 100
    );
  }, [checklist]);

  const getTicketTitle = (questionId: number) => {
    return tickets?.find((ticket) => ticket.QuestionId === questionId)?.Title;
  };

  const getAnswerColor = (answer: string | null) => {
    if (answer === ChecklistResult.Passed) return 'bg-grey400';
    if (answer === ChecklistResult.Failed) return 'bg-orange100';
    return 'bg-grey';
  };

  return (
    <div
      ref={componentRef}
      className={'w-full h-[100vh] px-16 py-6 bg-grey300'}
    >
      <div className={'w-full flex justify-between  mb-7'}>
        <div className={'w-full'} css={printLogoCss} />
        <div className={'flex'}>
          <div className={'shadow-2xl h-[88px] py-2 px-2.5 rounded-2xl mr-3.5'}>
            <Badge
              className={
                'h-[72px] w-[72px] px-1 bg-transparent text-center text-xl text-green border-2 border-green rounded-[50%] text-bold'
              }
              variant="light"
              radius={'sm'}
            >
              {percentage}%
              <Text size="xs" className={'normal-case font-medium'}>
                Passed
              </Text>
            </Badge>
          </div>
          <div
            className={
              'shadow-2xl h-[88px] py-2 px-7 rounded-2xl flex flex-col justify-center'
            }
          >
            <Text className={'bg-transparent text-brand text-bold'}>
              Report generated
            </Text>
            <Text className={'bg-transparent font-bold'}>
              {format(new Date(), 'eo LLLL yyyy - hh:mm aaa')}
            </Text>
          </div>
        </div>
      </div>
      <div className={'w-full flex'}>
        <div className={'w-1/2 mr-4'}>
          <div className={'shadow-2xl flex h-10 text-brand font-medium'}>
            <Text className={'border-r px-4 h-full flex items-center w-2/5'}>
              Completed
            </Text>
            <Text className={'px-6 h-full flex items-center'}>
              Completed by
            </Text>
          </div>
          <div className={'mt-2 shadow-2xl flex h-16 font-bold'}>
            <Text className={'border-r px-4 h-full flex items-center w-2/5'}>
              {checklist?.CompletedDate
                ? format(new Date(checklist.CompletedDate), 'MMM d, yyyy')
                : ''}
            </Text>
            <Text className={'px-6 h-full flex items-center'}>
              {checklist?.CompletedUserName}
            </Text>
          </div>
        </div>
        <div className={'w-1/2'}>
          <div
            className={
              'shadow-2xl flex h-10 text-brand font-medium w-full justify-center'
            }
          >
            <Text className={'h-full flex items-center'}>Location</Text>
          </div>
          <div className={'mt-2 shadow-2xl flex h-16 font-bold justify-center'}>
            <Text className={'h-full flex items-center '}>
              {checklist?.LocationName}
            </Text>
          </div>
        </div>
      </div>
      <div className={'flex items-center mt-12'}>
        <Text className={'h-full flex items-center font-medium w-[170px]'}>
          Checklist report
        </Text>
        <div className={'h-0.5 w-full border-brand bg-brand mt-1'} />
      </div>
      <Text className={'text-brand font-extrabold text-3xl mb-14'}>
        Centre Standard Audit
      </Text>
      <>
        {checklist?.Sections?.map((section) => (
          <div
            key={section.CheckListSectionId}
            className={'w-full px-6 py-5 shadow-2xl'}
          >
            <Text className={'font-medium mb-6'}>{section.SectionName}</Text>
            {section.Questions.map((question) => (
              <div
                key={question.CheckListQuestionId}
                className={'w-full px-6 py-5 shadow-2xl bg-white mb-3.5'}
              >
                <div className={'flex items-center'}>
                  <div
                    className={
                      'rounded-2xl pl-2 pr-4 py-1.5 shadow-2xl w-fit flex items-center'
                    }
                  >
                    <div
                      className={`w-9 rounded-[50%] w-5 h-5 flex items-center justify-center ${getAnswerColor(
                        question.Answer
                      )}`}
                    >
                      <Check color="white" size={16} />
                    </div>
                    <Text className={'ml-2 font-medium'}>
                      {question.Answer || 'N/A'}
                    </Text>
                  </div>
                  <Text className={'text-lg ml-3 font-semibold'}>
                    {question.Question}
                  </Text>
                </div>
                {getTicketTitle(question.CheckListQuestionId) ? (
                  <div
                    className={'shadow-2xl bg-white px-6 py-2.5 flex mt-3.5'}
                  >
                    <Text className={'mr-6 font-semibold'}>Note</Text>
                    <Text>{getTicketTitle(question.CheckListQuestionId)}</Text>
                  </div>
                ) : (
                  ''
                )}
                <Text className={'font-semibold ml-6 mt-5'}>Attachments</Text>
                <div className={'flex bg-white w-full h-fit '}>
                  <PrintImages images={question.Attachments} />
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    </div>
  );
};

export default PrintPage;
