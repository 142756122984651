import { Badge, Button, LoadingOverlay, Text } from '@mantine/core';
import { Bell, Ticket } from 'tabler-icons-react';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { Reminder } from 'types/Reminders';
import { customScrollYCss } from 'Shared/helpers/styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchReminders } from 'Shared/data/Reminders';

export interface FilterParamsDashboard {
  duedate: Date[];
}

const dateClasses =
  'bg-transparent justify-start h-10 text-xs normal-case cursor-pointer';

const timeRange = ['Last 24h', 'Last 3h', 'Due in 3h', 'Due in 24h'];

const DashboardRminderSection = () => {
  const navigate = useNavigate();
  const now = new Date();
  const [filterParams, setFilterParams] = useState<FilterParamsDashboard>({
    duedate: [
      new Date(
        new Date(new Date().setHours(new Date().getHours() - 24)).toUTCString()
      ),
      new Date(now.toUTCString()),
    ],
  });
  const [reminderData, setReminderData] = useState<Reminder[] | []>([]);
  const [selectedTime, setSelectedTime] = useState<string>(timeRange[0]);
  const [reminders, setReminders] = useState<Reminder[] | undefined>(undefined);
  const perPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [skip, setSkip] = useState<number>(perPage * (pageNumber - 1));
  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  const { isFetching, refetch } = useQuery<
    {
      value: Reminder[];
      ['@odata.count']: number;
    },
    Error
  >(
    ['reminders'],
    () => fetchReminders({ filterParams, skip: skip, top: perPage }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data.value) {
          setReminders([...(reminders || []), ...data.value]);
          setHasMore(data['@odata.count'] > skip + perPage);
        }
      },
    }
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetch) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          setPageNumber(pageNumber + 1);
          setSkip(perPage * pageNumber);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetch, hasMore]
  );

  const refetchData = async () => {
    setIsRefetch(true);
    await refetch();
    setIsRefetch(false);
  };

  useEffect(() => {
    refetchData();
  }, [pageNumber, selectedTime]);

  const handleSelectDueDate = useCallback(
    (time: string) => {
      if (time === 'Last 24h') {
        setFilterParams({
          duedate: [
            new Date(
              new Date(
                new Date().setHours(new Date().getHours() - 24)
              ).toUTCString()
            ),
            new Date(now.toUTCString()),
          ],
        });
      }
      if (time === 'Last 3h') {
        setFilterParams({
          duedate: [
            new Date(
              new Date(
                new Date().setHours(new Date().getHours() - 3)
              ).toUTCString()
            ),
            new Date(now.toUTCString()),
          ],
        });
      }
      if (time === 'Due in 3h') {
        setFilterParams({
          duedate: [
            new Date(now.toUTCString()),
            new Date(
              new Date(
                new Date().setHours(new Date().getHours() + 3)
              ).toUTCString()
            ),
          ],
        });
      }
      if (time === 'Due in 24h') {
        setFilterParams({
          duedate: [
            new Date(now.toUTCString()),
            new Date(
              new Date(
                new Date().setHours(new Date().getHours() + 24)
              ).toUTCString()
            ),
          ],
        });
      }
      setSkip(0);
      setPageNumber(1);
      setReminders([]);
      setSelectedTime(time);
    },
    [selectedTime]
  );

  return (
    <div
      className={
        'relative h-[476px] bg-greyLight100 rounded-xl mb-3.5 px-4 py-1'
      }
    >
      <LoadingOverlay visible={isFetching || isRefetch} />
      <div className={'flex justify-between'}>
        <Badge
          className={
            'bg-transparent justify-start h-10 text-black100 font-medium text-base normal-case'
          }
          leftSection={<Bell size={25} strokeWidth={1.5} />}
        >
          Recent reminders
        </Badge>
        <div>
          {timeRange.map((time) => (
            <Badge
              key={time}
              className={`${dateClasses} hover:bg-brand hover:text-white ${
                time === selectedTime
                  ? 'text-brand font-medium'
                  : 'text-black100 font-light'
              } `}
              onClick={() => {
                if (selectedTime !== time) {
                  handleSelectDueDate(time);
                }
              }}
            >
              {time}
            </Badge>
          ))}
        </div>
      </div>
      <div className={'overflow-y-auto h-5/6 mt-3'} css={customScrollYCss}>
        {reminders?.length ? (
          reminders.map((reminder, index) => (
            <div
              ref={reminders.length === index + 1 ? lastElementRef : undefined}
              key={reminder.ReminderId}
              className={
                'flex flex-col justify-center bg-greyOpacity mb-2 text-black100 h-[70px] border rounded-lg cursor-pointer w-full px-4 py-2'
              }
            >
              <Text>{reminder.Title}Test</Text>
              <div className="flex items-center justify-between">
                <div>
                  {reminder.DueDate ? (
                    <Badge
                      className={
                        'text-xs font-normal normal-case h-6  bg-green100 mr-3'
                      }
                      size="sm"
                      variant="filled"
                    >
                      {format(new Date(reminder.DueDate || ''), 'dd.MM.yyyy')}
                    </Badge>
                  ) : (
                    ''
                  )}
                  {reminder.AssignUser ? (
                    <Badge
                      className={
                        'text-xs font-normal normal-case h-6 px-4 bg-white text-black'
                      }
                      size="sm"
                      variant="filled"
                    >
                      {reminder.AssignUser}
                    </Badge>
                  ) : (
                    ''
                  )}
                </div>
                {reminder.TicketId ? (
                  <Button
                    className={'flex justify-start text-brand p-0'}
                    variant="subtle"
                    leftIcon={
                      <Ticket size={20} className={'rotate-[270deg]'} />
                    }
                    onClick={() => navigate(`/tickets/${reminder.TicketId}`)}
                  >
                    View ticket
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          ))
        ) : (
          <Text className={'flex h-full w-full items-center justify-center'}>
            No data
          </Text>
        )}
      </div>
    </div>
  );
};

export default DashboardRminderSection;
