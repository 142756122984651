import { FormUIMode } from 'types/UI';

export enum UserRole {
  Regular = 'Regular',
  Admin = 'Admin',
}

export interface User {
  FirstName: string;
  Surname: string;
  JobTitle: string;
  PhoneNumber: string;
  Email: string;
  UserRole: UserRole;
  Id?: string;
  Password?: string;
  LockoutEnd?: Date | null;
  LockoutEnabled?: boolean;
}

export interface UserLoginFields {
  username: string;
  password: string;
}

export interface UserLoginCredentials extends UserLoginFields {
  keepLogin: boolean;
  returnUrl: string;
}

export interface MemberEditData {
  user: User;
  mode: FormUIMode;
}

export interface UserLoginCredential {
  ExpireUCTTime: string;
  RefreshTokenString: string;
  TokenString: string;
  UserEmail: string;
  UserId: string;
  UserName: string;
}
