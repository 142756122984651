import { LoadingOverlay, Modal, Text, Title } from '@mantine/core';
import { Plus, Shape } from 'tabler-icons-react';
import Actions from 'Admin/components/Actions';
import { Area, FormAreaMode, LocationEditData } from 'types/Locations';
import { carouselModalCss, customScrollYCss } from 'Shared/helpers/styles';
import { useEffect, useState } from 'react';
import { getImage } from 'Shared/helpers/request';
import LocationCarousel from './LocationCarousel';
import Carousel from 'Admin/components/Carousel';
import Floors from './Floors';

interface AreasProps {
  areas?: Area[];
  locationBeingEdited: LocationEditData;
  setLocationBeingEdited({ item, mode }: LocationEditData): void;
  openAreaDetails: number;
  setOpenAreaDetails(data: number): void;
  defaultRoute: string;
  isAdmin: boolean;
  hasFloor: boolean;
}

const Areas = ({
  areas,
  locationBeingEdited,
  setLocationBeingEdited,
  openAreaDetails,
  setOpenAreaDetails,
  defaultRoute,
  isAdmin,
  hasFloor,
}: AreasProps) => {
  const [images, setImages] = useState<string[] | []>([]);
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setAreaImage = async () => {
    setIsLoading(true);
    const currentArea = areas?.find((area) => area.AreaId === openAreaDetails);
    if (currentArea) {
      await Promise.all<any>(
        currentArea?.Images?.map(async (img) => {
          const response = await getImage(img);
          setImages((prevState) => [
            ...prevState.filter((item) => !item.includes(img)),
            response,
          ]);
        })
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (areas?.length && openAreaDetails > 0) {
      setAreaImage();
    } else {
      setImages([]);
    }
  }, [openAreaDetails, areas]);

  return (
    <div className={'w-full h-fit flex flex-col h-96'}>
      <div
        className={`${
          !hasFloor ? 'cursor-not-allowed opacity-50' : ''
        } flex mb-5`}
      >
        <Title className={'mb-1 mr-2.5'} order={5}>
          Floors areas
        </Title>
        {isAdmin ? (
          <div
            className={`${
              !hasFloor ? 'cursor-not-allowed' : ''
            } bg-brand rounded-[50%] text-white w-6 h-6 flex items-center justify-center cursor-pointer`}
            onClick={() => {
              if (hasFloor) {
                setLocationBeingEdited({
                  item: locationBeingEdited.item,
                  mode: FormAreaMode.AddArea,
                });
              }
            }}
          >
            <Plus size={18} className={'bg-brand rounded-[50%] text-white'} />
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className={'overflow-y-auto overflow-x-hidden p-1 h-80'}
        css={customScrollYCss}
      >
        {areas?.map((area, index) => (
          <div
            className={
              'flex flex-col min-h-[40px] pr-1 pl-2 shadow-xs mb-1 justify-center'
            }
            key={index}
          >
            <div
              className={`flex min-h-10 items-center justify-between w-full ${
                openAreaDetails === area.AreaId ? 'py-2' : ''
              }`}
            >
              <div>
                <Text className={'w-full flex items-center text-sm'}>
                  <Shape className={'mr-3'} size={18} />
                  {area.Name}
                </Text>
              </div>
              <div className={'flex items-center'}>
                <Actions
                  id={area.AreaId}
                  item={area}
                  openDetails={openAreaDetails}
                  onActionClicked={setLocationBeingEdited}
                  setOpenDetails={setOpenAreaDetails}
                  deleteMode={FormAreaMode.DeleteArea}
                  editMode={FormAreaMode.EditArea}
                  route={`${defaultRoute}/floors/${area.FloorId}/area/${area.AreaId}`}
                  goBack={`${defaultRoute}/floors/${area.FloorId}`}
                  isAdmin={isAdmin}
                />
              </div>
            </div>
            {openAreaDetails === area.AreaId ? (
              <div
                className={
                  'flex relative w-full left-0 z-10 overflow-x-auto h-32 items-center cursor-default'
                }
              >
                {isLoading ? (
                  <LoadingOverlay visible={isLoading} />
                ) : (
                  <>
                    {images.length ? (
                      <LocationCarousel
                        images={images}
                        setPreviewImage={setPreviewImage}
                      />
                    ) : (
                      <Text className={'w-full text-center'}>No data</Text>
                    )}
                  </>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <Modal
        css={carouselModalCss}
        opened={!!previewImage}
        onClose={() => setPreviewImage(undefined)}
      >
        <Carousel images={images} previewImage={previewImage} />
      </Modal>
    </div>
  );
};
export default Areas;
