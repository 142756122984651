import { Avatar, Badge, Text, Title } from '@mantine/core';
import formatDistance from 'date-fns/formatDistance';
import { useNavigate } from 'react-router-dom';
import { ListCheck, MapPin, Refresh } from 'tabler-icons-react';
import {
  avatarCss,
  customScrollYCss,
  tagClasses,
  ticketClasses,
  ticketCss,
} from 'Shared/helpers/styles';
import Actions from '../Actions';
import { FormTicketMode, Ticket, TicketEditData } from 'types/Tickets';
import { useEffect, useState } from 'react';
import { UserState } from 'types/Store';

interface TicketCardProps {
  ticket: Ticket;
  status: any;
  title?: string;
  description?: string;
  labels: any[] | undefined;
  CreateDate: Date | undefined;
  UpdateDate: Date | undefined;
  setTicketBeingEdited(data: TicketEditData): void;
  setEditMode(value: boolean): void;
  setIsPreviewTicket?(value: boolean): void;
  lastElementRef: any;
  currentUser: UserState;
}

const TicketCard = ({
  ticket,
  status,
  title,
  description,
  labels,
  CreateDate,
  UpdateDate,
  setTicketBeingEdited,
  setEditMode,
  lastElementRef,
  currentUser,
}: TicketCardProps) => {
  const navigate = useNavigate();
  const [openDetails, setOpenDetails] = useState<number>(0);

  useEffect(() => {
    openDetails &&
      setTicketBeingEdited({ item: ticket, mode: FormTicketMode.EditTicket });
  }, [openDetails]);
  const now = new Date();

  return (
    <div
      ref={lastElementRef}
      className={`${ticketClasses} h-fit min-w-[500px]`}
      css={ticketCss}
    >
      <div className={'flex justify-between h-10 border-b items-center px-4'}>
        <div className={'flex'}>
          <div className={'flex text-xs items-center mr-5'}>
            <span className={'text-gray-400 mr-1.5'}>Raised</span>
            {CreateDate ? (
              <Badge color="gray" className={tagClasses}>
                {formatDistance(new Date(CreateDate), now, {
                  addSuffix: true,
                })}
              </Badge>
            ) : (
              ''
            )}
          </div>
          {UpdateDate ? (
            <div className={'flex text-xs'}>
              <Badge
                className={
                  'text-gray-400 mr-1.5 bg-transparent normal-case font-normal p-0'
                }
                variant="light"
                leftSection={<Refresh className={'text-black'} size={18} />}
              >
                Updated
              </Badge>

              <Badge color="gray" className={tagClasses}>
                {formatDistance(new Date(UpdateDate), now, {
                  addSuffix: true,
                })}
              </Badge>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={'flex gap-2 flex-col items-end'}>
          <div className={'flex text-xs text-gray-400 items-center'}>
            <span>Status</span>
            <Badge
              className={'px-4 ml-1.5 text-xs font-normal normal-case'}
              color={'lime'}
              size="sm"
              variant="filled"
            >
              {status}
            </Badge>
          </div>
        </div>
      </div>
      <div className={'px-4'}>
        <div className={'flex justify-between'}>
          <Badge
            color="gray"
            className={'flex h-6 bg-transparent p-0 font-normal normal-case'}
            leftSection={<MapPin color="black" size={15} strokeWidth={1.5} />}
          >
            {ticket.LocationName}
          </Badge>
          <div className={'flex items-center'}>
            <Text
              className={'text-right text-gray-400 text-xs leading-3 mr-2.5'}
            >
              Reporter
            </Text>
            <Badge
              sx={{ paddingLeft: 0 }}
              size="lg"
              radius="xl"
              color="gray"
              className={
                'bg-transparent p-0 text-black text-xs font-normal normal-case'
              }
              leftSection={
                <div>
                  <Avatar
                    className={'mt-px'}
                    alt="Avatar for badge"
                    css={avatarCss}
                    size={20}
                    radius="xl"
                  >
                    {(ticket.CreateUser || '')
                      .match(/\b\w/g)
                      ?.toString()
                      .slice(0, 1)
                      .toUpperCase()}
                    {(ticket.CreateUser || '')
                      .match(/\b\w/g)
                      ?.toString()
                      .slice(2, 3)
                      .toUpperCase()}
                  </Avatar>
                </div>
              }
            >
              {ticket.CreateUser}
            </Badge>
            {ticket.CheckListId ? (
              <Badge
                className={
                  'bg-transparent p-0 text-brand text-xs font-medium normal-case cursor-pointer ml-5'
                }
                leftSection={<ListCheck size={20} />}
                onClick={() => navigate(`/checklists/${ticket.CheckListId}`)}
              >
                Checklist
              </Badge>
            ) : (
              ''
            )}
          </div>
        </div>
        <div>
          <Title className={'font-medium'} order={4}>
            {title}
          </Title>
          <Text className={'text-sm mt-2 mb-3'}>{description}</Text>
        </div>
        <div className={'flex justify-between items-center'}>
          <div
            className={
              'flex flex-wrap w-[calc(100%-120px)] items-center gap-x-0.5 gap-y-1 mt-0.5 max-h-11 overflow-y-auto'
            }
            css={customScrollYCss}
          >
            {labels?.map((label, index) => (
              <Badge
                key={index}
                color="gray"
                className={`${tagClasses} mr-1.5`}
              >
                {label}
              </Badge>
            ))}
          </div>
          <Actions
            item={ticket}
            id={ticket.FDSTicketId}
            openDetails={openDetails}
            onActionClicked={setTicketBeingEdited}
            setEditMode={setEditMode}
            setOpenDetails={setOpenDetails}
            deleteMode={FormTicketMode.DeleteTicket}
            editMode={FormTicketMode.EditTicket}
            route={`/tickets/${ticket.FDSTicketId}`}
            isAdmin={true}
            editRoute={`/tickets/${ticket.FDSTicketId}/edit`}
            hideDelete={!currentUser?.isAdmin}
          />
        </div>
      </div>
    </div>
  );
};
export default TicketCard;
