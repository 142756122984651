import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useEffect } from 'react';
import { TextInput } from '@mantine/core';
import { LocationEditData } from 'types/Locations';

interface PlacesAutocompleteProps {
  isLoaded: boolean;
  locationData: LocationEditData;
  setAddress(value: string): void;
  setLocation(value?: { lat: number; lng: number }): void;
}

const PlacesAutocomplete = ({
  isLoaded,
  locationData,
  setAddress,
  setLocation,
}: PlacesAutocompleteProps) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    init,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    if (!e.target.value) {
      setLocation(undefined);
    }
  };

  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      setValue(description, false);
      clearSuggestions();
      setAddress(description);
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setLocation({ lat, lng });
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          className={'cursor-pointer'}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  useEffect(() => {
    if (isLoaded) {
      if (
        locationData.item &&
        Number(locationData.item.Latitude) &&
        Number(locationData.item.Longtitude)
      ) {
        setValue(String(locationData.item?.Address1));
        setLocation({
          lat: Number(locationData.item.Latitude),
          lng: Number(locationData.item.Longtitude),
        });
      }
      init();
    }
  }, [isLoaded]);

  return (
    <div ref={ref} className={'mb-7'}>
      <TextInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Location address"
        className={'h-16 min-w-[420px] relative'}
        size={'xl'}
        radius={'md'}
        required
      />
      {status === 'OK' && (
        <ul className="absolute bg-white w-[420px] border px-4 py-2 z-50">
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
