import { Avatar, Badge, Select } from '@mantine/core';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchAllUsers } from 'Shared/data/Users';
import { avatarCss, inputCss } from 'Shared/helpers/styles';
import { Mail, Phone } from 'tabler-icons-react';
import { User } from 'types/Users';
import CustomSelect from '../CustomSelect';

interface UserInfoProps {
  name: string;
  email?: string;
  phoneNumber?: string;
  canBeEdit?: boolean;
  id?: string;
  setAssignUser?(value?: string): void;
}

const ticketInfoStyles = `h-12 bg-white justify-start pl-6 text-black text-xs font-normal normal-case`;

const UserInfo = ({
  name,
  email,
  phoneNumber,
  canBeEdit,
  id,
  setAssignUser,
}: UserInfoProps) => {
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const { refetch: refetchUsers } = useQuery<
    { value: User[]; ['@odata.count']: number },
    Error
  >(
    ['users'],
    async () =>
      id
        ? await fetchAllUsers({
            id,
          })
        : [],
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if (response && response.value && response.value.length) {
          if (id) {
            setCurrentUser(response.value[0]);
          }
          setUsers(response.value);
        }
      },
    }
  );

  useMemo(async () => {
    if (id) {
      await refetchUsers();
    }
  }, [id]);
  const usersList = useMemo(() => {
    return (
      users?.map((user, index) => ({
        value: user.Id || '',
        label: `${user.FirstName} ${user.Surname}`,
        index,
      })) ?? []
    );
  }, [users]);

  return (
    <>
      {canBeEdit && setAssignUser ? (
        <>
          <CustomSelect
            setData={(list: User[]) =>
              setUsers(
                currentUser
                  ? [
                      currentUser,
                      ...list.filter(
                        (user: User) => user.Id !== currentUser?.Id
                      ),
                    ]
                  : [...(list || [])]
              )
            }
            request={fetchAllUsers}
            options={usersList}
            placeholder={'Who to assign'}
            classNames={'flex w-fit rounded-xl mb-4 shadow-xs'}
            onChange={setAssignUser}
            defaultValue={String(id)}
            dataList={users}
          />
        </>
      ) : (
        <div className={'flex w-full rounded-xl mb-4 shadow-xs'}>
          <Badge
            className={`w-2/6 rounded-l-xl ${ticketInfoStyles}`}
            radius="xs"
            leftSection={
              <Avatar
                className={'mr-3 mt-px'}
                alt="Avatar for badge"
                css={avatarCss}
                size={20}
                radius="xl"
              >
                {name.match(/\b\w/g)?.toString().slice(0, 1).toUpperCase()}
                {name.match(/\b\w/g)?.toString().slice(2, 3).toUpperCase()}
              </Avatar>
            }
          >
            {name}
          </Badge>
          <Badge
            className={`w-2/5 border-x-stone rounded-none ${ticketInfoStyles}`}
            radius="xs"
            leftSection={<Mail className={'mr-3'} size={20} strokeWidth={2} />}
          >
            {email}
          </Badge>
          <Badge
            className={`w-2/6 min-w-44 w-full rounded-r-xl ${ticketInfoStyles}`}
            radius="xs"
            leftSection={<Phone className={'mr-3'} size={20} strokeWidth={2} />}
          >
            {phoneNumber}
          </Badge>
        </div>
      )}
    </>
  );
};

export default UserInfo;
