import { Badge, Button, Input, Select, Text, Textarea } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  buttonIconClasses,
  inputCss,
  inputTimeCss,
  tagClasses,
  textAreaCss,
} from 'Shared/helpers/styles';
import { Calendar, CircleMinus, Plus, Clock2, Clock } from 'tabler-icons-react';
import { Ticket, TicketEditData } from 'types/Tickets';
import { Location } from 'types/Locations';
import { User } from 'types/Users';
import { fetchLocations } from 'Shared/data/Locations';
import { useQuery } from 'react-query';
import { fetchAllUsers } from 'Shared/data/Users';
import CustomSelect from 'Admin/components/CustomSelect';

interface CreateTicketProps {
  data: TicketEditData;
  onSubmit(data: TicketEditData): void;
  setIsAddLabel(value: boolean): void;
  labels?: string[] | [];
  setSelectedLebels(data: string[] | []): void;
  labelsId: string[];
  setLabelsId(value: string[]): void;
  isMutating: boolean;
  isChecklist?: boolean;
}

interface handleAddLabelProps {
  label: string;
  index: number;
}

const CreateTicket = ({
  data,
  onSubmit,
  setIsAddLabel,
  labels,
  setSelectedLebels,
  labelsId,
  setLabelsId,
  isMutating,
  isChecklist,
}: CreateTicketProps) => {
  const [locationId, setLocationId] = useState<number | undefined>(undefined);
  const [locations, setLocations] = useState<Location[] | undefined>(undefined);
  const [assignUser, setAssignUser] = useState<string>('');
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [dueDate, setDueDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);
  const { register, handleSubmit } = useForm<Ticket>({
    defaultValues: data.item,
  });
  const submitHandler: SubmitHandler<Ticket> = (item) => {
    onSubmit({
      item: {
        ...item,
        LocationId: locationId,
        AssignUserId: assignUser,
        DueDate:
          new Date(
            String(dueDate).slice(0, 15).concat(String(time).slice(15)) || ''
          ).toUTCString() || undefined,
      },
      mode: data.mode,
    });
  };

  const locationsList = useMemo(() => {
    return (
      locations?.map((location, index) => ({
        value: String(location.LocationId),
        label: location.Name,
        index,
      })) || []
    );
  }, [locations]);

  const usersList = useMemo(() => {
    return (
      users?.map((user, index) => ({
        value: user.Id || '',
        label: `${user.FirstName} ${user.Surname}`,
        index,
      })) ?? []
    );
  }, [users]);

  const handleDeleteLabel = ({ label, index }: handleAddLabelProps) => {
    if (labels?.length) {
      setSelectedLebels(labels.filter((tag) => tag !== label));
    }
    setLabelsId(labelsId.filter((_, i) => i !== index));
  };

  return (
    <div className={'w-full h-fit bg-white'}>
      <div className={'flex justify-between'}>
        <Text className={'text-base p-4'}>Add ticket</Text>
        {isChecklist ? <Text className={'text-base p-4'}>3/3</Text> : ''}
      </div>

      <form
        className={'flex flex-col justify-between h-fit items-center p-4'}
        onSubmit={handleSubmit(submitHandler)}
      >
        <Input
          css={inputCss}
          className={'w-full mb-3.5'}
          placeholder="Ticket name"
          required
          {...register('Title', { required: true })}
        />
        <DatePicker
          css={inputCss}
          className={'w-full mb-3.5'}
          placeholder="Due date"
          rightSection={<Calendar size={30} />}
          onChange={(value) => setDueDate(String(value))}
          required
        />
        <TimeInput
          css={inputTimeCss}
          className={'w-full mb-3.5'}
          rightSection={<Clock size={30} className={'text-brand'} />}
          onChange={(value) => setTime(String(value))}
          format={'12'}
          required
        />
        <CustomSelect
          setData={setLocations}
          request={fetchLocations}
          options={locationsList}
          placeholder={'Location'}
          classNames={'w-full mb-3.5'}
          onChange={setLocationId}
          defaultValue={String(data.item?.LocationId)}
          dataList={locations}
          cssStyles={inputCss}
        />
        <CustomSelect
          setData={setUsers}
          request={fetchAllUsers}
          options={usersList}
          placeholder={'Who to assign'}
          classNames={'w-full mb-3.5'}
          onChange={setAssignUser}
          defaultValue={String(data.item?.AssignUserId)}
          dataList={users}
          cssStyles={inputCss}
        />
        <Textarea
          css={textAreaCss}
          className={'w-full'}
          placeholder="Ticket description"
          required
          {...register('Description', { required: true })}
        />
        <div className={'flex items-center w-full mt-6'}>
          <Text className={'text-base mr-3'}>Labels</Text>
          <div
            className={buttonIconClasses}
            onClick={() => setIsAddLabel(true)}
          >
            <Plus size={18} className={'bg-brand rounded-[50%] text-white'} />
          </div>
        </div>
        <div className={'flex flex-wrap w-full  mt-3 mb-7 h-28'}>
          {labels?.map((label, index) => (
            <Badge
              className={`${tagClasses} h-7 mr-1.5 mb-2.5 rounded`}
              key={index}
              leftSection={
                <CircleMinus
                  onClick={() => handleDeleteLabel({ label, index })}
                  className={'text-brand cursor-pointer'}
                  size={20}
                />
              }
            >
              {label}
            </Badge>
          ))}
        </div>
        <Button
          className={'bg-brand w-full text-lg'}
          size="md"
          type="submit"
          disabled={isMutating}
        >
          Finish
        </Button>
      </form>
    </div>
  );
};

export default CreateTicket;
