import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, TextInput } from '@mantine/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { Plus, Search } from 'tabler-icons-react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import PageHeader from 'Admin/components/PageHeader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import {
  addTemplate,
  fetchChecklistsTemplates,
  updateTemplate,
} from 'Shared/data/Templates';
import { Template, FormTemplateMode, TemplateEditData } from 'types/Template';
import TemplateList from 'Admin/components/Template/TemplateList';
import CreateTemplate from 'Admin/components/Template/CreateTemplate';
import TemplateInfo from 'Admin/components/Template/TemplateInfo';
import { fetchChecklists } from 'Shared/data/Checklists';
import { Checklist } from 'types/Checklist';
import { Location } from 'types/Locations';
import { fetchLocations } from 'Shared/data/Locations';
import TemplateSearch from 'Admin/components/Template/TemplateSearch';
import useDebounce from 'Shared/hooks/useDebounce';

const notificationMessages = {
  [FormTemplateMode.Add]: 'The new template has been added successfully',
  [FormTemplateMode.Edit]: 'template details saved successfully',
  error:
    'Error encountered. Please try again or contact the administrator if the issue persists.',
};

const Templates = () => {
  const { templateId, mode } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultEditData = { item: undefined, mode: undefined };
  const [templateBeingEdited, setTemplateBeingEdited] =
    useState<TemplateEditData>(defaultEditData);
  const [openDetails, setOpenDetails] = useState<number>(0);
  const [templates, setTemplates] = useState<Template[] | []>([]);
  const [searchResult, setSearchResult] = useState<Template[] | []>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [checkLists, setCheckLists] = useState<Checklist[] | undefined>(
    undefined
  );
  const perPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchPageNumber, setSearchPageNumber] = useState<number>(1);
  const [skipSearch, setSkipSearch] = useState<number>(
    perPage * (searchPageNumber - 1)
  );
  const [searchHasMore, setSearchHasMore] = useState<boolean>(false);
  const [locations, setLocations] = useState<Checklist[] | undefined>(
    undefined
  );
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deferredSearchQuery = useDebounce(
    useDeferredValue(searchParams.get('search') || ''),
    500
  );

  const { isFetching, isError, data, error, refetch } = useQuery<
    { value: Template[]; ['@odata.count']: number },
    Error
  >(
    ['templates'],
    async () =>
      await fetchChecklistsTemplates({
        searchElement: deferredSearchQuery,
        skip: searchParams.get('search') ? skipSearch : 0,
        top: searchParams.get('search')
          ? perPage
          : Number(searchParams.get('top') || perPage),
        id: Number(templateId),
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
    }
  );
  const { isFetching: isFetchingChecklists } = useQuery<
    { value: Checklist[]; ['@odata.count']: number },
    Error
  >(['checkLists'], () => fetchChecklists({}), {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data) {
        setCheckLists(data.value);
      }
    },
  });
  const { isFetching: isFetchingLocations } = useQuery<
    { value: Location[]; ['@odata.count']: number },
    Error
  >(['locations'], () => fetchLocations({}), {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data) {
        setLocations(data.value);
      }
    },
  });

  const setData = useCallback(
    async ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: Template[];
      ['@odata.count']: number;
    }) => {
      setIsLoading(true);
      if (data && !searchParams.get('search')) {
        setTemplates([...(data || [])]);
        setHasMore(dataCount > Number(searchParams.get('top') || perPage));
      }
      if (templateId && !searchParams.get('search')) {
        !mode
          ? setOpenDetails(data[0].CheckListTemplateId || 0)
          : setOpenDetails(0);
        setTemplateBeingEdited({
          item: data[0],
          mode: mode === 'edit' ? FormTemplateMode.Edit : undefined,
        });
      }
      if (searchParams.get('search')) {
        if (!templates?.length) {
          const response = await fetchChecklistsTemplates({
            top: Number(searchParams.get('top') || perPage),
            skip: 0,
          });
          if (response && response.value) {
            setTemplates(response.value);
            setHasMore(dataCount > Number(searchParams.get('top') || perPage));
          }
        }
        setSearchResult([
          ...(skipSearch === 0 ? [] : searchResult || []),
          ...(data || []),
        ]);
        setSearchHasMore(dataCount > skipSearch + perPage);
      }
      setIsLoading(false);
    },
    [templates, searchParams.get('search'), searchPageNumber, skipSearch]
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetch || searchParams.get('search') || templateId) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          searchParams.set(
            'top',
            String(perPage * (Number(searchParams.get('page') || 1) + 1))
          );
          searchParams.set(
            'page',
            String(Number(searchParams.get('page') || 1) + 1)
          );
          setSearchParams(searchParams);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetch, hasMore]
  );

  const observerSearch: any = useRef();
  const lastSearchElementRef = useCallback(
    (node: any) => {
      if (isRefetch || !searchParams.get('search')) return;
      if (observerSearch.current) observerSearch.current.disconnect();
      observerSearch.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          searchHasMore &&
          node !== observerSearch.current
        ) {
          setSearchPageNumber(searchPageNumber + 1);
          setSkipSearch(perPage * searchPageNumber);
        }
      });
      if (node) observerSearch.current.observe(node);
    },
    [isRefetch, searchHasMore, searchParams.get('search')]
  );

  const refetchData = async () => {
    setIsRefetch(true);
    await refetch();
    setIsRefetch(false);
  };

  useEffect(() => {
    if (!templateId) {
      refetchData();
    }
    if (!deferredSearchQuery) {
      setSearchResult([]);
    }
  }, [searchPageNumber]);

  const mutationRequst = (params: TemplateEditData) => {
    if (templateBeingEdited?.mode === FormTemplateMode.Edit)
      return updateTemplate(params);

    return addTemplate(params);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationRequst, {
    onSuccess: () => {
      queryClient.invalidateQueries(['templates']);
      setTemplateBeingEdited(defaultEditData);
    },
  });
  const onSubmit = (data: TemplateEditData) => {
    mutation.mutate({
      item: {
        ...data.item,
      },
      mode: data.mode,
    });
  };

  useEffect(() => {
    if (mutation.variables) {
      const data = mutation.variables;

      if (mutation.isSuccess && data.mode) {
        showOkToast(notificationMessages[data.mode]);
      } else if (mutation.isError) {
        showErrorToast(notificationMessages['error']);
      }
    }
  }, [mutation.variables, mutation.isSuccess, mutation.isError]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={'members'}>
        <PageHeader pageName={'Templates'}>
          {!openDetails ? (
            <TemplateSearch
              searchResult={searchResult}
              setTemplates={setTemplates}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              lastElementRef={lastSearchElementRef}
            />
          ) : (
            ''
          )}
          <Button
            className={'bg-brand ml-4'}
            leftIcon={<Plus size={20} />}
            size="md"
            onClick={() =>
              setTemplateBeingEdited({
                item: undefined,
                mode: FormTemplateMode.Add,
              })
            }
          >
            New tempalate
          </Button>
        </PageHeader>
        {openDetails ? (
          <TemplateInfo
            template={templateBeingEdited.item}
            checkLists={checkLists}
            locations={locations}
            hasOverlay={
              isLoading ||
              isFetching ||
              isFetchingLocations ||
              isFetchingChecklists
            }
          />
        ) : (
          <TemplateList
            hasOverlay={
              isLoading ||
              isFetching ||
              isFetchingLocations ||
              isFetchingChecklists
            }
            templates={templates}
            isError={isError}
            error={error ?? undefined}
            onActionClicked={setTemplateBeingEdited}
            openDetails={openDetails}
            setOpenDetails={setOpenDetails}
            templateBeingEdited={templateBeingEdited}
            onSubmit={onSubmit}
            lastElementRef={lastElementRef}
          />
        )}
        {templateBeingEdited?.mode === FormTemplateMode.Add ? (
          <CreateTemplate
            opened={!!templateBeingEdited?.mode}
            onClose={() => setTemplateBeingEdited(defaultEditData)}
            mode={templateBeingEdited.mode}
            item={templateBeingEdited.item}
            onSubmit={onSubmit}
          />
        ) : (
          ''
        )}
      </div>
    </DndProvider>
  );
};

export default Templates;
