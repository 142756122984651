import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Modal, TextInput } from '@mantine/core';
import { Plus, Search } from 'tabler-icons-react';
import { css } from '@emotion/react';
import PageHeader from 'Admin/components/PageHeader';
import MemberList from 'Admin/components/Member/MemberList';
import { MemberEditData, User } from 'types/Users';
import { FormUIMode } from 'types/UI';
import { useStore } from 'Shared/data/Store';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  defaultUser,
  deleteUsers,
  fetchAllUsers,
  addUsers,
  setUserPassword,
  updateUsers,
} from 'Shared/data/Users';
import MemberEditForm from 'Admin/components/Member/MemberEditForm';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import ConfirmModal from 'Admin/components/Member/ConfirmModal';
import { confirmModalCss } from 'Shared/helpers/styles';
import MemberSearch from 'Admin/components/Member/MemberSearch';
import { useSearchParams } from 'react-router-dom';
import useDebounce from 'Shared/hooks/useDebounce';

const notificationMessages = {
  [FormUIMode.Add]: 'The new user has been added successfully',
  [FormUIMode.Edit]: 'User details saved successfully',
  [FormUIMode.Delete]: 'The user has been deleted successfully',
  [FormUIMode.SetPassword]: 'The password has been set successfully',
  error:
    'Error encountered. Please try again or contact the administrator if the issue persists.',
};

function Members() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userBeingEdited, setUserBeingEdited] = useState<{
    user?: User;
    mode?: FormUIMode;
  }>();
  const [users, setUsers] = useState<User[] | []>([]);
  const [searchResult, setSearchResult] = useState<User[] | []>([]);
  const perPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchPageNumber, setSearchPageNumber] = useState<number>(1);
  const [skipSearch, setSkipSearch] = useState<number>(
    perPage * (searchPageNumber - 1)
  );
  const [searchHasMore, setSearchHasMore] = useState<boolean>(false);
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deferredSearchQuery = useDebounce(
    useDeferredValue(searchParams.get('search') || ''),
    500
  );

  const { currentUser } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
    currentUser: state.user,
  }));

  const { isFetching, isError, error, refetch } = useQuery<
    { value: User[]; ['@odata.count']: number },
    Error
  >(
    ['members'],
    () =>
      fetchAllUsers({
        searchElement: deferredSearchQuery,
        skip: searchParams.get('search') ? skipSearch : 0,
        top: searchParams.get('search')
          ? perPage
          : Number(searchParams.get('top') || perPage),
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
    }
  );

  const setData = useCallback(
    async ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: User[];
      ['@odata.count']: number;
    }) => {
      setIsLoading(true);
      if (data && !searchParams.get('search')) {
        setUsers([...(data || [])]);
        setHasMore(dataCount > Number(searchParams.get('top') || perPage));
      }
      if (searchParams.get('search')) {
        if (!users?.length) {
          const response = await fetchAllUsers({
            top: Number(searchParams.get('top') || perPage),
            skip: 0,
          });
          if (response && response.value) {
            setUsers(response.value);
            setHasMore(dataCount > Number(searchParams.get('top') || perPage));
          }
        }
        setSearchResult([
          ...(skipSearch === 0 ? [] : searchResult || []),
          ...(data || []),
        ]);
        setSearchHasMore(dataCount > skipSearch + perPage);
      }
      setIsLoading(false);
    },
    [users, searchParams.get('search'), searchPageNumber, skipSearch]
  );

  const mutationRequst = (params: MemberEditData) => {
    if (userBeingEdited?.mode === FormUIMode.Add) return addUsers(params);
    if (userBeingEdited?.mode === FormUIMode.Edit) return updateUsers(params);
    if (userBeingEdited?.mode === FormUIMode.SetPassword) {
      return setUserPassword(params);
    }

    return deleteUsers(params);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationRequst, {
    onSuccess: () => {
      queryClient.invalidateQueries(['members']);
      setUserBeingEdited(undefined);
    },
  });
  const onSubmit = (data: MemberEditData) => {
    mutation.mutate({
      user: { ...data.user, Id: userBeingEdited?.user?.Id },
      mode: data.mode,
    });
  };

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetch || searchParams.get('search')) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          searchParams.set(
            'top',
            String(perPage * (Number(searchParams.get('page') || 1) + 1))
          );
          searchParams.set(
            'page',
            String(Number(searchParams.get('page') || 1) + 1)
          );
          setSearchParams(searchParams);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetch, hasMore]
  );

  const observerSearch: any = useRef();
  const lastSearchElementRef = useCallback(
    (node: any) => {
      if (isRefetch || !searchParams.get('search')) return;
      if (observerSearch.current) observerSearch.current.disconnect();
      observerSearch.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          searchHasMore &&
          node !== observerSearch.current
        ) {
          setSearchPageNumber(searchPageNumber + 1);
          setSkipSearch(perPage * searchPageNumber);
        }
      });
      if (node) observerSearch.current.observe(node);
    },
    [isRefetch, searchHasMore, searchParams.get('search')]
  );

  const refetchMembers = async () => {
    setIsRefetch(true);
    await refetch();
    setIsRefetch(false);
  };

  useEffect(() => {
    if (deferredSearchQuery) {
      refetchMembers();
    } else {
      setSearchResult([]);
    }
  }, [searchPageNumber]);

  useEffect(() => {
    if (mutation.variables) {
      const data = mutation.variables;

      if (mutation.isSuccess) {
        showOkToast(notificationMessages[data.mode]);
      } else if (mutation.isError) {
        showErrorToast(notificationMessages['error']);
      }
    }
  }, [mutation.variables, mutation.isSuccess, mutation.isError]);

  return (
    <div className={'members'}>
      <PageHeader pageName={'Members'}>
        <MemberSearch
          searchResult={searchResult}
          setUserBeingEdited={setUserBeingEdited}
          setUsers={setUsers}
          lastElementRef={lastSearchElementRef}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <Button
          className={'bg-brand ml-4'}
          leftIcon={<Plus size={20} />}
          size="md"
          disabled={!currentUser.isAdmin}
          onClick={() =>
            setUserBeingEdited({ user: defaultUser, mode: FormUIMode.Add })
          }
        >
          Add member
        </Button>
      </PageHeader>

      <MemberList
        hasOverlay={isLoading || isFetching || isRefetch}
        isError={isError}
        error={error ?? undefined}
        currentUser={currentUser}
        onActionClicked={setUserBeingEdited}
        users={users}
        lastElementRef={lastElementRef}
      />

      {userBeingEdited?.mode === FormUIMode.Edit ||
      userBeingEdited?.mode === FormUIMode.Add ? (
        <Modal
          css={confirmModalCss}
          centered
          opened={Boolean(userBeingEdited)}
          onClose={() => setUserBeingEdited(undefined)}
        >
          {userBeingEdited?.user && (
            <MemberEditForm
              user={userBeingEdited.user}
              mode={userBeingEdited.mode}
              onSubmit={onSubmit}
              isMutating={mutation.isLoading}
              hasMutationError={mutation.isError}
              onClose={() => setUserBeingEdited(undefined)}
            />
          )}
        </Modal>
      ) : (
        ''
      )}
      {userBeingEdited?.user &&
      (userBeingEdited?.mode == FormUIMode.Delete ||
        userBeingEdited?.mode === FormUIMode.SetPassword) ? (
        <ConfirmModal
          user={userBeingEdited.user}
          mode={userBeingEdited.mode}
          onSubmit={onSubmit}
          isMutating={mutation.isLoading}
          opened={Boolean(userBeingEdited)}
          onClose={() => setUserBeingEdited(undefined)}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Members;
