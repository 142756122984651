import { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  Badge,
  Button,
  LoadingOverlay,
  Progress,
  Text,
  Title,
} from '@mantine/core';
import formatDistance from 'date-fns/formatDistance';
import {
  Check,
  CircleMinus,
  Eye,
  EyeOff,
  FileUnknown,
  MapPin,
  PlayerPlay,
  Refresh,
  Certificate,
} from 'tabler-icons-react';
import { css } from '@emotion/react';
import { Ticket, TicketEditData } from 'types/Tickets';
import { User } from 'types/Users';
import { Location } from 'types/Locations';
import {
  ChecklistEditData,
  ChecklistResult,
  ChecklistSection,
  ChecklistSectionQuestions,
  FormChecklistMode,
} from 'types/Checklist';
import { tagClasses } from 'Shared/helpers/styles';
import CheckListQuestion from './CheckListQuestion';
import { UserState } from 'types/Store';
import { statusClassMap } from 'Shared/data/Checklists';

interface ChecklistDetailProps {
  data: ChecklistEditData;
  setChecklistAnswer(questionId: number, answer: string | null): void;
  setIsAddTicketMode?(value: boolean): void;
  openDetails: number;
  setOpenDetails(value: number): void;
  questionTicket?: Ticket;
  setTicketBeingEdited(data: TicketEditData): void;
  currentUser: UserState;
  setCurrentQuestion(value: ChecklistSectionQuestions | undefined): void;
  onSubmit(data: ChecklistEditData): void;
  selectedSection?: ChecklistSection;
  setSelectedSection(value: ChecklistSection | undefined): void;
  isUploadPhoto: boolean;
}

const sectionCss = (title: string) => {
  return css`
    .mantine-Accordion-item {
      padding: 0px;
      border: 1px solid rgba(1, 1, 1, 0.1);
    }
    .mantine-Accordion-control {
      position: relative;
      padding: 8px 5px 8px 15px;
      &::before {
        content: '${title}';
      }
      .mantine-Accordion-icon {
        position: absolute;
        right: 0;
        & svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    .mantine-Accordion-content {
      padding-left: 0;
      .mantine-Accordion-contentInner {
        padding: 10px 15px;
      }
    }
  `;
};

const accordionCss = css`
  ${sectionCss('Sections')};
  .mantine-Accordion-item {
    padding: 5px;
    border: none;
  }
  .mantine-Accordion-control {
    padding-left: 5px;
    .mantine-Accordion-icon {
      margin-left: 10px;
      position: unset;
      right: unset;
    }
  }
  .mantine-Accordion-content {
    .mantine-Accordion-contentInner {
      padding: 0px;
    }
  }
`;

const ChecklistDetail = ({
  data,
  setChecklistAnswer,
  setIsAddTicketMode,
  openDetails,
  setOpenDetails,
  questionTicket,
  setTicketBeingEdited,
  currentUser,
  setCurrentQuestion,
  onSubmit,
  selectedSection,
  setSelectedSection,
  isUploadPhoto,
}: ChecklistDetailProps) => {
  const isDisabledChecklist = data.item?.Status === 'Disable';
  useMemo(() => {
    if (selectedSection && selectedSection.Questions?.length && openDetails) {
      const currentQuestion = selectedSection.Questions.find(
        (question) => question.CheckListQuestionId === openDetails
      );
      setCurrentQuestion(currentQuestion);
    } else {
      setCurrentQuestion(undefined);
    }
  }, [selectedSection, openDetails]);

  const now = new Date();
  const percentage = useMemo(() => {
    return Math.round(
      ((data.item?.TotalPassed || 0) / (data.item?.TotalQuestion || 0)) * 100 ||
        0
    );
  }, [data.item?.TotalPassed, data.item?.TotalQuestion]);

  const percentageFailed = useMemo(() => {
    const failedQuestions = data.item?.Sections?.filter((item) => {
      const findFailedAnswers = item.Questions.find(
        (question) => question.Answer === 'Failed'
      );

      if (findFailedAnswers) return item;
    })?.map((item) => item.Questions);

    const countFailedAnswer = failedQuestions
      ?.map(
        (questions) =>
          questions.filter((question) => question.Answer === 'Failed').length
      )
      .reduce((partialSum, a) => partialSum + a, 0);

    return Math.round(
      ((countFailedAnswer || 0) / (data.item?.TotalQuestion || 0)) * 100 || 0
    );
  }, [data.item]);

  const getQuestionIcon = (answer: string | null) => {
    const iconClasses =
      'w-9 rounded-[50%] w-8 h-8 flex items-center justify-center';
    if (answer === null || answer === ChecklistResult.NotApplicable)
      return (
        <div className={`${iconClasses} bg-grey`}>
          <FileUnknown color="white" />
        </div>
      );
    if (answer === ChecklistResult.Failed)
      return (
        <div className={`${iconClasses} bg-brand`}>
          <CircleMinus color="white" />
        </div>
      );
    if (answer === ChecklistResult.Passed)
      return (
        <div className={`${iconClasses} bg-green`}>
          <Check color="white" />
        </div>
      );
  };

  const submitChecklist = () => {
    onSubmit({
      item: { CheckListId: data.item?.CheckListId, Status: 'Submit' },
      mode: FormChecklistMode.UpdateChecklist,
    });
  };

  return (
    <div className={'h-fit bg-white w-4/6 rounded-lg shadow-xl mr-9'}>
      <LoadingOverlay visible={isUploadPhoto} className={'w-full'} />
      <div className={'flex justify-between h-12 border-b items-center px-5'}>
        <div className={'flex items-center justify-between w-full'}>
          <div className={'flex text-xs items-center'}>
            <div className={'flex text-xs items-center'}>
              <Badge
                className={
                  'text-black opacity-70 mr-1.5 bg-transparent normal-case font-normal p-0'
                }
                variant="light"
                leftSection={<PlayerPlay className={'text-black'} size={18} />}
              >
                Started
              </Badge>
              {data.item?.CreateDate ? (
                <Badge className={`${tagClasses} bg-grey200`}>
                  {formatDistance(new Date(data.item.CreateDate), now, {
                    addSuffix: true,
                  })}
                </Badge>
              ) : (
                ''
              )}
            </div>
            {data.item?.UpdateDate ? (
              <div className={'flex text-xs'}>
                <Badge
                  className={
                    'text-black opacity-70 mr-1.5 ml-7 bg-transparent normal-case font-normal p-0'
                  }
                  variant="light"
                  leftSection={<Refresh className={'text-black'} size={18} />}
                >
                  Updated
                </Badge>

                <Badge className={`${tagClasses} bg-grey200`}>
                  {formatDistance(new Date(data.item.UpdateDate), now, {
                    addSuffix: true,
                  })}
                </Badge>
              </div>
            ) : (
              ''
            )}
            {data.item?.SubmitDate ? (
              <div className={'flex text-xs'}>
                <Badge
                  className={
                    'text-black opacity-70 mr-1.5 ml-7 bg-transparent normal-case font-normal p-0'
                  }
                  variant="light"
                  leftSection={
                    <Certificate className={'text-black'} size={18} />
                  }
                >
                  Submitted
                </Badge>

                <Badge className={`${tagClasses} bg-grey200`}>
                  {formatDistance(new Date(data.item.SubmitDate), now, {
                    addSuffix: true,
                  })}
                </Badge>
              </div>
            ) : (
              ''
            )}
          </div>
          {data.item?.Status !== 'Submit' && !isDisabledChecklist ? (
            <Button
              className={
                'bg-green text-white mr-1.5 ml-7 rounded-xl hover:bg-blue'
              }
              variant="light"
              onClick={() => submitChecklist()}
              disabled={(data.item.PercentageAnswered || 0) !== 1}
            >
              Submit
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={'w-full'}>
        <div className={'px-5 py-2'}>
          <div className={'flex justify-between items-center ml-[-5px]'}>
            <Badge
              className={
                'flex h-6 bg-transparent p-0 normal-case justify-start text-black font-normal'
              }
              leftSection={<MapPin color="black" size={20} strokeWidth={1.5} />}
            >
              {data.item?.LocationName}
            </Badge>
            <div className={'flex text-xs'}>
              <Text
                className={
                  'mr-1.5 bg-transparent normal-case text-xs opacity-70 p-0'
                }
              >
                ID Number
              </Text>

              <Badge className={`${tagClasses} bg-grey200`}>
                NO: {data.item?.CheckListId}
              </Badge>
            </div>
          </div>
          <div className={'flex justify-between items-center mb-4 mt-0.5'}>
            <div className={'h-fit flex flex-col justify-between'}>
              <Title className={'font-medium'} order={4}>
                {data.item?.CheckListName}
              </Title>
              <div className={'flex text-xs items-center mt-3'}>
                <span className={'opacity-70'}>Status</span>
                <Badge
                  className={'px-4 h-6 ml-1.5 text-xs font-normal normal-case'}
                  color={statusClassMap[data.item?.Status]}
                  variant="filled"
                >
                  {data.item?.Status}
                </Badge>
              </div>
            </div>
            {data.item?.Status !== 'Draft' ? (
              <Badge
                className={
                  'h-14 w-16 px-1 bg-transparent text-center text-base text-green'
                }
                size="sm"
                variant="light"
                radius={'sm'}
              >
                <Text
                  size="xs"
                  className={'normal-case font-normal text-black'}
                >
                  Passed
                </Text>
                {percentage}%
              </Badge>
            ) : (
              ''
            )}
          </div>
          {data.item?.Status === 'Draft' ? (
            <Progress
              className={'mb-3 h-1'}
              color="green"
              value={(data.item.PercentageAnswered || 0) * 100}
            />
          ) : (
            ''
          )}
        </div>
        <div className={'border-t'}></div>
        <Accordion
          className={'px-5 py-2'}
          css={accordionCss}
          onChange={() => {
            setSelectedSection(undefined);
            setOpenDetails(0);
          }}
        >
          <Accordion.Item>
            {data.item?.Sections?.map((section) => (
              <Accordion
                key={section.CheckListSectionId}
                css={sectionCss(section.SectionName)}
                className={'mt-3'}
                onChange={() => {
                  setSelectedSection(undefined);
                  setOpenDetails(0);
                }}
              >
                <AccordionItem className={'rounded'}>
                  {section.Questions.map((question) => (
                    <div
                      key={question.CheckListQuestionId}
                      className="border mb-1 px-4 rounded"
                    >
                      <div className={'flex items-center min-h-[55px]'}>
                        {getQuestionIcon(question.Answer)}
                        <Text
                          className={
                            'border-l h-full flex items-center ml-3.5 pl-3.5 w-full justify-between'
                          }
                        >
                          {question.Question}
                          <button
                            className={'pl-6 text-brand'}
                            type={'button'}
                            onClick={() => {
                              setSelectedSection(section);
                              setOpenDetails(
                                openDetails === question.CheckListQuestionId
                                  ? 0
                                  : question.CheckListQuestionId
                              );
                              if (openDetails && setIsAddTicketMode) {
                                setIsAddTicketMode(false);
                              }
                            }}
                          >
                            {openDetails === question.CheckListQuestionId ? (
                              <EyeOff size={'22'} />
                            ) : (
                              <Eye size={'22'} />
                            )}
                          </button>
                        </Text>
                      </div>
                      {openDetails === question.CheckListQuestionId ? (
                        <CheckListQuestion
                          question={question}
                          setChecklistAnswer={setChecklistAnswer}
                          setIsAddTicketMode={setIsAddTicketMode}
                          questionTicket={questionTicket}
                          setTicketBeingEdited={setTicketBeingEdited}
                          currentUser={currentUser}
                          hasPremision={
                            currentUser?.userId === data?.item?.CreateUserId ||
                            currentUser.isAdmin
                          }
                          isCompletedChecklist={
                            data.item?.Status === 'Submit' ||
                            isDisabledChecklist
                          }
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                </AccordionItem>
              </Accordion>
            ))}
          </Accordion.Item>
        </Accordion>
        <div className={'w-full px-5 py-3'}>
          <div className={'w-full px-5 py-2 shadow-xs rounded'}>
            <Text className={'opacity-50 text-xs'}>
              Completion progress percentage
            </Text>
            <div className={'w-full py-7 px-6 border rounded-2xl mt-2'}>
              <div className={'w-full flex mb-4'}>
                <Text
                  className={
                    'h-fit text-center text-green text-2xl font-bold p-0'
                  }
                >
                  {percentage}%
                </Text>
                <div className={'w-full flex flex-col justify-center ml-2'}>
                  <Text
                    size="xs"
                    className={'uppercase font-normal mb-1 text-green'}
                  >
                    PASSED
                  </Text>
                  <Progress
                    className={'w-full mb-3 h-1'}
                    color="green"
                    value={percentage}
                  />
                </div>
              </div>
              <div className={'w-full flex'}>
                <Text
                  className={
                    'h-fit text-center text-brand text-2xl font-bold p-0'
                  }
                >
                  {percentageFailed}%
                </Text>
                <div className={'w-full flex flex-col justify-center ml-2'}>
                  <Text
                    size="xs"
                    className={'uppercase font-normal mb-1 text-brand'}
                  >
                    FAILED
                  </Text>
                  <Progress
                    className={'w-full mb-3 h-1'}
                    color="red"
                    value={percentageFailed}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistDetail;
