import { useState } from 'react';

interface LocationCarouselProps {
  images: string[] | [];
  setPreviewImage(value?: string): void;
}

const LocationCarousel = ({
  images,
  setPreviewImage,
}: LocationCarouselProps) => {
  const [translateX, setTranslateX] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const dotButtons = Array.from(
    Array(images.length >= 3 ? Math.ceil(images.length / 3) : 1).keys()
  );

  const onClick = (item: number) => {
    setCurrentStep(item);
    if (item > currentStep) {
      setTranslateX(-Number(`${item}00 `));
    }
    if (item < currentStep) {
      setTranslateX(item === 0 ? 0 : translateX + Number(`${item}00 `));
    }
  };

  return (
    <div className={'flex justify-center text-center w-full'}>
      <div className={'relative w-[264px] ml-auto mr-auto'}>
        <div className={'overflow-hidden w-full'}>
          <div
            className={'flex'}
            style={{ transform: `translateX(${translateX}%)` }}
          >
            {images.map((image) => (
              <div
                className={'relative w-[80px] rounded-lg ml-2 cursor-pointer'}
                key={image}
                onClick={() => setPreviewImage(image)}
              >
                <div className={'relative overflow-hidden w-[80px] h-[80px]'}>
                  <img
                    className={'absolute w-20 h-20'}
                    src={image}
                    alt={image}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={'flex w-full justify-center items-center mt-5'}>
          {dotButtons.map((item) => (
            <div
              key={item}
              className={`bg-brand ${
                item === currentStep ? 'h-3.5 w-3.5' : 'h-2 w-2'
              } rounded-[50%] mx-0.5 cursor-pointer`}
              onClick={() => onClick(item)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocationCarousel;
