import { useState } from 'react';
import ChecklistQuickCard from 'Admin/components/Checklist/ChecklistQuickCard';
import { Checklist, ChecklistEditData } from 'types/Checklist';
import { LoadingOverlay, Alert, Text } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { Location } from 'types/Locations';
import { Template } from 'types/Template';

interface ChecklistListProps {
  checklists?: Checklist[];
  hasOverlay: boolean;
  isError: boolean;
  error?: Error;
  lastElementRef: any;
}

const ChecklistList = ({
  checklists,
  hasOverlay,
  isError,
  error,
  lastElementRef,
}: ChecklistListProps) => {
  return (
    <div>
      {isError && (
        <Alert icon={<AlertCircle size={20} />} title="Oops!" color="red">
          We encountered an error! Please refresh or try again later. If the
          problem persists, please contact the administrator.
          <br />
          Error: {error?.message ?? 'Unknown error'}.
        </Alert>
      )}

      <LoadingOverlay visible={hasOverlay} className={'w-full'} />

      {checklists?.length ? (
        checklists.map((checklist, index) => (
          <ChecklistQuickCard
            key={'checklist' + checklist.CheckListId}
            lastElementRef={
              checklists.length === index + 1 ? lastElementRef : undefined
            }
            checklist={checklist}
          />
        ))
      ) : (
        <Text className={'w-full text-center my-20 text-3xl font-medium'}>
          No Data
        </Text>
      )}
    </div>
  );
};

export default ChecklistList;
