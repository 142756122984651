import { css } from '@emotion/react';
import { Button, Modal, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { inputCss } from 'Shared/helpers/styles';
import { Plus } from 'tabler-icons-react';
import {
  FormTemplateMode,
  Section,
  Template,
  TemplateEditData,
} from 'types/Template';
import SectionsList from './SectionsList';

interface CreateTemplateProps {
  opened: boolean;
  onClose(): void;
  mode: FormTemplateMode;
  item?: Template;
  onSubmit(data: TemplateEditData): void;
}

const modalCss = css`
  .mantine-Paper-root {
    width: 100%;
    max-width: 1127px;
    height: 721px;
  }
`;

const CreateTemplate = ({
  opened,
  onClose,
  mode,
  item,
  onSubmit,
}: CreateTemplateProps) => {
  const [sections, setSections] = useState<Section[] | []>([]);
  const [selectedSection, setSelectedSection] = useState<Section | undefined>(
    undefined
  );
  const { register, handleSubmit } = useForm<Template>({
    defaultValues: item,
  });
  const submitHandler: SubmitHandler<Template> = (item) => {
    onSubmit({ item: { ...item, Sections: sections }, mode });
  };

  useEffect(() => {
    if (item && item.Sections) {
      setSections(item.Sections);
      setSelectedSection({ ...item?.Sections[0], Id: 1 });
    }
  }, []);

  return (
    <Modal opened={opened} onClose={() => onClose()} css={modalCss}>
      <form className={'px-10'} onSubmit={handleSubmit(submitHandler)}>
        <Title className={'mb-5 text-4xl'}>Add new template</Title>
        <div className={'flex mb-10'}>
          <TextInput
            css={inputCss}
            radius={'md'}
            className={'w-1/2 mr-5'}
            placeholder={'Template name'}
            size={'xl'}
            {...register('TemplateName', { required: true })}
          />
          <div className={'flex justify-around w-1/2 items-center ml-5'}>
            <Button
              size="lg"
              className={'bg-brand w-52 rounded-lg p-0'}
              type="submit"
              leftIcon={<Plus />}
            >
              Add template
            </Button>
            <Button
              size="lg"
              className={'w-52 rounded-lg border-brand text-brand'}
              onClick={() => onClose()}
              variant="outline"
              type="submit"
            >
              Cancel
            </Button>
          </div>
        </div>
        <SectionsList
          sections={sections}
          setSections={setSections}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
      </form>
    </Modal>
  );
};

export default CreateTemplate;
