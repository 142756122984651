import {
  Alert,
  Button,
  LoadingOverlay,
  Table,
  Text,
  Badge,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, Pencil, Ticket, Trash } from 'tabler-icons-react';
import format from 'date-fns/format';
import {
  actionsCss,
  tagClasses,
  tdCss,
  thClass,
  thCss,
} from 'Shared/helpers/styles';
import { FormReminderMode, Reminder, ReminderEditData } from 'types/Reminders';
import { UserState } from 'types/Store';

interface ReminderListProps {
  hasOverlay: boolean;
  isError: boolean;
  error?: Error;
  reminders?: Reminder[];
  onActionClicked(data: ReminderEditData): void;
  currentUser: UserState;
  lastElementRef: any;
}

const rowClasses = 'flex mt-2.5 px-6 bg-white h-20';

const ReminderList = ({
  hasOverlay,
  isError,
  error,
  reminders,
  onActionClicked,
  currentUser,
  lastElementRef,
}: ReminderListProps) => {
  const navigate = useNavigate();

  return (
    <div className="mt-12 col-span-9 flex flex-wrap gap-4 relative">
      {isError && (
        <Alert icon={<AlertCircle size={20} />} title="Oops!" color="red">
          We encountered an error! Please refresh or try again later. If the
          problem persists, please contact the administrator.
          <br />
          Error: {error?.message ?? 'Unknown error'}.
        </Alert>
      )}
      <LoadingOverlay visible={hasOverlay} className={'w-full'} zIndex={200} />

      <Table
        className={'drop-shadow-sm rounded-sm'}
        verticalSpacing={'md'}
        horizontalSpacing={'lg'}
        highlightOnHover
      >
        <thead>
          <tr className={'bg-white'}>
            <th className={`${thClass} w-7/12`} css={thCss}>
              Reminder title
            </th>
            <th className={`${thClass} w-1/12`} css={thCss}>
              Due date
            </th>
            <th className={`${thClass} w-1/12`} css={thCss}>
              Assignee
            </th>
            <th className={`${thClass} w-1/12`} css={thCss}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {reminders?.length ? (
            reminders?.map((reminder, index) => (
              <tr
                ref={
                  reminders.length === index + 1 ? lastElementRef : undefined
                }
                key={'reminder' + index}
              >
                <td css={tdCss}>
                  <div className={`flex-col justify-center ${rowClasses}`}>
                    <Text size={'lg'} weight={600}>
                      {reminder.Title}
                    </Text>
                    {reminder.TicketId ? (
                      <Button
                        className={'flex justify-start text-brand p-0'}
                        variant="subtle"
                        leftIcon={
                          <Ticket size={20} className={'rotate-[270deg]'} />
                        }
                        onClick={() =>
                          navigate(`/tickets/${reminder.TicketId}`)
                        }
                      >
                        View ticket
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center justify-center`}>
                    {reminder.DueDate ? (
                      <Badge
                        className={'text-xs font-normal normal-case h-6'}
                        color={'lime'}
                        size="sm"
                        variant="filled"
                      >
                        {format(new Date(reminder.DueDate || ''), 'dd.MM.yyyy')}
                      </Badge>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center justify-center`}>
                    <Badge className={`${tagClasses} h-6`}>
                      {reminder.AssignUser}
                    </Badge>
                  </div>
                </td>
                <td css={tdCss}>
                  <div
                    className={`flex justify-center ${rowClasses}`}
                    css={actionsCss}
                  >
                    {currentUser?.userId === reminder.CreateUserId ||
                    currentUser.isAdmin ? (
                      <>
                        <button
                          className={'mr-8'}
                          onClick={() => {
                            navigate(`/reminders/${reminder.ReminderId}`);
                          }}
                        >
                          <Pencil size={'20'} />
                        </button>
                        <button
                          onClick={() =>
                            onActionClicked({
                              item: reminder,
                              mode: FormReminderMode.Delete,
                            })
                          }
                        >
                          <Trash size={'20'} />
                        </button>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {!reminders?.length ? (
        <Text className={'w-full text-center my-20 text-3xl font-medium'}>
          No Data
        </Text>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReminderList;
