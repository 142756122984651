import { useMemo, useState } from 'react';
import { getImage } from 'Shared/helpers/request';

interface PrintImagesProps {
  images: string[];
}

const PrintImages = ({ images }: PrintImagesProps) => {
  const [attachments, setAttachments] = useState<string[] | []>([]);
  useMemo(async () => {
    await Promise.all<any>(
      images.map(async (img) => {
        const response = await getImage(img);
        response &&
          setAttachments((prevState) => [
            ...prevState.filter((image: string) => !image.includes(img)),
            response,
          ]);
      })
    );
  }, [images]);

  return (
    <div className="flex flex-wrap">
      {attachments.map((img: string, index) => (
        <img key={img + index} className={'h-36 w-36 mt-4 ml-5'} src={img} />
      ))}
    </div>
  );
};

export default PrintImages;
