import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Plus, ArrowBackUp, X } from 'tabler-icons-react';
import { Button } from '@mantine/core';
import ChecklistList from 'Admin/components/Checklist/ChecklistList';
import PageHeader from 'Admin/components/PageHeader';
import {
  ChecklistEditData,
  Checklist,
  FormChecklistMode,
  ChecklistQuestionAnswer,
  ChecklistSectionQuestions,
  ChecklistSection,
} from 'types/Checklist';
import {
  addChecklist,
  fetchChecklists,
  updateChecklist,
  updateCheckListQuestion,
} from 'Shared/data/Checklists';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addEntityTag,
  deleteEntityTag,
  fetchEntityTags,
  fetchTags,
} from 'Shared/data/Tags';
import Filter, { FilterParams } from 'Admin/components/Filter';
import CreateChecklist from 'Admin/components/Checklist/CreateChecklist';
import AddLabels from 'Admin/components/Ticket/AddLabels';
import { FormTicketMode, Ticket, TicketEditData } from 'types/Tickets';
import ChecklistDetail from 'Admin/components/Checklist/ChecklistDetail';
import ChecklistInfo from 'Admin/components/Checklist/ChecklistInfo';
import { addTicket, fetchTickets, updateTicket } from 'Shared/data/Tickets';
import DeleteModal from 'Admin/components/DeleteModal';
import ChecklistSearch from 'Admin/components/Checklist/ChecklistSearch';
import { useStore } from 'Shared/data/Store';
import { cancelButtonCss } from 'Shared/helpers/styles';
import { uploadFile } from 'Shared/helpers/request';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import useDebounce from 'Shared/hooks/useDebounce';
import SortBySelect from 'Admin/components/SortBySelect';

interface Tag {
  Id: number;
  TagName: string;
}
interface Labels {
  labels?: string[];
}

const notificationMessages = {
  [FormChecklistMode.Add]: 'The new checklist has been added successfully',
  [FormChecklistMode.UpdateChecklist]:
    'The checklist has been changed successfully',
  [FormChecklistMode.AddLabel]: 'The new label has been added successfully',
  [FormChecklistMode.UpdateQuesionAnswer]:
    'The question answer has been changed successfully',
  [FormChecklistMode.AddPhoto]: 'The new photo has been added successfully',
  [FormTicketMode.AddTicket]: 'The new ticket has been deleted successfully',
  error:
    'Error encountered. Please try again or contact the administrator if the issue persists.',
};

const statusChecklist = ['Draft', 'Submit', 'Disable'];

const sortByOptions = [
  { value: 'CreateDate', label: 'Create date' },
  { value: 'UpdateDate', label: 'Update date' },
  { value: 'DueDate', label: 'Due date' },
];

const Checklists = () => {
  const { checklistId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultEditData = { item: undefined, mode: undefined };
  const [checklistBeingEdited, setChecklistBeingEdited] =
    useState<ChecklistEditData>(defaultEditData);
  const [ticketBeingEdited, setTicketBeingEdited] =
    useState<TicketEditData>(defaultEditData);
  const [checklists, setChecklists] = useState<Checklist[] | undefined>(
    undefined
  );
  const [filterParams] = useState<FilterParams>({
    locationIds: searchParams.get('locationIds')
      ? [...(searchParams.get('locationIds') || '').split(',')].map((id) =>
          Number(id)
        )
      : [],
    labelsNames: searchParams.get('labelsNames')
      ? [...(searchParams.get('labelsNames') || '').split(',')]
      : [],
    templatesIds: searchParams.get('templatesIds')
      ? [...(searchParams.get('templatesIds') || '').split(',')].map((id) =>
          Number(id)
        )
      : [],
    statuses: searchParams.get('statuses')
      ? [...(searchParams.get('statuses') || '').split(',')]
      : [],
    createdate: searchParams.get('createDate')
      ? [...(searchParams.get('createDate') || '').split(',')].map(
          (date) => new Date(date)
        )
      : [],
    duedate: searchParams.get('dueDate')
      ? [...(searchParams.get('dueDate') || '').split(',')].map(
          (date) => new Date(date)
        )
      : [],
    reporterIds: searchParams.get('reporterIds')
      ? [...(searchParams.get('reporterIds') || '').split(',')]
      : [],
    percentagePassed: searchParams.get('completed')
      ? searchParams.get('completed') || ''
      : '',
  });
  const [createPage, setCreatePage] = useState<number>(1);
  const [isAddLabel, setIsAddLabel] = useState<boolean>(false);
  const [isAddTickeLabel, setIsAddTickeLabel] = useState<boolean>(false);
  const [labelsId, setLabelsId] = useState<string[] | []>([]);
  const [ticketLabelsId, setTicketLabelsId] = useState<string[] | []>([]);
  const [selectedLebels, setSelectedLebels] = useState<string[] | []>([]);
  const [selectedTicketsLebels, setSelectedTicketsLebels] = useState<
    string[] | []
  >([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<
    number | undefined
  >(undefined);
  const [searchResult, setSearchResult] = useState<Checklist[] | []>([]);
  const [openDetails, setOpenDetails] = useState<number>(0);
  const [isAddTicketMode, setIsAddTicketMode] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [currentQuestion, setCurrentQuestion] = useState<
    ChecklistSectionQuestions | undefined
  >(undefined);
  const [isRefetching, setIsRefetching] = useState(false);
  const perPage = 20;
  const [searchPageNumber, setSearchPageNumber] = useState<number>(1);
  const [skipSearch, setSkipSearch] = useState<number>(
    perPage * (searchPageNumber - 1)
  );
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchHasMore, setSearchHasMore] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<number | undefined>(undefined);
  const [selectedSection, setSelectedSection] = useState<
    ChecklistSection | undefined
  >(undefined);
  const [deleteLabel, setDeleteLabel] = useState<undefined | string>(undefined);
  const [isUploadPhoto, setIsUploadPhoto] = useState<boolean>(false);
  const [sortByDate] = useState(searchParams.get('sortByDate') || 'CreateDate');
  const [sortByOrder] = useState(searchParams.get('sortByOrder') || 'desc');
  const [tickets, setTickets] = useState<Ticket[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deferredSearchQuery = useDebounce(
    useDeferredValue(searchParams.get('search') || ''),
    500
  );

  const { currentUser } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
    currentUser: state.user,
  }));

  const isAddMode = checklistBeingEdited.mode === FormChecklistMode.Add;
  const isEditMode = checklistBeingEdited.mode === FormChecklistMode.Edit;
  const isEditTicketMode = ticketBeingEdited.mode === FormTicketMode.EditTicket;
  const isDeleteTicketMode =
    ticketBeingEdited?.mode === FormTicketMode.DeleteTicket;

  const { isFetching, isError, data, error, refetch } = useQuery<
    { value: Checklist[]; ['@odata.count']: number },
    Error
  >(
    ['checklist'],
    async () =>
      await fetchChecklists({
        filterParams,
        searchElement: deferredSearchQuery,
        skip: searchParams.get('search') ? skipSearch : 0,
        top: searchParams.get('search')
          ? perPage
          : Number(searchParams.get('top') || perPage),
        id: Number(checklistId),
        sortByValue: sortByDate,
        sortByOrder,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
    }
  );

  const { refetch: refetchTicket } = useQuery<
    { value: Ticket[]; ['@odata.count']: number },
    Error
  >(
    ['tickets'],
    async () =>
      ticketId ||
      checklistBeingEdited.item?.CheckListId ||
      currentQuestion?.CheckListQuestionId
        ? await fetchTickets({
            id: ticketId,
            checklistId: checklistBeingEdited.item?.CheckListId,
            questionId: currentQuestion?.CheckListQuestionId,
          })
        : [],
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data) {
          setTickets(data.value);
        }
      },
    }
  );

  const setData = useCallback(
    async ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: Checklist[];
      ['@odata.count']: number;
    }) => {
      setIsLoading(true);
      const filterdData = data.filter((checklist) => !checklist.Sections);
      if (!searchParams.get('search') && !checklistId) {
        setChecklists([...(filterdData || [])]);
        setHasMore(dataCount > Number(searchParams.get('top') || perPage));
      }
      if (checklistId && !searchParams.get('search')) {
        setChecklistBeingEdited({
          item: data[0],
          mode: FormChecklistMode.Edit,
        });
      }
      if (searchParams.get('search')) {
        if (!checklists?.length) {
          const response = await fetchChecklists({
            filterParams,
            top: Number(searchParams.get('top') || perPage),
            skip: 0,
            sortByValue: sortByDate,
            sortByOrder: sortByOrder,
          });
          if (response && response.value) {
            setChecklists(response.value);
            setHasMore(
              response['@odata.count'] > Number(searchParams.get('top'))
            );
          }
        }
        setSearchResult([
          ...(skipSearch === 0 ? [] : searchResult || []),
          ...(filterdData || []),
        ]);
        setSearchHasMore(dataCount > skipSearch + perPage);
      }
      setIsLoading(false);
    },
    [
      checklists,
      filterParams,
      searchParams.get('search'),
      searchPageNumber,
      skipSearch,
      sortByDate,
      sortByOrder,
    ]
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetching || searchParams.get('search') || checklistId) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          searchParams.set(
            'top',
            String(perPage * (Number(searchParams.get('page') || 1) + 1))
          );
          searchParams.set(
            'page',
            String(Number(searchParams.get('page') || 1) + 1)
          );
          setSearchParams(searchParams);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetching, hasMore]
  );

  const observerSearch: any = useRef();
  const lastSearchElementRef = useCallback(
    (node: any) => {
      if (isRefetching || !searchParams.get('search')) return;

      if (observerSearch.current) observerSearch.current.disconnect();
      observerSearch.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          searchHasMore &&
          node !== observerSearch.current
        ) {
          setSearchPageNumber(searchPageNumber + 1);
          setSkipSearch(perPage * searchPageNumber);
        }
      });
      if (node) observerSearch.current.observe(node);
    },
    [isRefetching, searchHasMore, searchParams.get('search')]
  );

  const mutationRequst = async (
    params: ChecklistEditData &
      ChecklistQuestionAnswer &
      Labels &
      TicketEditData
  ) => {
    if (deleteLabel) {
      const response = await fetchEntityTags(
        checklistBeingEdited.item?.CheckListId,
        deleteLabel,
        'CheckListId'
      );
      if (response.value && response.value.length) {
        return await deleteEntityTag(response.value[0].EntityTagId);
      } else return '';
    }
    if (params.mode === FormChecklistMode.UpdateChecklist) {
      return await updateChecklist(
        params.item?.CheckListId,
        { Status: params.item?.Status },
        []
      );
    }
    if (isAddTicketMode) {
      return addTicket({
        item: {
          ...params.item,
          QuestionId: openDetails,
          TagIds: labelsId.toString(),
          AssignUserId: currentUser.userId,
        },
      });
    }
    if (isAddLabel || (isAddTickeLabel && !isAddMode)) {
      return addEntityTag(params.labels, {
        CheckListId: checklistBeingEdited.item?.CheckListId,
      });
    }
    if (isEditTicketMode) {
      return updateTicket(
        params.item?.FDSTicketId,
        {
          Title: params.item?.Title,
          Description: params.item?.Description,
        },
        files
      );
    }
    if (isDeleteTicketMode)
      return updateTicket(
        params.item?.FDSTicketId,
        { Status: 'Cancelled' },
        files
      );
    if (isEditMode) {
      return updateCheckListQuestion({
        questionId: params.questionId,
        answer: params.answer,
      });
    }
    return addChecklist(
      {
        ...params,
        item: {
          ...params.item,
          TagIds: labelsId.toString(),
          TemplateId: selectedTemplateId,
        },
      },
      {
        ...ticketBeingEdited,
        item: {
          ...(ticketBeingEdited.item || {}),
          DueDate:
            new Date(
              String(ticketBeingEdited.item?.Date)
                .slice(0, 15)
                .concat(String(ticketBeingEdited.item?.Time).slice(15)) || ''
            ).toUTCString() || undefined,
        },
      }
    );
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationRequst, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['checklist']);
      if (isAddMode) {
        queryClient.invalidateQueries(['tickets']);
        setChecklistBeingEdited(defaultEditData);
        setTicketBeingEdited(defaultEditData);
        setSelectedTemplateId(undefined);
      }
      if (isAddLabel || isAddTickeLabel) {
        setIsAddLabel(false);
        setIsAddTickeLabel(false);
      }
      if (isEditTicketMode && !isAddLabel && !isAddTickeLabel) {
        queryClient.invalidateQueries(['tickets']);
      }
      if (isAddTicketMode || isDeleteTicketMode) {
        setTicketId(data);
        setTicketBeingEdited(defaultEditData);
      }
      setCreatePage(1);
      if (!isAddLabel) {
        setLabelsId([]);
        setSelectedLebels([]);
        setSelectedTicketsLebels([]);
        setTicketLabelsId([]);
      }
      setIsAddTicketMode(false);
      setFiles([]);
      setDeleteLabel(undefined);
    },
  });

  const onSubmit = (data: ChecklistEditData & TicketEditData) => {
    mutation.mutate({
      item: data.item,
      mode: data.mode,
    });
  };

  useEffect(() => {
    if (ticketId) {
      refetchTicket();
    }
  }, [ticketId]);

  const refetchChecklists = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  useEffect(() => {
    if (!checklistId) {
      refetchChecklists();
    }
    if (!deferredSearchQuery) {
      setSearchResult([]);
    }
  }, [searchPageNumber]);

  useEffect(() => {
    if (checklistBeingEdited.item?.CheckListId || currentQuestion) {
      refetchTicket();
    }
  }, [
    checklistBeingEdited.item?.CheckListId,
    currentQuestion?.CheckListQuestionId,
  ]);

  const setChecklistAnswer = (questionId: number, answer: string | null) => {
    mutation.mutate({
      questionId,
      answer,
      mode: FormChecklistMode.UpdateQuesionAnswer,
    });
  };

  const handleAddLabels = (labels: any[]) => {
    if (isAddTickeLabel) {
      const filterdLabels = labels
        ?.filter((tag) => {
          const findLabel = ticketBeingEdited.item?.Labels?.find(
            (tagName) => tagName === tag.label
          );
          if (!findLabel) return tag;
        })
        .map((tag) => tag.value);
      mutation.mutate({ labels: filterdLabels });
    } else {
      const filterdLabels = labels
        ?.filter((tag) => {
          const findLabel = checklistBeingEdited.item?.Labels?.find(
            (tagName) => tagName === tag.label
          );
          if (!findLabel) return tag;
        })
        .map((tag) => tag.value);
      mutation.mutate({ labels: filterdLabels });
    }
  };

  const onReset = () => {
    if (isAddMode) {
      setLabelsId([]);
      setSelectedLebels([]);
      setSelectedTicketsLebels([]);
      setTicketLabelsId([]);
      setCreatePage(1);
      setChecklistBeingEdited(defaultEditData);
      setTicketBeingEdited(defaultEditData);
      setSelectedTemplateId(undefined);
      setIsAddTicketMode(false);
    } else {
      navigate(location.key !== 'default' ? -1 : '/checklists');
    }
  };

  const questionTicket = useMemo(() => {
    if (tickets && tickets.length) {
      return tickets.find((ticket) => ticket.QuestionId === openDetails);
    }
  }, [tickets, openDetails]);

  useMemo(() => {
    if (data && Array.isArray(data.value) && selectedSection) {
      const findCurrentChecklist = data.value.find((checklist) => {
        const findSection = checklist.Sections?.find(
          (section) =>
            section.CheckListSectionId === selectedSection.CheckListSectionId
        );
        if (findSection) return checklist;
      });
      const findCurrentSection = findCurrentChecklist?.Sections?.filter(
        (section) => {
          if (
            section.CheckListSectionId === selectedSection.CheckListSectionId
          ) {
            return section;
          }
        }
      );
      setSelectedSection(
        findCurrentSection ? findCurrentSection[0] : undefined
      );
    }
  }, [data]);

  const uploadQuestionFile = async (file: any) => {
    if (file) {
      setIsUploadPhoto(true);
      await uploadFile(
        [file],
        currentQuestion?.CheckListQuestionId,
        'CheckListQuestion'
      );
      await refetch();
      showOkToast(notificationMessages.AddPhoto);
      setIsUploadPhoto(false);
    }
  };

  useMemo(() => {
    if (ticketLabelsId) {
      setTicketBeingEdited({
        ...ticketBeingEdited,
        item: {
          ...ticketBeingEdited.item,
          TagIds: ticketLabelsId.toString(),
          Labels: null,
        },
      });
    }
  }, [ticketLabelsId]);

  useEffect(() => {
    if (mutation.variables) {
      const data = mutation.variables;

      if (data.mode && mutation.isSuccess) {
        showOkToast(notificationMessages[data.mode]);
      } else if (mutation.isError) {
        showErrorToast(notificationMessages['error']);
      }
    }
  }, [mutation.variables, mutation.isSuccess, mutation.isError]);

  const getSelectedTags = () => {
    if (isAddTickeLabel) {
      return selectedTicketsLebels;
    }
    if (isAddTicketMode || isAddMode) {
      return selectedLebels;
    }
    return checklistBeingEdited.item?.Labels;
  };

  return (
    <div className={'checklists'}>
      <PageHeader pageName={'Checklists'}>
        {checklistBeingEdited.mode !== FormChecklistMode.Edit ? (
          <ChecklistSearch
            searchResult={searchResult}
            setChecklists={setChecklists}
            lastElementRef={lastSearchElementRef}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ) : (
          ''
        )}
        {checklistBeingEdited.mode === FormChecklistMode.Add ? (
          <Button
            css={cancelButtonCss}
            className={'ml-4 text-brand border-brand w-80 rounded-3xl'}
            leftIcon={<X size={20} />}
            size="md"
            variant="outline"
            onClick={() => onReset()}
          >
            Cancel
          </Button>
        ) : (
          ''
        )}
        {checklistBeingEdited.mode === FormChecklistMode.Edit ? (
          <Button
            className={'ml-4 text-brand border-brand w-80'}
            leftIcon={<ArrowBackUp size={20} />}
            size="md"
            variant="outline"
            onClick={() => onReset()}
          >
            Step back
          </Button>
        ) : (
          ''
        )}
        {checklistBeingEdited.mode !== FormChecklistMode.Edit &&
        checklistBeingEdited.mode !== FormChecklistMode.Add ? (
          <Button
            className={'bg-brand ml-4'}
            leftIcon={<Plus size={20} />}
            size="md"
            onClick={() =>
              setChecklistBeingEdited({
                item: undefined,
                mode: FormChecklistMode.Add,
              })
            }
          >
            Create New Checklist
          </Button>
        ) : (
          ''
        )}
      </PageHeader>
      <div className={'relative w-full max-h-max flex mt-2'}>
        {!isEditMode ? (
          <>
            <div className={'w-72'}>
              <Filter
                title={'Filter checklists'}
                filterParams={filterParams}
                statusesOptions={statusChecklist}
                isChecklistFilter={true}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            </div>
            <div className={'w-3/5'}>
              <SortBySelect
                data={sortByOptions}
                classNames={'mb-4 font-medium text-base'}
                value={sortByDate}
                order={sortByOrder}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
              <ChecklistList
                checklists={checklists}
                hasOverlay={isLoading || isFetching || isRefetching}
                isError={isError}
                error={error ?? undefined}
                lastElementRef={lastElementRef}
              />
            </div>
            {checklistBeingEdited.mode === FormChecklistMode.Add ? (
              <CreateChecklist
                data={checklistBeingEdited}
                isMutating={mutation.isLoading}
                onSubmit={onSubmit}
                createPage={createPage}
                setCreatePage={setCreatePage}
                setIsAddLabel={setIsAddLabel}
                labels={selectedLebels}
                ticketLabels={selectedTicketsLebels}
                ticketBeingEdited={ticketBeingEdited}
                setTicketBeingEdited={setTicketBeingEdited}
                setSelectedTemplateId={setSelectedTemplateId}
                currentUser={currentUser}
                setIsAddTickeLabel={setIsAddTickeLabel}
                selectedTemplateId={selectedTemplateId}
                setSelectedLebels={setSelectedLebels}
                setSelectedTicketsLebels={setSelectedTicketsLebels}
                labelsId={labelsId}
                setLabelsId={setLabelsId}
                ticketLabelsId={ticketLabelsId}
                setTicketLabelsId={setTicketLabelsId}
              />
            ) : (
              ''
            )}
          </>
        ) : (
          <div className={'flex justify-between w-full'}>
            <ChecklistDetail
              data={checklistBeingEdited}
              setChecklistAnswer={setChecklistAnswer}
              setIsAddTicketMode={setIsAddTicketMode}
              openDetails={openDetails}
              setOpenDetails={setOpenDetails}
              questionTicket={questionTicket}
              setTicketBeingEdited={setTicketBeingEdited}
              currentUser={currentUser}
              setCurrentQuestion={setCurrentQuestion}
              onSubmit={onSubmit}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              isUploadPhoto={isUploadPhoto}
            />
            <ChecklistInfo
              data={checklistBeingEdited}
              setIsAddLabel={setIsAddLabel}
              labels={selectedLebels}
              tickets={tickets}
              selectedLebels={selectedLebels}
              setSelectedLebels={setSelectedLebels}
              isMutating={mutation.isLoading}
              ticketBeingEdited={ticketBeingEdited}
              labelsId={labelsId}
              isAddTicketMode={isAddTicketMode}
              setIsAddTicketMode={setIsAddTicketMode}
              onSubmit={onSubmit}
              setLabelsId={setLabelsId}
              openDetails={openDetails}
              currentQuestion={currentQuestion}
              uploadQuestionFile={uploadQuestionFile}
              checklists={checklists}
              selectedSection={selectedSection}
              setDeleteLabel={setDeleteLabel}
            />
          </div>
        )}
        {isAddLabel ? (
          <AddLabels
            title={'Add labels'}
            opened={isAddLabel}
            onClose={() => {
              setIsAddLabel(false);
              setIsAddTickeLabel(false);
            }}
            labelsId={isAddTickeLabel ? ticketLabelsId : labelsId}
            setLabelsId={isAddTickeLabel ? setTicketLabelsId : setLabelsId}
            handleAddLabels={isEditMode ? handleAddLabels : undefined}
            selectedTags={getSelectedTags()}
            isAddTicketMode={isAddTicketMode}
            isAddTickeLabel={isAddTickeLabel}
            setSelectedLebels={setSelectedLebels}
            setSelectedTicketsLebels={setSelectedTicketsLebels}
          />
        ) : (
          ''
        )}
        {isDeleteTicketMode ? (
          <DeleteModal
            opened={isDeleteTicketMode}
            item={ticketBeingEdited.item}
            mode={ticketBeingEdited.mode}
            isMutating={mutation.isLoading}
            hasMutationError={isError}
            onClose={() => setTicketBeingEdited(defaultEditData)}
            onSubmit={onSubmit}
          />
        ) : (
          ''
        )}
        {deleteLabel ? (
          <DeleteModal
            opened={deleteLabel}
            item={ticketBeingEdited.item}
            mode={ticketBeingEdited.mode}
            isMutating={mutation.isLoading}
            hasMutationError={isError}
            onClose={() => setDeleteLabel(undefined)}
            onSubmit={onSubmit}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Checklists;
