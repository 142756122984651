import { Badge, Button, Text, TextInput } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { customScrollYCss, serachCss } from 'Shared/helpers/styles';
import { MapPin, Search, X } from 'tabler-icons-react';
import { Ticket } from 'types/Tickets';

interface TicketSearchProps {
  searchResult?: Ticket[];
  setTickets(value?: Ticket[]): void;
  lastElementRef: any;
  searchParams: any;
  setSearchParams(value: any): void;
}

const TicketSearch = ({
  searchResult,
  setTickets,
  lastElementRef,
  searchParams,
  setSearchParams,
}: TicketSearchProps) => {
  const navigate = useNavigate();
  const [openList, setOpenList] = useState(true);

  return (
    <div className={'relative flex justify-end w-full'}>
      <TextInput
        css={serachCss}
        className={'flex'}
        placeholder="Search tickets"
        icon={<Search size="20" className={'ml-2.5'} />}
        size="md"
        rightSection={
          searchParams.get('search') ? (
            <X
              className={'text-brand cursor-pointer'}
              onClick={() => {
                searchParams.delete('search');
                setSearchParams(searchParams);
              }}
            />
          ) : (
            ''
          )
        }
        autoFocus={!!searchParams.get('search')}
        value={searchParams.get('search') || ''}
        onChange={(event) => {
          searchParams.set('search', event.currentTarget.value);
          if (!event.currentTarget.value) {
            searchParams.delete('search');
          }
          setSearchParams(searchParams);
        }}
        onFocus={() => setOpenList(true)}
      />
      {searchParams.get('search') && openList ? (
        <div
          className={
            'absolute top-[65px] w-full max-h-[70vh] overflow-y-auto max-w-[550px]  border bg-white rounded-lg justify-center'
          }
          css={customScrollYCss}
        >
          <div
            className={
              'relative flex flex-col h-fit w-full max-w-[550px] border bg-white rounded-lg justify-center'
            }
          >
            {searchResult?.map((ticket, index) => (
              <div
                className={
                  'w-full h-fit px-4 py-3 border-0 border-b cursor-pointer'
                }
                ref={
                  searchResult.length === index + 1 ? lastElementRef : undefined
                }
                key={ticket.FDSTicketId}
                onClick={() => {
                  navigate(`/tickets/${ticket.FDSTicketId}`);
                }}
              >
                <Badge
                  className={
                    'flex h-6 bg-transparent p-0 text-grey800 font-normal normal-case justify-start cursor-pointer'
                  }
                  leftSection={
                    <MapPin color="black" size={15} strokeWidth={1} />
                  }
                >
                  {ticket.LocationName}
                </Badge>
                <Text className={'ml-1 text-sm font-medium text-grey600'}>
                  {ticket.Title}
                </Text>
                <Text className={'ml-1 text-xs font-normal text-grey600'}>
                  {ticket.Description}
                </Text>
              </div>
            ))}
          </div>
          {!searchResult?.length ? (
            <Text className={'my-4 text-center w-full'}>No data</Text>
          ) : (
            <Button
              className={
                'w-full sticky bottom-0 bg-white text-brand py-2 rounded-none rounded-b hover:bg-red-300 text-semibold text-base'
              }
              radius={'xl'}
              onClick={() => {
                setTickets(searchResult);
                setOpenList(false);
              }}
            >
              View all
            </Button>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default TicketSearch;
