import { Fragment, useMemo, useState } from 'react';
import { Table, LoadingOverlay, Alert, Text } from '@mantine/core';
import { AlertCircle, MapPin } from 'tabler-icons-react';
import {
  Area,
  Floor,
  FormLocationMode,
  LocationEditData,
  Location,
} from 'types/Locations';
import Floors from 'Admin/components/Location/Floors';
import Actions from 'Admin/components/Actions';
import Areas from 'Admin/components/Location/Areas';
import { getImage } from 'Shared/helpers/request';
import { rowClasses, tdCss, thClass, thCss } from 'Shared/helpers/styles';
import LocationMap from './LocationMap';
import { UserState } from 'types/Store';

interface LocationListProps {
  hasOverlay: boolean;
  isError: boolean;
  error?: Error | null;
  locations?: Location[];
  locationBeingEdited: LocationEditData;
  setLocationBeingEdited({ item, mode }: LocationEditData): void;
  openDetails?: number;
  setOpenDetails(data?: number): void;
  openAreaDetails: number;
  setOpenAreaDetails(data: number): void;
  openFloorDetails: number;
  setOpenFloorDetails(data: number): void;
  areas?: Area[];
  floors?: Floor[];
  selectedFloor?: Floor;
  setSelectedFloor(floor: Floor): void;
  currentLocation?: Location;
  setCurrentLocation(location: Location): void;
  isLoaded: boolean;
  mapOptions: any;
  center: { lat: number; lng: number };
  onLoad(value: any): void;
  onUnmount(value: any): void;
  currentUser: UserState;
  lastElementRef: any;
}

function LocationList({
  isError,
  error,
  hasOverlay,
  locations = [],
  locationBeingEdited,
  setLocationBeingEdited,
  openDetails,
  setOpenDetails,
  openAreaDetails,
  setOpenAreaDetails,
  openFloorDetails,
  setOpenFloorDetails,
  areas,
  floors,
  selectedFloor,
  setSelectedFloor,
  setCurrentLocation,
  currentLocation,
  isLoaded,
  mapOptions,
  center,
  onLoad,
  onUnmount,
  currentUser,
  lastElementRef,
}: LocationListProps) {
  const [images, setImages] = useState<string[] | []>([]);

  const setImage = async (img: string) => {
    const response = await getImage(img);
    response && setImages((prevState) => [...prevState, response]);
  };

  useMemo(async () => {
    if (currentLocation && currentLocation.Attachments?.length) {
      await Promise.all<any>(
        currentLocation.Attachments.map((img) => {
          setImage(img);
        })
      );
    } else {
      setImages([]);
    }
  }, [currentLocation]);

  useMemo(() => {
    if (!openDetails) {
      setOpenFloorDetails(0);
      setOpenAreaDetails(0);
    }
  }, [openDetails]);

  return (
    <div className="mt-12 col-span-9 flex flex-wrap gap-4 relative">
      <div className={'flex w-full'}>
        {isError ? (
          <div className="w-full flex justify-center">
            <Alert icon={<AlertCircle size={20} />} title="Oops!" color="red">
              We encountered an error! Please refresh or try again later. If the
              problem persists, please contact the administrator.
              <br />
              Error: {error?.message ?? 'Unknown error'}.
            </Alert>
          </div>
        ) : (
          <>
            <LoadingOverlay visible={hasOverlay} className={'w-full'} />
            <div className={'flex flex-col w-full mr-8'}>
              <Table
                className={'drop-shadow-sm rounded-sm h-fit mr-8'}
                verticalSpacing={'md'}
                horizontalSpacing={'lg'}
                highlightOnHover
              >
                <thead>
                  <tr>
                    <th className={`${thClass}`} css={thCss}>
                      Location name
                    </th>
                    <th className={`${thClass} w-1/12`} css={thCss}>
                      Floors
                    </th>
                    <th className={`${thClass} w-1/12`} css={thCss}>
                      Photos
                    </th>
                    {currentUser.isAdmin ? (
                      <th className={`${thClass} w-1/12`} css={thCss}>
                        Actions
                      </th>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>
                <tbody>
                  {locations?.length ? (
                    locations.map((location, index) => (
                      <Fragment key={'location' + location.LocationId}>
                        <tr
                          className={'relative'}
                          ref={
                            locations.length === index + 1
                              ? lastElementRef
                              : undefined
                          }
                        >
                          <td css={tdCss}>
                            <div className={`${rowClasses} items-center`}>
                              {location.Name}
                            </div>
                          </td>
                          <td css={tdCss}>
                            <div
                              className={`${rowClasses} items-center justify-center`}
                            >
                              {location.Floors}
                            </div>
                          </td>
                          <td css={tdCss}>
                            <div
                              className={`${rowClasses} items-center justify-center`}
                            >
                              {location.Photos}
                            </div>
                          </td>
                          {currentUser.isAdmin ? (
                            <td css={tdCss}>
                              <div
                                className={`${rowClasses} items-center justify-center`}
                              >
                                <Actions
                                  id={location.LocationId}
                                  item={location}
                                  openDetails={openDetails}
                                  onActionClicked={setLocationBeingEdited}
                                  setOpenDetails={setOpenDetails}
                                  deleteMode={FormLocationMode.DeleteLocation}
                                  editMode={FormLocationMode.EditLocation}
                                  setCurrentLocation={setCurrentLocation}
                                  route={`/locations/${location.LocationId}`}
                                  goBack={'/locations'}
                                  isAdmin={currentUser.isAdmin}
                                />
                              </div>
                            </td>
                          ) : (
                            ''
                          )}
                          {openDetails === location.LocationId ? (
                            <td
                              className={
                                'w-1/2 absolute bg-white top-14 left-0 z-10'
                              }
                            >
                              <Floors
                                floors={floors}
                                selectedFloor={selectedFloor}
                                setSelectedFloor={setSelectedFloor}
                                locationBeingEdited={locationBeingEdited}
                                setLocationBeingEdited={setLocationBeingEdited}
                                openFloorDetails={openFloorDetails}
                                setOpenFloorDetails={setOpenFloorDetails}
                                setOpenAreaDetails={setOpenAreaDetails}
                                defaultRoute={`/locations/${location.LocationId}`}
                                isAdmin={currentUser.isAdmin}
                              />
                            </td>
                          ) : (
                            <></>
                          )}
                          {openDetails === location.LocationId ? (
                            <td
                              className={
                                'w-1/2 absolute bg-white top-14 right-0 z-10'
                              }
                            >
                              <Areas
                                areas={areas}
                                locationBeingEdited={locationBeingEdited}
                                setLocationBeingEdited={setLocationBeingEdited}
                                openAreaDetails={openAreaDetails}
                                setOpenAreaDetails={setOpenAreaDetails}
                                defaultRoute={`/locations/${location.LocationId}`}
                                isAdmin={currentUser.isAdmin}
                                hasFloor={!!floors?.length}
                              />
                            </td>
                          ) : (
                            <></>
                          )}
                        </tr>
                      </Fragment>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              {!locations?.length ? (
                <Text
                  className={'w-full text-center my-20 text-3xl font-medium'}
                >
                  No Data
                </Text>
              ) : (
                ''
              )}
            </div>
            <div className={'flex flex-col w-2/5'}>
              <div
                className={
                  'bg-white gap-4 flex items-center justify-center text-md drop-shadow h-14 font-medium'
                }
              >
                <MapPin size={'24'} strokeWidth={1.5} />
                Locations on map
              </div>
              {isLoaded && locations.length ? (
                <LocationMap
                  center={center}
                  mapOptions={mapOptions}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  currentLocation={currentLocation}
                  images={images}
                  locations={locations}
                />
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LocationList;
