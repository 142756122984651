import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import formatDistance from 'date-fns/formatDistance';
import {
  Bell,
  Check,
  ChevronRight,
  MapPin,
  Pencil,
  Plus,
  Refresh,
  Ticket as TicketIcon,
  Trash,
  X,
} from 'tabler-icons-react';
import { css } from '@emotion/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import {
  buttonIconClasses,
  carouselModalCss,
  tagClasses,
  tailwindConfig,
} from 'Shared/helpers/styles';
import {
  FormTicketMode,
  Ticket,
  TicketEditData,
  TicketStatus,
} from 'types/Tickets';
import { getImage } from 'Shared/helpers/request';
import UserInfo from './UserInfo';
import { ticketStatusList } from 'Shared/helpers/ui';
import { Reminder } from 'types/Reminders';
import { useLocation, useNavigate } from 'react-router-dom';
import Carousel from '../Carousel';
import { User } from 'types/Users';

interface TicketDetailProps {
  data: TicketEditData;
  setTicketBeingEdited(data: TicketEditData): void;
  onSubmit(data: TicketEditData): void;
  editMode: boolean;
  setEditMode(value: boolean): void;
  setFiles(files: any): void;
  isMutating: boolean;
  deletedImages: string[] | [];
  setDeletedImages(files: string[] | []): void;
  reminders?: Reminder[];
}

const inputEditCss = css`
  input,
  textarea,
  input:focus,
  textarea:focus {
    border-radius: 10px;
    border: 1px solid ${(tailwindConfig.theme.backgroundColor as any)?.stone};
  }
  textarea {
    height: 139px;
    padding: 10px;
  }
`;

const inputDisabledCss = css`
  input,
  textarea {
    background-color: transparent !important;
  }
  textarea {
    height: 139px;
    padding: 10px;
  }
`;

const selectCss = css`
  .mantine-Select-input {
    cursor: pointer !important;
    opacity: 1 !important;
    width: 115px;
    height: 20px;
    text-align: center;
    margin-left: 10px;
    padding-right: 5px;
    border-radius: 20px;
    color: white !important;
    background: ${(tailwindConfig.theme.backgroundColor as any)?.green};
    &:disabled {
      cursor: initial !important;
      opacity: 1 !important;
    }
  }
  & svg {
    stroke: white;
  }
`;

const selectDisabledCss = css`
  ${selectCss};
  & svg {
    display: none;
  }
`;

function TicketDetail({
  data,
  setTicketBeingEdited,
  onSubmit,
  editMode,
  setEditMode,
  setFiles,
  isMutating,
  deletedImages,
  setDeletedImages,
  reminders,
}: TicketDetailProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [images, setImages] = useState<string[] | []>([]);
  const [status, setStatus] = useState<TicketStatus | undefined>(
    data.item?.Status as TicketStatus
  );
  const [assignUser, setAssignUser] = useState<string | undefined>(undefined);
  const { register, handleSubmit } = useForm<Ticket>({
    defaultValues: data.item,
  });
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    undefined
  );
  const allowedFileTypes = ['jpg', 'jpeg', 'png'];
  const submitHandler: SubmitHandler<Ticket> = (item) => {
    onSubmit({
      item: {
        FDSTicketId: item.FDSTicketId,
        Title: item.Title,
        Description: item.Description,
        Status: status,
        AssignUserId: assignUser || data.item?.AssignId,
      },
      mode: FormTicketMode.EditTicket,
    });
    setEditMode(false);
  };

  const uploadFiles = (event: any) => {
    const fileSize = event.target.files[0].size;
    const fileType = event.target.files[0].type;
    const isCorrectType = allowedFileTypes.some((item) =>
      fileType.endsWith(item)
    );
    const fileSizeLimit = 1e7;

    if (fileSize <= fileSizeLimit && isCorrectType) {
      setImages([...images, URL.createObjectURL(event.target.files[0])]);
      setFiles((prevState: any) => [...prevState, event.target.files[0]]);
    }
  };

  const currentReminder = reminders?.find(
    (reminder) => reminder.TicketId === data.item?.FDSTicketId
  );

  const setTicketImage = useCallback(
    async (photos: string[]) => {
      if (photos.length) {
        await Promise.all<any>(
          photos.map(async (img) => {
            const response = await getImage(img);
            setImages((prevState: any) => [
              ...prevState.filter(
                (image: string) =>
                  !image.includes(img) && !image.startsWith('blob')
              ),
              response,
            ]);
          })
        );
      }
    },
    [data.item?.Attachments]
  );

  useMemo(() => {
    if (data.item?.Attachments?.length) {
      const photos = data.item.Attachments.filter((item) => {
        const findPhoto = allowedFileTypes.find((type) => item.endsWith(type));
        if (findPhoto) return item;
      });
      setTicketImage(photos);
    } else {
      setImages([]);
    }
  }, [data.item?.Attachments]);

  const now = new Date();

  const breadCrumb = [data.item?.LocationName, data.item?.Title];

  const statusOptions = useMemo(() => {
    switch (status) {
      case TicketStatus.Open:
      case TicketStatus.InProgress:
        return ticketStatusList;
      case TicketStatus.Complete:
        return ticketStatusList.filter(
          (status) => status.value === TicketStatus.Complete
        );
      case TicketStatus.Cancelled:
        return ticketStatusList.filter(
          (status) => status.value === TicketStatus.Cancelled
        );
      default:
        return [];
    }
  }, [status]);

  useEffect(() => {
    setStatus(data.item?.Status as TicketStatus);
  }, [data.item?.Status]);

  const handleDeleteImage = (image: string) => {
    setImages([...images.filter((img: string) => img !== image)]);
    const findImage = data.item?.Attachments?.find((img) =>
      image.includes(img)
    );
    findImage && setDeletedImages([...deletedImages, findImage]);
  };

  const handleReset = () => {
    setEditMode(false);
    setTicketBeingEdited(data);
    setStatus(data.item?.Status as TicketStatus);
    setImages([]);
    navigate(`/tickets/${data.item?.FDSTicketId}`);
  };

  return (
    <form
      className={'h-fit bg-white w-4/5 rounded-lg shadow-xl mr-9'}
      onSubmit={handleSubmit(submitHandler)}
    >
      <LoadingOverlay visible={isMutating} className={'w-full'} />
      <div className={'flex justify-between h-12 border-b items-center px-5'}>
        <div className={'flex'}>
          <div className={'flex text-xs items-center mr-8'}>
            <span className={'text-gray-400 mr-1.5'}>Raised</span>
            {data.item?.CreateDate ? (
              <Badge color="gray" className={tagClasses}>
                {formatDistance(new Date(data.item.CreateDate), now, {
                  addSuffix: true,
                })}
              </Badge>
            ) : (
              ''
            )}
          </div>
          {data.item?.UpdateDate ? (
            <div className={'flex text-xs mr-8 items-center'}>
              <Badge
                className={
                  'text-gray-400 mr-1.5 bg-transparent normal-case font-normal p-0'
                }
                variant="light"
                leftSection={<Refresh className={'text-black'} size={18} />}
              >
                Updated
              </Badge>

              <Badge color="gray" className={tagClasses}>
                {formatDistance(new Date(data.item.UpdateDate), now, {
                  addSuffix: true,
                })}
              </Badge>
            </div>
          ) : (
            ''
          )}
          <div className={'flex gap-2 flex-col items-end'}>
            <div className={'flex text-xs text-gray-400 items-center'}>
              <span>Status</span>
              <Select
                data={statusOptions}
                maxDropdownHeight={400}
                css={editMode ? selectCss : selectDisabledCss}
                variant={'unstyled'}
                value={status}
                disabled={!editMode}
                onChange={(value) => {
                  value && setStatus(value as TicketStatus);
                }}
              />
            </div>
          </div>
        </div>
        <div className={'flex gap-2 flex-col items-end'}>
          <div className={'flex text-xs text-gray-400 items-center'}>
            {!editMode ? (
              <Button
                className={'bg-brand rounded-lg h-7 ml-7'}
                leftIcon={<Pencil size={15} />}
                onClick={() => {
                  navigate(`/tickets/${data.item?.FDSTicketId}/edit`);
                }}
              >
                Edit ticket
              </Button>
            ) : (
              <div className={'flex items-center'}>
                <Text className={'text-sm mr-3 text-gray-400 ml-5'}>
                  Cancel
                </Text>
                <div
                  className={buttonIconClasses}
                  onClick={() => handleReset()}
                >
                  <X
                    size={18}
                    className={'bg-brand rounded-[50%] text-white'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={'flex h-7 items-center px-5 border-b'}>
        <MapPin className={'mb-0.5 mr-3'} size={16} />
        {breadCrumb.map((item, index) => (
          <div className={'flex items-center'} key={index}>
            <Text className="text-xs text-gray-500">{item}</Text>
            {index + 1 !== breadCrumb.length ? (
              <ChevronRight
                color={'grey'}
                className={'mx-1 mt-0.5'}
                size={18}
                strokeWidth={1.5}
              />
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <div className={'w-full px-5 py-4'}>
        <Text className="text-sm pb-2.5 text-gray-400">Reporter details</Text>
        <UserInfo
          name={data.item?.CreateUser || ''}
          email={data.item?.CreateEmail}
          phoneNumber={data.item?.CreatePhone}
        />
        {data.item?.AssignId || editMode ? (
          <>
            <Text className="text-sm pb-2.5 text-gray-400">
              Assignee details
            </Text>
            <UserInfo
              name={data.item?.AssignUser || ''}
              email={data.item?.AssignEmail}
              phoneNumber={data.item?.AssignPhone}
              canBeEdit={editMode ? true : false}
              id={data.item?.AssignId}
              setAssignUser={setAssignUser}
            />
          </>
        ) : (
          ''
        )}
        <div className="px-2.5">
          <div className={'flex items-center justify-between w-full relative'}>
            <TextInput
              size="xl"
              css={editMode ? inputEditCss : inputDisabledCss}
              className={'w-full mr-7'}
              variant="unstyled"
              disabled={!editMode}
              placeholder={'Title'}
              defaultValue={data.item?.Title}
              icon={<TicketIcon className="rotate-[270deg]" color={'black'} />}
              required
              {...register('Title', { required: true })}
            />
            {currentReminder ? (
              <div
                className={
                  'flex border bg-red100 rounded-lg min-w-[fit-content]'
                }
              >
                <div className={'flex py-2.5 px-3 items-center'}>
                  <Bell size={28} strokeWidth={1.5} />
                </div>
                <div className={'pr-7 py-2.5 border-r'}>
                  <div className={'flex items-center'}>
                    <Text className={'text-xs opacity-50'}>Remind:</Text>
                    <Text className={'text-sm ml-2 font-semibold'}>
                      {currentReminder.AssignUser}
                    </Text>
                  </div>
                  {currentReminder.DueDate ? (
                    <div className={'flex items-center'}>
                      <Text className={'text-xs opacity-50'}>Due date:</Text>
                      <Text className={'text-xs opacity-50 ml-1'}>
                        {format(new Date(currentReminder.DueDate), 'dd.mm.yy')}
                      </Text>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={'flex py-2.5 px-3 items-center'}>
                  <Pencil
                    className={'text-brand cursor-pointer'}
                    size={30}
                    strokeWidth={1.5}
                    onClick={() =>
                      navigate(`/reminders/${currentReminder.ReminderId}`)
                    }
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={'mt-6'}>
            <Text className={'text-sm pb-2.5 text-gray-400 ml-5'}>
              Ticket description
            </Text>
            <Textarea
              css={editMode ? inputEditCss : inputDisabledCss}
              variant="unstyled"
              disabled={!editMode}
              placeholder={'Description'}
              defaultValue={data.item?.Description}
              required
              {...register('Description', { required: false })}
            />
          </div>
          {editMode || images.length ? (
            <div className={'flex items-center mt-6'}>
              <Text className={'text-sm mr-3 text-gray-400 ml-5'}>Photos</Text>
              {editMode ? (
                <label htmlFor="inputImage" className={buttonIconClasses}>
                  <Plus
                    size={18}
                    className={'bg-brand rounded-[50%] text-white'}
                  />

                  <input
                    className={'hidden'}
                    id="inputImage"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={uploadFiles}
                  />
                </label>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          <div className={'w-full flex mb-11 mt-5 overflow-y-auto'}>
            {images.map((image, index) => (
              <div key={index} className={'relative rounded-[11px] h-24'}>
                <img
                  className={'rounded-[11px] h-24 mx-2'}
                  src={image}
                  alt={image}
                  onClick={() => setPreviewImage(image)}
                />
                {editMode ? (
                  <div
                    className={
                      'bg-white rounded-[50%] absolute z-10 right-2 top-2.5 w-8 h-8 cursor-pointer'
                    }
                    onClick={() => handleDeleteImage(image)}
                  >
                    <Trash className={'m-1 z-10 text-brand'} />
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
          {editMode ? (
            <Group
              className={'mt-8 flex justify-center mb-11'}
              position="right"
            >
              <Button
                className={'bg-brand rounded-lg'}
                type="submit"
                leftIcon={<Check size={20} />}
                size={'md'}
                disabled={isMutating}
              >
                Finish ticket
              </Button>
            </Group>
          ) : (
            ''
          )}
        </div>
      </div>
      <Modal
        css={carouselModalCss}
        opened={!!previewImage}
        onClose={() => setPreviewImage(undefined)}
      >
        <Carousel images={images} previewImage={previewImage} />
      </Modal>
    </form>
  );
}

export default TicketDetail;
