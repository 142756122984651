import { Text, Title, Modal, LoadingOverlay } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { customScrollYCss, carouselModalCss } from 'Shared/helpers/styles';
import { Building, Plus } from 'tabler-icons-react';
import { Floor, FormFloorMode, LocationEditData } from 'types/Locations';
import Actions from 'Admin/components/Actions';
import { getImage } from 'Shared/helpers/request';
import LocationCarousel from 'Admin/components/Location/LocationCarousel';
import Carousel from 'Admin/components/Carousel';

interface Floors {
  floors?: Floor[];
  selectedFloor?: Floor;
  setSelectedFloor(floor: Floor): void;
  locationBeingEdited: LocationEditData;
  setLocationBeingEdited({ item, mode }: LocationEditData): void;
  openFloorDetails: number;
  setOpenFloorDetails(data: number): void;
  setOpenAreaDetails(data: number): void;
  defaultRoute: string;
  isAdmin: boolean;
}

const Floors = ({
  floors,
  selectedFloor,
  setSelectedFloor,
  locationBeingEdited,
  setLocationBeingEdited,
  openFloorDetails,
  setOpenFloorDetails,
  setOpenAreaDetails,
  defaultRoute,
  isAdmin,
}: Floors) => {
  const [images, setImages] = useState<string[] | []>([]);
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setFloorImage = useCallback(async () => {
    if (selectedFloor && selectedFloor.Images?.length) {
      setIsLoading(true);
      await Promise.all<any>(
        selectedFloor.Images.map(async (img) => {
          const response = await getImage(img);
          setImages((prevState) => [
            ...prevState.filter((item) => !item.includes(img)),
            response,
          ]);
        })
      );
      setIsLoading(false);
    }
  }, [selectedFloor?.Images]);

  useEffect(() => {
    if (selectedFloor?.Images?.length) {
      setFloorImage();
    } else {
      setImages([]);
    }
  }, [selectedFloor?.Images]);

  return (
    <div className={'flex w-full h-fit flex-col h-96'}>
      <div className={'flex mb-5'}>
        <Title className={'mb-1 mr-2.5'} order={5}>
          Floors
        </Title>
        {isAdmin ? (
          <div
            className={
              'bg-brand rounded-[50%] text-white w-6 h-6 flex items-center justify-center cursor-pointer'
            }
            onClick={() =>
              setLocationBeingEdited({
                item: locationBeingEdited.item,
                mode: FormFloorMode.AddFloor,
              })
            }
          >
            <Plus size={18} className={'bg-brand rounded-[50%] text-white'} />
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className={'overflow-y-auto overflow-x-hidden p-1 h-80'}
        css={customScrollYCss}
      >
        {floors?.map((floor, index) => (
          <div
            className={`flex cursor-pointer flex-col min-h-[40px] pr-1 pl-2 shadow-xs mb-1 justify-center ${
              floor.FloorId === selectedFloor?.FloorId ? 'shadow-brand' : ''
            }`}
            key={floor.FloorId}
            onClick={() => {
              if (selectedFloor?.FloorId !== floor.FloorId) {
                setSelectedFloor(floor);
                setImages([]);
                setOpenFloorDetails(0);
                setOpenAreaDetails(0);
              }
            }}
          >
            <div
              className={`flex items-center justify-between w-full ${
                openFloorDetails === floor.FloorId ? 'py-2' : ''
              }`}
            >
              <div>
                <Text className={'w-full flex items-center text-sm'}>
                  <Building className={'mr-3'} size={18} />
                  {floor.Name}
                </Text>
              </div>
              <div className={'flex items-center'}>
                <Actions
                  item={floor}
                  id={floor.FloorId}
                  openDetails={openFloorDetails}
                  deleteMode={FormFloorMode.DeleteFloor}
                  editMode={FormFloorMode.EditFloor}
                  setOpenDetails={setOpenFloorDetails}
                  onActionClicked={setLocationBeingEdited}
                  route={`${defaultRoute}/floors/${floor.FloorId}`}
                  goBack={`${defaultRoute}`}
                  isAdmin={isAdmin}
                />
              </div>
            </div>
            {openFloorDetails === floor.FloorId ? (
              <div
                className={
                  'flex relative w-full left-0 z-10 overflow-x-auto h-32 items-center cursor-default'
                }
              >
                {isLoading ? (
                  <LoadingOverlay visible={isLoading} />
                ) : (
                  <>
                    {images.length ? (
                      <LocationCarousel
                        images={images}
                        setPreviewImage={setPreviewImage}
                      />
                    ) : (
                      <Text className={'w-full text-center'}>No data</Text>
                    )}
                  </>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <Modal
        css={carouselModalCss}
        opened={!!previewImage}
        onClose={() => setPreviewImage(undefined)}
      >
        <Carousel images={images} previewImage={previewImage} />
      </Modal>
    </div>
  );
};

export default Floors;
