import { Button } from '@mantine/core';
import { useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';

interface CarouselProps {
  images: string[] | [];
  previewImage?: string;
}

const Carousel = ({ images, previewImage }: CarouselProps) => {
  const [translateX, setTranslateX] = useState(0);
  const isPrevEnabled = translateX < 0;
  const isNextEnabled = translateX - 100 > images.length * -100;

  useMemo(() => {
    if (previewImage) {
      const indexPrevieImage = images.indexOf(previewImage);
      setTranslateX(indexPrevieImage * -100);
    }
  }, [previewImage]);

  return (
    <div className={'flex justify-center text-center w-full'}>
      <div
        className={`relative w-[${images.length}000px] ml-auto mr-auto min-h-[600px] h-full`}
      >
        <div className={'overflow-hidden w-[900px] min-h-[600px] h-full'}>
          <div
            className={'flex'}
            style={{ transform: `translateX(${translateX}%)` }}
          >
            {images.map((image) => (
              <div
                className={'relative min-h-[600px] h-full rounded-lg'}
                key={image}
              >
                <div
                  className={
                    'flex justify-center relative overflow-hidden w-[900px] min-h-[600px] h-full'
                  }
                >
                  <img
                    className={'absolute min-w-[80px] w-full h-full'}
                    src={image}
                    alt={image}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={'absolute flex w-full justify-between top-1/2 items-center'}
      >
        <Button
          className={'bg-none hover:bg-sky-700'}
          leftIcon={
            <ChevronLeft
              size={100}
              className={`${
                isPrevEnabled ? 'text-brand hover:text-sky-700' : 'text-grey'
              } `}
            />
          }
          styles={(theme) => ({
            root: {
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            },
          })}
          onClick={() => {
            if (translateX < 0) {
              setTranslateX(translateX + 100);
            }
          }}
        ></Button>
        <Button
          className={'bg-none'}
          rightIcon={
            <ChevronRight
              size={100}
              className={`${
                isNextEnabled ? 'text-brand hover:text-sky-700' : 'text-grey'
              }`}
            />
          }
          styles={(theme) => ({
            root: {
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            },
          })}
          onClick={() => {
            if (translateX - 100 > images.length * -100) {
              setTranslateX(translateX - 100);
            }
          }}
        ></Button>
      </div>
    </div>
  );
};

export default Carousel;
