import { useNavigate } from 'react-router-dom';
import { actionsCss } from 'Shared/helpers/styles';
import { Eye, Pencil, Trash, EyeOff } from 'tabler-icons-react';
import {
  FormAreaMode,
  FormFloorMode,
  FormLocationMode,
  LocationEditData,
  Location,
} from 'types/Locations';
import { FormTemplateMode, TemplateEditData } from 'types/Template';
import { FormTicketMode, TicketEditData } from 'types/Tickets';

interface ActionsI {
  item: any;
  id?: number;
  openDetails?: number;
  onActionClicked({
    item,
    mode,
  }: LocationEditData | TicketEditData | TemplateEditData): void;
  setEditMode?(value: boolean): void;
  setOpenDetails?(e: number): void;
  deleteMode?:
    | FormLocationMode.DeleteLocation
    | FormFloorMode.DeleteFloor
    | FormAreaMode.DeleteArea
    | FormTicketMode.DeleteTicket;
  editMode:
    | FormLocationMode.EditLocation
    | FormFloorMode.EditFloor
    | FormAreaMode.EditArea
    | FormTicketMode.EditTicket
    | FormTemplateMode.Edit;
  setCurrentLocation?(location: Location): void;
  hideEye?: boolean;
  hideDelete?: boolean;
  route?: string;
  goBack?: string;
  isAdmin: boolean;
  editRoute?: string;
}

const Actions = ({
  item,
  id,
  openDetails,
  onActionClicked,
  setOpenDetails,
  deleteMode,
  editMode,
  setEditMode,
  setCurrentLocation,
  hideEye,
  hideDelete,
  route,
  goBack,
  isAdmin,
  editRoute,
}: ActionsI) => {
  const navigate = useNavigate();

  return (
    <div className={'flex justify-around'} css={actionsCss}>
      {!hideDelete && isAdmin ? (
        <Trash
          className={'cursor-pointer mx-2'}
          size={'22'}
          onClick={() => {
            onActionClicked({ item, mode: deleteMode });
            setCurrentLocation && setCurrentLocation(item);
          }}
        />
      ) : (
        ''
      )}
      {isAdmin ? (
        <Pencil
          className={'cursor-pointer mx-2'}
          size={'22'}
          onClick={() => {
            onActionClicked({ item, mode: editMode });
            setEditMode && setEditMode(true);
            editRoute && navigate(editRoute);
          }}
        />
      ) : (
        ''
      )}

      {!hideEye && id ? (
        <>
          {openDetails === id ? (
            <EyeOff
              className={'cursor-pointer  mx-2'}
              size={'22'}
              onClick={() => {
                setOpenDetails && setOpenDetails(0);
                onActionClicked({ item, mode: undefined });
                setCurrentLocation && setCurrentLocation(item);
                goBack && navigate(goBack);
              }}
            />
          ) : (
            <Eye
              className={'cursor-pointer  mx-2'}
              size={'22'}
              onClick={() => {
                onActionClicked({ item, mode: undefined });
                setCurrentLocation && setCurrentLocation(item);
                setOpenDetails && setOpenDetails(id);
                route && navigate(route);
              }}
            />
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default Actions;
