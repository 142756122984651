export enum FormTemplateMode {
  Add = 'Add',
  Edit = 'Edit',
}

export interface Question {
  CheckListQuestionTemplateId?: number;
  Question: string;
  Order?: number;
}

export interface Section {
  Id?: number;
  CheckListSectionTemplateId?: number;
  SectionName: string;
  Questions?: Question[];
  Index?: number;
  Order?: number;
}

export interface Template {
  CheckListCount?: number;
  CheckListTemplateId?: number;
  CreateDate?: Date;
  QueryKey?: string;
  TemplateName?: string;
  Sections?: Section[];
  Id?: number;
}

export interface TemplateEditData {
  item?: Template;
  mode?: FormTemplateMode;
}
