import { Modal, Title, Badge, Group, Button } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { confirmModalCss, nameCss } from 'Shared/helpers/styles';
import { Area, Floor, Location, LocationEditData } from 'types/Locations';
import { Ticket, TicketEditData } from 'types/Tickets';
import { Reminder, ReminderEditData } from 'types/Reminders';
import {
  Checklist,
  ChecklistEditData,
  FormChecklistMode,
} from 'types/Checklist';

type ItemLocation = Location & Floor & Area;

interface DeleteModalProps {
  item?: ItemLocation | Ticket | Reminder | Checklist;
  mode: any;
  onSubmit(
    data:
      | LocationEditData
      | TicketEditData
      | ReminderEditData
      | ChecklistEditData
  ): void;
  isMutating: boolean;
  hasMutationError: boolean;
  opened: string | boolean;
  onClose(): void;
  label?: string;
  deleteLabel?: boolean;
}

const DeleteModal = ({
  item,
  mode,
  opened,
  onClose,
  onSubmit,
  label,
  deleteLabel,
}: DeleteModalProps) => {
  const { handleSubmit } = useForm<
    (Location & Floor & Area) | Ticket | Reminder | Checklist
  >({
    defaultValues: item,
  });
  const submitHandler = (
    item: (Location & Floor & Area) | Ticket | Reminder | Checklist
  ) => {
    if (deleteLabel) {
      onSubmit({});
    }
    onSubmit({ item, mode });
  };

  const getItemTitle = () => {
    if (item && 'Name' in item) {
      return item.Name;
    }
    if (item && 'Title' in item) {
      return item.Title;
    }
    if (label) {
      return label;
    }
  };

  return (
    <Modal
      centered
      opened={!!opened}
      onClose={() => onClose()}
      css={confirmModalCss}
    >
      <form
        className={
          'flex flex-col gap-6 justify-center items-center max-w-[600px]'
        }
        onSubmit={handleSubmit(submitHandler)}
      >
        <Title order={1}>
          {`Are you sure you want to delete a ${
            deleteLabel ? 'label' : opened
          }?`}
        </Title>
        {deleteLabel ? (
          ''
        ) : (
          <Badge
            css={nameCss}
            color="dark"
            size="xl"
            radius="md"
            variant="light"
          >
            {getItemTitle()}
          </Badge>
        )}
        <Group className={'mt-10 m-auto'} position="right">
          <Button
            size="lg"
            className={'bg-brand w-52 rounded-lg'}
            type="submit"
          >
            Delete it
          </Button>
          <Button
            size="lg"
            className={'w-52 rounded-lg'}
            onClick={() => onClose()}
            variant="outline"
          >
            Go back
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default DeleteModal;
