import { useState } from 'react';
import { Badge, Button, Group, Text } from '@mantine/core';
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  MarkerF,
} from '@react-google-maps/api';
import { Location } from 'types/Locations';
import { Eye } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';

interface LocationMapProps {
  mapOptions: any;
  currentLocation?: Location;
  center: { lat: number; lng: number };
  onLoad(value: any): void;
  onUnmount(value: any): void;
  images: string[] | [];
  locations?: Location[];
}

const containerStyle = {
  minWidth: '364px',
  minHeight: '494px',
};

const LocationMap = ({
  mapOptions,
  currentLocation,
  center,
  onLoad,
  onUnmount,
  images,
  locations,
}: LocationMapProps) => {
  const navigate = useNavigate();
  const [openInfo, setOpenInfo] = useState<number | undefined>(undefined);

  return (
    <div className={'bg-white mt-2.5 p-2'}>
      <GoogleMap
        options={mapOptions}
        mapContainerStyle={containerStyle}
        center={
          currentLocation &&
          Number(currentLocation.Latitude) &&
          Number(currentLocation.Longtitude)
            ? {
                lat: Number(currentLocation.Latitude),
                lng: Number(currentLocation.Longtitude),
              }
            : center
        }
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {locations?.map((location) => {
          if (Number(location.Latitude) && Number(location.Longtitude)) {
            return (
              <MarkerF
                key={location.LocationId}
                position={{
                  lat: Number(location.Latitude),
                  lng: Number(location.Longtitude),
                }}
                onClick={() => setOpenInfo(location.LocationId)}
              >
                {openInfo === location.LocationId ? (
                  <InfoWindowF
                    onCloseClick={() => {
                      setOpenInfo(0);
                    }}
                  >
                    <div>
                      <Text className={'text-xs mb-1'}>Location name</Text>
                      <Text className={'text-sm font-medium'}>
                        {location.Name}
                      </Text>
                      <div className={'flex mt-1'}>
                        {images.length ? (
                          <img
                            className={'h-20 w-20 max-w-none rounded-xl mr-5'}
                            src={images[0]}
                          />
                        ) : (
                          ''
                        )}
                        <div>
                          <Group>
                            <div className={'flex-1'}>
                              <Text className={'text-xs mb-1'}>Floors</Text>
                              <Badge
                                className={
                                  'bg-transparent shadow-xs w-8 h-8 rounded-lg text-sm text-black font-medium'
                                }
                              >
                                {location.Floors}
                              </Badge>
                            </div>
                            <div className={'flex-1'}>
                              <Text className={'text-xs mb-1'}>Images</Text>
                              <Badge
                                className={
                                  'bg-transparent shadow-xs w-8 h-8 rounded-lg text-sm text-black font-medium'
                                }
                              >
                                {location.Attachments?.length}
                              </Badge>
                            </div>
                          </Group>
                          <Button
                            className={'text-brand p-0 hover:bg-transparent'}
                            onClick={() =>
                              navigate(`/locations/${location.LocationId}`)
                            }
                            leftIcon={<Eye />}
                          >
                            Open
                          </Button>
                        </div>
                      </div>
                    </div>
                  </InfoWindowF>
                ) : null}
              </MarkerF>
            );
          } else {
            return '';
          }
        })}
      </GoogleMap>
    </div>
  );
};

export default LocationMap;
