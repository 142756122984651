import { Badge, LoadingOverlay, Text } from '@mantine/core';
import { ResponsiveBar } from '@nivo/bar';
import { format, getMonth, getYear } from 'date-fns';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import {
  fetchLocations,
  locationPerformancesByMonth,
} from 'Shared/data/Locations';
import { customScrollXCss } from 'Shared/helpers/styles';
import { Location, LocationPerformace } from 'types/Locations';

const monthData: any = {
  [1]: 'Jan',
  [2]: 'Feb',
  [3]: 'Mar',
  [4]: 'Apr',
  [5]: 'May',
  [6]: 'Jun',
  [7]: 'Jul',
  [8]: 'Aug',
  [9]: 'Sep',
  [10]: 'Oct',
  [11]: 'Nov',
  [12]: 'Dec',
};

const DashboardChecklistOverview = () => {
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(undefined);
  const [performance, setPerformance] = useState<any>([]);
  const [locations, setLocations] = useState<Location[] | undefined>(undefined);
  const perPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [skip, setSkip] = useState<number>(perPage * (pageNumber - 1));
  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  const { isFetching, refetch } = useQuery<
    {
      value: Location[];
      ['@odata.count']: number;
    },
    Error
  >(
    ['locations'],
    () =>
      fetchLocations({
        skip: skip,
        top: perPage,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data.value) {
          setLocations([...(locations || []), ...data.value]);
          setHasMore(data['@odata.count'] > skip + perPage);
        }
      },
    }
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetch) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          setPageNumber(pageNumber + 1);
          setSkip(perPage * pageNumber);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetch, hasMore]
  );

  const refetchData = async () => {
    setIsRefetch(true);
    await refetch();
    setIsRefetch(false);
  };

  useEffect(() => {
    refetchData();
  }, [pageNumber]);

  useMemo(() => {
    if (locations && locations.length && !selectedLocation) {
      setSelectedLocation(locations[0]);
    }
  }, [locations, selectedLocation]);

  const getLocationPerfomance = useCallback(async () => {
    if (locations && locations.length) {
      const currentYear = getYear(new Date());
      const currentMonth = getMonth(new Date());
      const currentDay = format(new Date(), 'dd');
      try {
        setIsRefetch(true);
        const response: LocationPerformace[] =
          await locationPerformancesByMonth({
            startDate: new Date(
              currentYear - 1,
              currentMonth,
              Number(currentDay) + 1
            ).toISOString(),
            endDate: new Date().toISOString(),
            locationId: selectedLocation
              ? selectedLocation.LocationId
              : locations[0].LocationId,
          });

        const data = response.map((item) => ({
          month: item.Month,
          passed: Number(((100 * item.Passed) / item.Total).toFixed(0)) + 10,
          passedColor: '#6CDC77',
          failed: ((item.Total - item.Passed) / item.Total) * 100,
          failedColor: '#D66171',
        }));
        const performanceData = Object.keys(monthData).map((month) => {
          const monthList = data.filter((item) => item.month === Number(month));
          if (monthList.length) {
            return {
              ...monthList[0],
              month: monthData[String(month)],
            };
          } else {
            return {
              month: monthData[String(month)],
              passed: 0,
              passedColor: '#6CDC77',
              failed: 0,
              failedColor: '#D66171',
            };
          }
        });
        setPerformance(performanceData);
      } catch (e) {
        console.error(e);
        setIsRefetch(false);
      }
      setIsRefetch(false);
    }
  }, [selectedLocation?.LocationId]);

  useEffect(() => {
    if (selectedLocation) {
      getLocationPerfomance();
    }
  }, [selectedLocation]);

  return (
    <div
      className={'relative w-full bg-greyLight100 mb-4 py-4 px-5 rounded-xl'}
    >
      <LoadingOverlay visible={isFetching || isRefetch} />
      <div className={'flex justify-between'}>
        <Text className={'font-medium text-black100 ml-4'}>
          Checklists overview %
        </Text>
        <div>
          <Badge
            className={
              'bg-transparent justify-start normal-case text-xs font-normal text-black h-7'
            }
            leftSection={
              <div className={'h-3 w-3 rounded-[50%] bg-green200'}></div>
            }
            radius={'md'}
          >
            Passed checklists
          </Badge>
          <Badge
            className={
              'bg-transparent justify-start normal-case text-xs font-normal text-black h-7'
            }
            leftSection={<div className={'h-3 w-3 rounded-[50%] bg-red'}></div>}
            radius={'md'}
          >
            Passed checklists
          </Badge>
        </div>
      </div>
      <div
        className={
          'flex w-full overflow-x-auto overflow-y-hidden mt-7 h-12 items-center'
        }
        css={customScrollXCss}
      >
        {locations?.map((location, index) => (
          <Badge
            ref={locations.length === index + 1 ? lastElementRef : undefined}
            key={location.LocationId}
            className={`min-w-fit bg-whiteOpacity justify-start normal-case text-xs h-9 rounded-md text-black font-medium cursor-pointer mr-1 ml-2 hover:bg-brand hover:text-white ${
              selectedLocation?.LocationId === location.LocationId
                ? 'shadow-md'
                : ''
            }`}
            onClick={() => setSelectedLocation(location)}
          >
            {location.Name}
          </Badge>
        ))}
      </div>
      <div className="h-48 w-full">
        <ResponsiveBar
          data={performance}
          keys={['passed', 'failed']}
          indexBy="month"
          margin={{ top: 30, right: 10, bottom: 20, left: 30 }}
          padding={0.7}
          innerPadding={10}
          isInteractive={false}
          groupMode="grouped"
          colors={({ id, data }: any) => String(data[`${id}Color`])}
          valueScale={{ type: 'linear' }}
          minValue={0}
          maxValue={100}
          enableLabel={false}
          role="application"
          ariaLabel="Nivo bar chart demo"
        />
      </div>
    </div>
  );
};

export default DashboardChecklistOverview;
