import { useMemo } from 'react';
import { Badge, Progress, Text, Title, Tooltip } from '@mantine/core';
import formatDistance from 'date-fns/formatDistance';
import formatISO9075 from 'date-fns/formatISO9075';
import {
  Eye,
  MapPin,
  PlayerPlay,
  Refresh,
  Template as TemplateIcon,
  Certificate,
} from 'tabler-icons-react';
import { Checklist } from 'types/Checklist';
import { tagClasses, ticketClasses, ticketCss } from 'Shared/helpers/styles';
import { statusClassMap } from 'Shared/data/Checklists';
import { useLocation, useNavigate } from 'react-router-dom';

interface ChecklistQuickCardProps {
  checklist: Checklist;
  lastElementRef?: any;
}

const ChecklistQuickCard = ({
  checklist,
  lastElementRef,
}: ChecklistQuickCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const now = new Date();
  const percentage = useMemo(() => {
    return Math.round(
      ((checklist.TotalPassed || 0) / (checklist.TotalQuestion || 0)) * 100 || 0
    );
  }, [checklist.TotalPassed, checklist.TotalQuestion]);

  return (
    <div
      ref={lastElementRef}
      className={`${ticketClasses} h-fit min-w-[500px]`}
      css={ticketCss}
    >
      <div className={'flex justify-between h-10 items-center px-4'}>
        <div className={'flex text-xs'}>
          <Badge
            className={
              'text-gray-400 mr-1.5 bg-transparent normal-case font-normal p-0'
            }
            variant="light"
            leftSection={<PlayerPlay className={'text-black'} size={18} />}
          >
            Started
          </Badge>
          {checklist.CreateDate ? (
            <Tooltip
              label={formatISO9075(new Date(checklist.CreateDate))}
              color="teal"
              withArrow
            >
              <Badge color="gray" className={tagClasses}>
                {formatDistance(new Date(checklist.CreateDate), now, {
                  addSuffix: true,
                })}
              </Badge>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
        {checklist.UpdateDate ? (
          <div className={'flex text-xs'}>
            <Badge
              className={
                'text-gray-400 mr-1.5 bg-transparent normal-case font-normal p-0'
              }
              variant="light"
              leftSection={<Refresh className={'text-black'} size={18} />}
            >
              Updated
            </Badge>

            <Badge color="gray" className={tagClasses}>
              {formatDistance(new Date(checklist.UpdateDate), now, {
                addSuffix: true,
              })}
            </Badge>
          </div>
        ) : (
          ''
        )}
        {checklist.SubmitDate ? (
          <div className={'flex text-xs'}>
            <Badge
              className={
                'text-gray-400 mr-1.5 bg-transparent normal-case font-normal p-0'
              }
              variant="light"
              leftSection={<Certificate className={'text-black'} size={18} />}
            >
              Submitted
            </Badge>

            <Badge color="gray" className={tagClasses}>
              {formatDistance(new Date(checklist.SubmitDate), now, {
                addSuffix: true,
              })}
            </Badge>
          </div>
        ) : (
          ''
        )}
        <div className={'flex gap-2 flex-col items-end'}>
          <div className={'flex text-xs text-gray-400'}>
            <span>Status</span>
            <Badge
              className={'px-4 ml-1.5 text-xs font-normal normal-case'}
              color={statusClassMap[checklist.Status]}
              size="sm"
              variant="filled"
            >
              {checklist.Status}
            </Badge>
          </div>
        </div>
      </div>
      <div className={'px-4'}>
        <div className={'flex justify-between items-center mb-4'}>
          <div className={'flex flex-col'}>
            <Badge
              color="gray"
              className={
                'flex h-6 bg-transparent p-0 font-normal normal-case justify-start'
              }
              leftSection={<MapPin color="black" size={15} strokeWidth={1.5} />}
            >
              {checklist.LocationName}
            </Badge>
            <Title className={'font-medium block'} order={4}>
              {checklist.CheckListName}
            </Title>
          </div>
          {checklist.Status !== 'Draft' ? (
            <Badge
              className={
                'h-14 w-16 px-1 bg-stone text-center text-base text-green'
              }
              // color={percentage === 100 ? "green" : "red"}
              size="sm"
              variant="light"
              radius={'sm'}
            >
              <Text size="xs" className={'normal-case font-normal text-black'}>
                Passed
              </Text>
              {percentage}%
            </Badge>
          ) : (
            ' '
          )}
        </div>
        {checklist.Status === 'Draft' ? (
          <Progress
            className={'mb-5'}
            color={'green'}
            value={(checklist.PercentageAnswered || 0) * 100}
          />
        ) : (
          ''
        )}
        <div className={'flex justify-between'}>
          <div className={'flex'}>
            {checklist.Labels?.map((label, index) => (
              <Badge
                key={index}
                color="gray"
                className={`${tagClasses} mr-1.5`}
              >
                {label}
              </Badge>
            ))}
          </div>
          <div className={'flex justify-around'}>
            <button
              className={'mr-6'}
              onClick={() =>
                navigate(`/checklists/templates/${checklist.TemplateId}`)
              }
            >
              <TemplateIcon size={'24'} />
            </button>
            <button
              onClick={() => {
                navigate(`${checklist.CheckListId}`);
              }}
            >
              <Eye size={'24'} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistQuickCard;
