import { Reminder } from './Reminders';

export enum FormTicketMode {
  AddTicket = 'AddTicket',
  EditTicket = 'EditTicket',
  DeleteTicket = 'DeleteTicket',
  AddNewTimeline = 'AddNewTimeline',
  AddLabel = 'AddLabel',
}

export enum FormTimeLineMode {
  AddTimeLine = 'AddTimeLine',
  EditTimeLine = 'EditTimeLine',
  DeleteTimeLine = 'DeleteTimeLine',
}

export interface TimeLine {
  FDSTicketId: number;
  EntityName: string;
  Action: string;
  PropertyName: string;
  ValueType: string;
  Title: string;
  Id: number;
  CreateDate: string;
  CreateUserId: string;
  NewValue: string;
  OldValue?: string;
  CreateUser?: string;
}

export interface Ticket {
  Description?: string;
  DueDate?: string;
  FDSTicketId?: number;
  LocationId?: number;
  Status?: string;
  Title?: string;
  Labels?: string[];
  Attachments?: string[];
  TimeLines?: TimeLine[];
  CreateId?: string;
  Content?: string;
  CreateDate?: Date;
  TagIds?: string;
  CheckListId?: number;
  QuestionId?: number;
  UpdateDate?: Date;
  AssignUser?: string;
  LocationName?: string;
  AssignId?: string;
  AssignUserId?: string;
  CreateUser?: string;
  CreateEmail?: string;
  CreatePhone?: string;
  AssignEmail?: string;
  AssignPhone?: string;
  Reminders?: Reminder[];
  Time?: Date | string;
  Date?: Date | string;
}

export interface TicketEditData {
  item?: Ticket;
  mode?: FormTicketMode | FormTimeLineMode;
}

export enum TicketStatus {
  Open = 'Open',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}
