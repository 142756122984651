import {
  Avatar,
  Badge,
  Button,
  LoadingOverlay,
  Modal,
  Text,
} from '@mantine/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CircleMinus,
  FileText,
  Photo,
  Plus,
  Ticket as TicketIcon,
  X,
} from 'tabler-icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateTicket from 'Admin/components/Ticket/CreateTicket';
import {
  Checklist,
  ChecklistEditData,
  ChecklistSection,
  ChecklistSectionQuestions,
} from 'types/Checklist';
import { User } from 'types/Users';
import { Location } from 'types/Locations';
import { FormTicketMode, Ticket, TicketEditData } from 'types/Tickets';
import {
  avatarCss,
  buttonIconClasses,
  carouselModalCss,
  customScrollYCss,
  tagClasses,
} from 'Shared/helpers/styles';
import LocationCarousel from '../Location/LocationCarousel';
import { getImage } from 'Shared/helpers/request';
import Carousel from '../Carousel';
import ReactToPrint from 'react-to-print';
import PrintPage from './PrintPage';

interface ChecklistInfoProps {
  data: ChecklistEditData;
  setIsAddLabel(value: boolean): void;
  labels?: string[];
  tickets?: Ticket[];
  isAddTicketMode: boolean;
  setIsAddTicketMode(value: boolean): void;
  ticketBeingEdited: TicketEditData;
  onSubmit(data: TicketEditData): void;
  selectedLebels?: string[] | [];
  setSelectedLebels(data: string[] | []): void;
  labelsId: string[];
  setLabelsId(value: string[]): void;
  isMutating: boolean;
  openDetails: number;
  currentQuestion?: ChecklistSectionQuestions;
  uploadQuestionFile(value: any): void;
  checklists?: Checklist[];
  selectedSection?: ChecklistSection;
  setDeleteLabel(value?: string): void;
}

const buttonClasses =
  'w-full h-14 bg-white rounded normal-case text-sm justify-start mb-2 shadow-lg text-brand';

const ChecklistInfo = ({
  data,
  setIsAddLabel,
  labels,
  tickets,
  isAddTicketMode,
  setIsAddTicketMode,
  ticketBeingEdited,
  onSubmit,
  selectedLebels,
  setSelectedLebels,
  labelsId,
  setLabelsId,
  isMutating,
  openDetails,
  currentQuestion,
  uploadQuestionFile,
  checklists,
  selectedSection,
  setDeleteLabel,
}: ChecklistInfoProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDisabledChecklist = data.item?.Status === 'Disable';
  const [tags, setTags] = useState<string[] | []>([]);
  const [images, setImages] = useState<string[] | []>([]);
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const ticketPreview = tickets?.find(
    (ticket) => ticket.CheckListId === data.item?.CheckListId
  );

  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  useEffect(() => {
    if (isAddTicketMode) {
      setTags([]);
    } else {
      setTags([...(data.item?.Labels || [])]);
    }
  }, [isAddTicketMode, data.item?.Labels]);

  const uploadFiles = (event: any) => {
    const allowedFileTypes = ['jpg', 'jpeg', 'png'];
    const fileSize = event.target.files[0].size;
    const fileType = event.target.files[0].type;
    const isCorrectType = allowedFileTypes.some((item) =>
      fileType.endsWith(item)
    );
    const fileSizeLimit = 1e7;

    if (fileSize <= fileSizeLimit && isCorrectType) {
      setIsLoading(true);
      setImages([...images, URL.createObjectURL(event.target.files[0])]);
      uploadQuestionFile(event.target.files[0]);
    }
  };

  const setQuestionImage = useCallback(async () => {
    if (currentQuestion?.Attachments?.length && openDetails) {
      setIsLoading(true);
      await Promise.all<any>(
        currentQuestion.Attachments.map(async (img) => {
          const response = await getImage(img);
          setImages((prevState) => [
            ...prevState.filter(
              (item) => !item.includes(img) && !item.startsWith('blob')
            ),
            response,
          ]);
        })
      );
      setIsLoading(false);
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion?.Attachments?.length && openDetails) {
      setQuestionImage();
    } else {
      setImages([]);
    }
  }, [currentQuestion]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button
        className={buttonClasses}
        leftIcon={<FileText size={20} />}
        variant={'light'}
      >
        Download checklist
      </Button>
    );
  }, []);

  const pastCheklists = useMemo(() => {
    return checklists
      ?.filter((checklist) => checklist.Status === 'Submit')
      .filter((checklist) => checklist.TemplateId === data.item?.TemplateId);
  }, [data.item]);

  const getPercentage = (checklist: Checklist) => {
    const percentage =
      ((checklist.TotalPassed || 0) / (checklist.TotalQuestion || 0)) * 100;
    return (
      <Text
        className={`text-sm font-bold ${
          percentage < 80 ? 'text-brand' : 'text-green'
        }`}
      >
        {percentage}%
      </Text>
    );
  };

  return (
    <>
      {isAddTicketMode ? (
        <div className="bg-white w-80 flex flex-col items-end">
          <div
            className={`${buttonIconClasses} mt-2 mr-2 absolute`}
            onClick={() => {
              setIsAddTicketMode(false);
              setLabelsId([]);
              setSelectedLebels([]);
            }}
          >
            <X size={18} className={'bg-brand rounded-[50%] text-white'} />
          </div>
          <CreateTicket
            data={{
              item: ticketBeingEdited.item,
              mode: FormTicketMode.AddTicket,
            }}
            onSubmit={onSubmit}
            setIsAddLabel={setIsAddLabel}
            labels={selectedLebels}
            setSelectedLebels={setSelectedLebels}
            labelsId={labelsId}
            setLabelsId={setLabelsId}
            isMutating={isMutating}
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className={'flex flex-col rounded-xl'}>
            <div className={'w-80 bg-white h-fit rounded-xl'}>
              <Badge
                className={`w-full flex justify-start h-14 border-b-slate-300 rounded-none bg-white py-4 rounded-t-xl`}
                leftSection={
                  <Avatar
                    className={'mr-3 mt-px'}
                    alt="Avatar for badge"
                    css={avatarCss}
                    size={27}
                    radius="xl"
                  >
                    {data.item?.CreateUserName?.match(/\b\w/g)
                      ?.toString()
                      .slice(0, 1)
                      .toUpperCase()}
                    {data.item?.CreateUserName?.match(/\b\w/g)
                      ?.toString()
                      .slice(2, 3)
                      .toUpperCase()}
                  </Avatar>
                }
              >
                <div
                  className={'flex flex-col normal-case text-black font-normal'}
                >
                  <Text className={'text-xs opacity-50'}>Completed by</Text>
                  <Text className={'text-sm'}>{data.item?.CreateUserName}</Text>
                </div>
              </Badge>
              {openDetails ? (
                <div className={'py-6 px-5'}>
                  <label htmlFor="inputTag">
                    <Badge
                      className={
                        'text-grey border border-blackOpacity10 text-lg w-full h-14 normal-case bg-transparent cursor-pointer font-semibold'
                      }
                      radius={'md'}
                      leftSection={<Plus size={25} />}
                      variant={'light'}
                    >
                      Upload photos
                    </Badge>
                    <input
                      className={'hidden'}
                      id="inputTag"
                      type="file"
                      accept="image/*"
                      onChange={uploadFiles}
                    />
                  </label>
                </div>
              ) : (
                <div
                  className={
                    'flex flex-col justify-between h-fit items-center p-4'
                  }
                >
                  <Button
                    disabled={ticketPreview === undefined ? true : false}
                    className={buttonClasses}
                    leftIcon={
                      <TicketIcon size={20} className={'rotate-[270deg]'} />
                    }
                    variant={'light'}
                    onClick={() =>
                      navigate(`/tickets/${ticketPreview?.FDSTicketId}`)
                    }
                  >
                    View Ticket
                  </Button>
                  <div className={'w-full'}>
                    <ReactToPrint
                      content={reactToPrintContent}
                      documentTitle="AwesomeFileName"
                      removeAfterPrint
                      trigger={reactToPrintTrigger}
                    />
                  </div>

                  <div className={'flex items-center w-full mt-5'}>
                    <Text className={'text-base mr-3'}>Labels</Text>
                    {!isDisabledChecklist ? (
                      <div
                        className={buttonIconClasses}
                        onClick={() => {
                          setIsAddLabel(true);
                        }}
                      >
                        <Plus
                          size={18}
                          className={'bg-brand rounded-[50%] text-white'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={'flex flex-wrap w-full mt-3 mb-7 h-28'}>
                    {tags?.map((label, index) => (
                      <Badge
                        className={`${tagClasses} h-7 mr-1.5 mb-2.5 rounded`}
                        key={index}
                        leftSection={
                          <>
                            {!isDisabledChecklist ? (
                              <CircleMinus
                                onClick={() => setDeleteLabel(label)}
                                className={'text-brand cursor-pointer'}
                                size={20}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        }
                      >
                        {label}
                      </Badge>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {openDetails &&
            selectedSection &&
            currentQuestion?.Attachments?.length ? (
              <div
                className={'w-80 bg-white h-fit p-5 mt-3 rounded-xl relative'}
              >
                <Badge
                  className={
                    'text-black opacity-70 bg-transparent normal-case font-normal h-8 p-0 mb-8'
                  }
                  leftSection={<Photo size={27} strokeWidth={1.5} />}
                >
                  <span className="text-black opacity-70 text-base">
                    {`Photos (${currentQuestion.Attachments.length})`}
                  </span>
                </Badge>

                {isLoading ? (
                  <LoadingOverlay visible={isLoading} />
                ) : (
                  <LocationCarousel
                    images={images}
                    setPreviewImage={setPreviewImage}
                  />
                )}
              </div>
            ) : (
              ''
            )}
            <Modal
              css={carouselModalCss}
              opened={!!previewImage}
              onClose={() => setPreviewImage(undefined)}
            >
              <Carousel images={images} previewImage={previewImage} />
            </Modal>
            <div className={'hidden'}>
              <PrintPage
                componentRef={componentRef}
                checklist={data.item}
                tickets={tickets}
              />
            </div>
          </div>
          {pastCheklists?.length ? (
            <div className={'flex flex-col rounded-xl bg-white mt-3 py-4'}>
              <Text className={'text-xs mb-1.5 px-4'}>PAST CHECKLISTS</Text>
              <div
                className={'flex flex-col h-[310px] overflow-y-auto px-4'}
                css={customScrollYCss}
              >
                {pastCheklists?.map((checklist) => (
                  <div
                    key={checklist.CheckListId}
                    className={
                      'h-fit p-3.5 rounded shadow-1xl flex justify-between items-center mt-2'
                    }
                  >
                    <Text className={'text-sm'}>{checklist.CheckListName}</Text>
                    {getPercentage(checklist)}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default ChecklistInfo;
