import { Button, Group, Modal, Select, Text, TextInput } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormReminderMode, Reminder, ReminderEditData } from 'types/Reminders';
import { DatePicker, TimeInput } from '@mantine/dates';
import { Calendar, Clock, Plus } from 'tabler-icons-react';
import { useMemo, useState } from 'react';
import { Ticket } from 'types/Tickets';
import { User } from 'types/Users';
import {
  bigInputTimeCss,
  confirmModalCss,
  inputCss,
} from 'Shared/helpers/styles';
import CustomSelect from '../CustomSelect';
import { fetchTickets } from 'Shared/data/Tickets';
import { useQuery } from 'react-query';
import { fetchAllUsers } from 'Shared/data/Users';

interface CreateEditModal {
  data: ReminderEditData;
  opened: boolean;
  onClose(): void;
  onSubmit(data: ReminderEditData): void;
  isMutating: boolean;
}

const CreateEditModal = ({
  data,
  opened,
  onClose,
  onSubmit,
  isMutating,
}: CreateEditModal) => {
  const [ticket, setTicket] = useState<string>('');
  const [tickets, setTickets] = useState<Ticket[] | undefined>(undefined);
  const [currentTicket, setCurrentTicket] = useState<Ticket | undefined>(
    undefined
  );
  const [assignUser, setAssignUser] = useState<string>('');
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [dueDate, setDueDate] = useState<string | null>(null);
  const isEditMode = data.mode === FormReminderMode.Edit;
  const [time, setTime] = useState<Date | null>(
    isEditMode && data.item?.DueDate ? new Date(data.item?.DueDate) : null
  );

  useQuery<{ value: Ticket[]; ['@odata.count']: number }, Error>(
    ['tickets'],
    async () =>
      data.item?.TicketId
        ? await fetchTickets({
            id: Number(data.item?.TicketId),
          })
        : [],
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if (response && response.value && response.value.length) {
          if (data.item?.TicketId) {
            setCurrentTicket(response.value[0]);
          }
          setTickets(response.value);
        }
      },
    }
  );
  useQuery<{ value: User[]; ['@odata.count']: number }, Error>(
    ['users'],
    async () =>
      data.item?.AssignUserId
        ? await fetchAllUsers({
            id: data.item?.AssignUserId,
          })
        : [],
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if (response && response.value && response.value.length) {
          if (data.item?.AssignUserId) {
            setCurrentUser(response.value[0]);
          }
          setUsers(response.value);
        }
      },
    }
  );

  const { register, handleSubmit } = useForm<Reminder>({
    defaultValues: data.item,
  });

  const submitHandler: SubmitHandler<Reminder> = (item) => {
    onSubmit({
      item: {
        ...item,
        TicketId: Number(ticket || data.item?.TicketId),
        AssignUserId: assignUser || data.item?.AssignUserId,
        DueDate: dueDate
          ? new Date(
              String(dueDate).slice(0, 15).concat(String(time).slice(15)) || ''
            ).toUTCString() || undefined
          : data.item?.DueDate,
      },
      mode: data.mode,
    });
  };

  const ticketsList = useMemo(() => {
    return (
      tickets?.map((ticket, index) => ({
        value: String(ticket.FDSTicketId),
        label: ticket.Title,
        index,
      })) || []
    );
  }, [tickets]);

  const usersList = useMemo(() => {
    return (
      users?.map((user, index) => ({
        value: user.Id || '',
        label: `${user.FirstName} ${user.Surname}`,
        index,
      })) || []
    );
  }, [users]);

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => onClose()}
      css={confirmModalCss}
    >
      <form className={'px-8'} onSubmit={handleSubmit(submitHandler)}>
        <Text className={'text-4xl font-semibold mb-6 leading-10'}>
          {isEditMode ? 'Edit reminder' : 'Add reminder'}
        </Text>
        <TextInput
          css={inputCss}
          className={'w-full mb-3.5'}
          placeholder="Reminder name"
          size={'xl'}
          required
          {...register('Title', { required: true })}
        />
        <CustomSelect
          setData={(list: Ticket[]) =>
            setTickets(
              currentTicket
                ? [
                    currentTicket,
                    ...list.filter(
                      (ticket: Ticket) =>
                        ticket.FDSTicketId !== currentTicket?.FDSTicketId
                    ),
                  ]
                : [...(list || [])]
            )
          }
          request={fetchTickets}
          options={ticketsList}
          placeholder={'Link ticket'}
          classNames={'w-full mb-3.5'}
          onChange={setTicket}
          defaultValue={String(data.item?.TicketId)}
          dataList={tickets}
          size={'xl'}
          cssStyles={inputCss}
        />
        <DatePicker
          css={inputCss}
          size={'xl'}
          className={'w-full mb-3.5'}
          placeholder="Due date"
          rightSection={<Calendar size={30} />}
          defaultValue={
            isEditMode && data.item?.DueDate
              ? new Date(data.item?.DueDate)
              : undefined
          }
          onChange={(value) => setDueDate(String(value))}
        />
        <TimeInput
          css={bigInputTimeCss}
          size={'xl'}
          className={'w-full mb-3.5'}
          rightSection={<Clock size={30} className={'text-brand'} />}
          value={time || undefined}
          onChange={(value) => value && setTime(new Date(value))}
          format={'12'}
          required
        />
        <CustomSelect
          setData={(list: User[]) =>
            setUsers(
              currentUser
                ? [
                    currentUser,
                    ...list.filter((user: User) => user.Id !== currentUser?.Id),
                  ]
                : [...(list || [])]
            )
          }
          request={fetchAllUsers}
          options={usersList}
          placeholder={'Who to remind'}
          classNames={'w-full mb-3.5'}
          onChange={setAssignUser}
          defaultValue={String(data.item?.AssignUserId)}
          dataList={users}
          size={'xl'}
          cssStyles={inputCss}
        />
        <Group className={'flex justify-start mt-11 mb-4'} position="right">
          <Button
            className={'bg-brand rounded-lg w-52'}
            type="submit"
            leftIcon={!isEditMode ? <Plus size={20} /> : ''}
            size={'lg'}
            disabled={isMutating}
          >
            {isEditMode ? 'Save changes' : 'Add reminder'}
          </Button>

          <Button
            className={'w-48 rounded-lg'}
            variant={'outline'}
            size={'lg'}
            disabled={isMutating}
            onClick={() => onClose()}
          >
            Go back
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default CreateEditModal;
