import config from 'config';
import { addReminder } from 'Shared/data/Reminders';
import { addTicket } from 'Shared/data/Tickets';
import { TicketEditData } from 'types/Tickets';

interface request {
  url: string;
  params: any;
  files?: any;
  type?: string;
  deletedImages?: string[];
  id?: number;
  ticketData?: TicketEditData;
}

interface checkTokenProps {
  method: string;
  url: string;
  params?: any;
  files?: any;
  type?: string;
  deletedImages?: string[];
  id?: number;
  ticketData?: TicketEditData;
}

interface queryProps {
  method: string;
  url: string;
  params: any;
  files: any;
  type: string | undefined;
  deletedImages: string[] | undefined;
  token: string;
  id?: number;
  ticketData?: TicketEditData;
}
export const redirectToLogin = () => {
  if (window.location.pathname !== '/login') {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }
};

export const refreshToken = async (token: string) => {
  return await fetch(`${config.apiBaseUrl}/Api/Token/RefreshAccessToken`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  })
    .then((response) => response.json())
    .catch((e) => redirectToLogin());
};

export const GET = (url: string, type?: string) => {
  return checkToken({ method: 'GET', url, type });
};

export const POST = ({ url, params, files, type, ticketData }: request) => {
  return checkToken({ method: 'POST', url, params, files, type, ticketData });
};

export const PUT = ({
  url,
  params,
  files,
  type,
  deletedImages,
  id,
  ticketData,
}: request) => {
  return checkToken({
    method: 'PUT',
    url,
    params,
    files,
    type,
    deletedImages,
    id,
    ticketData,
  });
};

export const DELETE = (url: string, params: any) => {
  return checkToken({ method: 'DELETE', url, params });
};

const checkToken = async ({
  method,
  url,
  params,
  files,
  type,
  deletedImages,
  id,
  ticketData,
}: checkTokenProps) => {
  const token: string = localStorage.getItem('token') || '';
  const expireUCTTime: string = localStorage.getItem('expireUCTTime') || '';
  const refreshTokenString: string =
    localStorage.getItem('refreshTokenString') || '';
  const date = new Date().toISOString();
  if (!token && type !== 'Login') {
    return redirectToLogin();
  }
  if (token && date >= expireUCTTime) {
    return await refreshToken(refreshTokenString).then((data) => {
      if (!data) return redirectToLogin();
      localStorage.setItem('token', data.TokenString);
      localStorage.setItem('expireUCTTime', data.ExpireUCTTime);
      localStorage.setItem('refreshTokenString', data.RefreshTokenString);
      return query({
        method,
        url,
        params,
        files,
        type,
        deletedImages,
        token: data.TokenString,
        id,
        ticketData,
      });
    });
  } else {
    return query({
      method,
      url,
      params,
      files,
      type,
      deletedImages,
      token,
      id,
      ticketData,
    });
  }
};

const query = async ({
  method,
  url,
  params,
  files,
  type,
  deletedImages,
  token,
  id,
  ticketData,
}: queryProps) => {
  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  if (method === 'GET') {
    return await fetch(`${config.apiBaseUrl}/${url}`, {
      method: 'GET',
      headers: defaultHeaders,
    })
      .then((response) =>
        type === 'Files' ? response.text() : response.json()
      )
      .then((data) => data);
  }

  if (method === 'POST') {
    return await fetch(`${config.apiBaseUrl}/${url}`, {
      method: 'POST',
      headers:
        type === 'Files'
          ? { Authorization: defaultHeaders.Authorization }
          : defaultHeaders,
      body: type === 'Files' ? params : JSON.stringify(params),
    })
      .then((response) => (type === 'User' ? response.text() : response.json()))
      .then(async (data) => {
        if (type === 'Checklist') {
          if (data && !data.errors && !data.ErrorMessage && ticketData) {
            await addTicket({
              item: { ...ticketData.item, CheckListId: data },
              mode: ticketData.mode,
            });
          }
          return data;
        }
        if (type === 'Ticket' && params.DueDate) {
          await addReminder({
            item: {
              DueDate: new Date(params.DueDate),
              TicketId: data,
              Title: `Reminder for ticket: ${params.Title}`,
              AssignUserId: params.AssignId,
            },
          });
        }
        if (
          type === 'Login' ||
          type === 'Label' ||
          type === 'User' ||
          type === 'Ticket'
        ) {
          return data;
        }
        if (files?.length) {
          await uploadFile(files, data, type);
        }
        return data.value;
      });
  }

  if (method === 'PUT') {
    if (deletedImages?.length && type) {
      await deleteFile(deletedImages, type);
    }
    return await fetch(`${config.apiBaseUrl}/${url}`, {
      method: 'PUT',
      headers: defaultHeaders,
      body: JSON.stringify(params),
    })
      .then((response) => (type === 'User' ? response.text() : response.json()))
      .then(async (response) => {
        if (files?.length) {
          await uploadFile(files, id, type);
        }
        return response;
      });
  }

  if (method === 'DELETE') {
    return await fetch(`${config.apiBaseUrl}/${url}/${params}`, {
      method: 'DELETE',
      headers: defaultHeaders,
    });
  }
};

export const uploadFile = async (
  files: File[],
  id: number | undefined,
  type?: string
) => {
  if (!files.length) return;
  await Promise.all<any>(
    files.map(async (file) => {
      const formData = new FormData();
      formData.append(`${file.name}`, file);
      await POST({
        url: `Api/Attachments/${type}/${id}`,
        params: formData,
        type: 'Files',
      });
    })
  );
};

export const getImage = async (img: string) => {
  return await GET(`Api/Attachments/uuid/${img}`, 'Files');
};

const deleteFile = async (files: string[], type: string) => {
  await Promise.all<any>(
    files.map(async (file) => {
      await DELETE(`Api/Attachments/${type}/${file}`, '');
    })
  );
};
