import { Badge, Button, Text, TextInput } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { customScrollYCss, serachCss } from 'Shared/helpers/styles';
import { Search, UserCircle, X } from 'tabler-icons-react';
import { FormUIMode } from 'types/UI';
import { MemberEditData, User } from 'types/Users';

interface MemberSearchProps {
  searchResult: User[] | [];
  setUserBeingEdited(data: MemberEditData): void;
  setUsers(value: User[] | []): void;
  lastElementRef: any;
  searchParams: any;
  setSearchParams(value: any): void;
}

const MemberSearch = ({
  searchResult,
  setUserBeingEdited,
  setUsers,

  lastElementRef,
  searchParams,
  setSearchParams,
}: MemberSearchProps) => {
  const navigate = useNavigate();
  const [openList, setOpenList] = useState(true);

  return (
    <div className={'relative flex justify-end w-full'}>
      <TextInput
        css={serachCss}
        placeholder="Search members"
        icon={<Search size="20" className={'ml-2.5'} />}
        size="md"
        rightSection={
          searchParams.get('search') ? (
            <X
              className={'text-brand cursor-pointer'}
              onClick={() => {
                searchParams.delete('search');
                setSearchParams(searchParams);
              }}
            />
          ) : (
            ''
          )
        }
        autoFocus={!!searchParams.get('search')}
        value={searchParams.get('search') || ''}
        onChange={(event) => {
          searchParams.set('search', event.currentTarget.value);
          if (!event.currentTarget.value) {
            searchParams.delete('search');
          }
          setSearchParams(searchParams);
        }}
        onFocus={() => setOpenList(true)}
      />
      {searchParams.get('search') && openList ? (
        <div
          className={
            'absolute top-[65px] w-full max-h-[70vh] overflow-y-auto max-w-[550px]  border bg-white rounded-lg justify-center'
          }
          css={customScrollYCss}
        >
          <div
            className={
              'relative flex flex-col h-fit w-full max-w-[550px] border bg-white rounded-lg justify-center'
            }
          >
            {searchResult?.map((user, index) => (
              <div
                className={
                  'flex w-full h-fit px-4 py-3 border-0 border-b cursor-pointer justify-between items-center'
                }
                ref={
                  searchResult.length === index + 1 ? lastElementRef : undefined
                }
                key={user.Id}
                onClick={() => {
                  setUserBeingEdited({
                    user,
                    mode: FormUIMode.Edit,
                  });
                }}
              >
                <Badge
                  className={
                    'flex bg-transparent p-0 text-grey600 text-sm font-normal normal-case justify-start cursor-pointer'
                  }
                  size={'xl'}
                  leftSection={
                    <UserCircle
                      className={'text-grey600'}
                      size={20}
                      strokeWidth={1}
                    />
                  }
                >
                  {`${user.FirstName} ${user.Surname}`}
                </Badge>
                <Text className={'ml-1 text-grey800 text-xs font-normal'}>
                  {user.JobTitle}
                </Text>
              </div>
            ))}
          </div>
          {!searchResult?.length ? (
            <Text className={'my-4 text-center'}>No data</Text>
          ) : (
            <Button
              className={
                'w-full text-brand sticky bottom-0 bg-white py-2 rounded-none rounded-b hover:bg-red-300 text-semibold text-base'
              }
              radius={'xl'}
              onClick={() => {
                setUsers(searchResult);
                setOpenList(false);
              }}
            >
              View all
            </Button>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default MemberSearch;
