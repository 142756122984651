import buildQuery from 'odata-query';
import { Ticket, TicketEditData } from 'types/Tickets';
import * as Request from 'Shared/helpers/request';
import { FilterParams } from 'Admin/components/Filter';
interface fetchTicketsPorps {
  filterParams?: FilterParams;
  searchElement?: string;
  skip?: number;
  top?: number;
  id?: number;
  sortByValue?: string;
  sortByOrder?: string;
  checklistId?: number;
  questionId?: number;
  isDashboard?: boolean;
}

export const fetchTickets = async ({
  filterParams,
  searchElement,
  skip = 0,
  top,
  id,
  sortByValue = 'CreateDate',
  sortByOrder = 'desc',
  checklistId,
  questionId,
  isDashboard,
}: fetchTicketsPorps) => {
  const count = true;
  const ticketDataList = `${
    id ? 'TimeLines,' : ''
  }FDSTicketId,LocationId,Status,Title,Labels,Attachments,CreateId,Content,Description,CreateDate,CheckListId,QuestionId,UpdateDate,AssignUser,LocationName,AssignId,CreateUser,CreateEmail,CreatePhone,AssignEmail,AssignPhone,Reminders`;
  const LocationId =
    filterParams?.locationIds && filterParams.locationIds.length
      ? {
          and: {
            or: filterParams.locationIds.map((id) => ({
              '': id,
            })),
          },
        }
      : {};

  const Labels = filterParams?.labelsNames.length
    ? {
        any: {
          '': {
            in: filterParams.labelsNames,
          },
        },
      }
    : {};
  const Status = filterParams?.statuses?.length
    ? {
        and: {
          '': {
            ne: {
              type: 'raw',
              value: "'Cancelled'",
            },
          },
          or: filterParams?.statuses?.map((status) => ({
            '': status,
          })),
        },
      }
    : {
        and: {
          '': {
            ne: {
              type: 'raw',
              value: "'Cancelled'",
            },
          },
        },
      };

  const createDateRange =
    filterParams?.createdate?.filter((date: string) => date !== null) || [];
  const CreateDate = createDateRange.length
    ? { ge: createDateRange[0], le: createDateRange[1] }
    : {};

  const dueDateRange =
    filterParams?.duedate?.filter((date: string) => date !== null) || [];
  const DueDate = dueDateRange?.length
    ? { ge: dueDateRange[0], le: dueDateRange[1] }
    : {};
  const AssignId = filterParams?.assigneeIds?.length
    ? {
        and: {
          or: filterParams.assigneeIds.map((id) => ({
            '': id,
          })),
        },
      }
    : {};
  const CreateId = filterParams?.reporterIds?.length
    ? {
        and: {
          or: filterParams.reporterIds.map((id) => ({
            '': id,
          })),
        },
      }
    : {};

  const filterExist =
    Object.keys(LocationId).length ||
    Object.keys(Labels).length ||
    Object.keys(Status).length ||
    Object.keys(CreateDate).length ||
    Object.keys(DueDate).length ||
    Object.keys(AssignId).length ||
    Object.keys(CreateId).length;

  const search = searchElement
    ? `${
        filterExist ? ' and ' : '&$filter='
      }(indexof(toupper(Title), '${searchElement.toLocaleUpperCase()}') ge 0 or indexof(toupper(Description), '${searchElement.toLocaleUpperCase()}') ge 0 or indexof(toupper(LocationName), '${searchElement.toLocaleUpperCase()}') ge 0)`
    : '';

  const getById = questionId
    ? { QuestionId: questionId }
    : checklistId
    ? { CheckListId: checklistId }
    : { FDSTicketId: id };

  const query = buildQuery({
    filter:
      id || checklistId
        ? getById
        : {
            LocationId,
            Labels,
            Status,
            CreateDate,
            DueDate,
            AssignId,
            CreateId,
          },
    select: ticketDataList,
  });
  if (isDashboard) {
    return await Request.GET(
      `Api/Tickets/odata?$apply=groupby((Status),aggregate($count as COUNT))&$count=true`
    );
  } else {
    return await Request.GET(
      `Api/Tickets/odata${query}${search}${
        top ? `&$top=${top}&$skip=${skip}` : ''
      }&$orderBy=${sortByValue} ${sortByOrder}&$count=true`
    );
  }
};

export const addTicket = async (data: TicketEditData | any) => {
  return await Request.POST({
    url: 'Api/Data/FDSTicket',
    params: data.item,
    type: 'Ticket',
  });
};

export const updateTicket = async (
  id: number | undefined,
  data: Ticket,
  files: any,
  deletedImages?: string[]
) => {
  return await Request.PUT({
    url: `Api/Data/FDSTicket/${id}`,
    params: data,
    files,
    type: 'Ticket',
    id,
    deletedImages,
  });
};
