import { Badge, LoadingOverlay, Text, Timeline } from '@mantine/core';
import { useState } from 'react';
import { Camera, FileText, Plus } from 'tabler-icons-react';
import { TicketEditData, TimeLine } from 'types/Tickets';
import AddTimeline from './AddTimeline';
import format from 'date-fns/format';
import { User } from 'types/Users';
import {
  buttonIconClasses,
  customScrollYCss,
  tagClasses,
  tailwindConfig,
} from 'Shared/helpers/styles';
import { css } from '@emotion/react';
interface TicketTimelineProps {
  setFiles(files: any): void;
  onSubmit(data: TicketEditData): void;
  data: TicketEditData;
  isMutating: boolean;
}

const timeLineCss = css`
  ${customScrollYCss}

  .mantine-Timeline-itemBullet {
    border-width: 3px;
    background-color: grey;
  }
  .mantine-Timeline-itemActive {
    .mantine-Timeline-itemBullet {
      border-color: ${(tailwindConfig.theme.backgroundColor as any)?.stone};
      border-width: 3px;
      background-color: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
    }
    &::before {
      border-left-color: ${(tailwindConfig.theme.backgroundColor as any)
        ?.stone};
    }
  }
`;

function TicketTimeline({
  setFiles,
  onSubmit,
  data,
  isMutating,
}: TicketTimelineProps) {
  const [isAddTimeline, setIsAddTimeline] = useState<boolean>(false);
  const photosFileTypes = ['jpg', 'jpeg', 'png'];

  const getActionTitle = (item: TimeLine) => {
    switch (item.Action) {
      case 'CommentsUpdate':
        return 'updated a comment';
      case 'StatusUpdates':
        return 'updated status';
      case 'DescriptionUpdate':
        return 'updated description';
      case 'TagAdd':
        return 'added a label';
      case 'TagRemove':
        return 'removed a labe';
      case 'Others':
        if (item.PropertyName === 'Title') {
          return 'updated title';
        }
        if (item.PropertyName === 'AssignUserId') {
          if (!item.OldValue) {
            return 'assigned user';
          }
          return 'updated assign user';
        }
        return '';
      case 'TicketCreate':
        if (item.PropertyName === 'Title') {
          return 'added title';
        }
        if (item.PropertyName === 'Description') {
          return 'added description';
        }
        if (item.PropertyName === 'Status') {
          return 'added status';
        }
        return '';
      case 'AttachmentUpload': {
        const isPhotoType = photosFileTypes.some((type) =>
          item.NewValue.endsWith(type)
        );
        if (isPhotoType) {
          return 'added photos';
        }
        return 'added documents';
      }
      default:
        '';
    }
  };

  const getEntity = (item: TimeLine) => {
    if (item.PropertyName) {
      if (item.PropertyName === 'AssignUserId') {
        return (
          <Text color="dimmed" size="sm" className={'mt-3'}>
            {item.OldValue
              ? `${item.OldValue} -> ${item.NewValue}`
              : item.NewValue}
          </Text>
        );
      }
      return (
        <Text color="dimmed" size="sm" className={'mt-3'}>
          {item.OldValue
            ? `${item.OldValue} -> ${item.NewValue}`
            : item.NewValue}
        </Text>
      );
    } else {
      switch (item.EntityName) {
        case 'FDSAttachment': {
          const isPhotoType = photosFileTypes.some((type) =>
            item.NewValue.endsWith(type)
          );
          return (
            <Badge
              className={
                'bg-stone text-black text-sm normal-case w-full mt-3 font-medium justify-start'
              }
              size={'xl'}
              radius={'sm'}
              leftSection={
                isPhotoType ? (
                  <Camera className={'text-brand'} />
                ) : (
                  <FileText className={'text-brand'} />
                )
              }
            >
              {item.NewValue}
            </Badge>
          );
        }
        case 'FDSEntityTag':
          return (
            <Badge className={`${tagClasses} h-7 mr-1.5 mt-3 rounded-2xl`}>
              {item.NewValue}
            </Badge>
          );
        default:
          '';
      }
    }
  };

  return (
    <>
      {!isAddTimeline ? (
        <div className={'h-fit bg-greyLight pb-10'}>
          <div className={'flex items-center p-4'}>
            <Text className={'text-sm mr-3'}>Timeline</Text>
            <div className={buttonIconClasses}>
              <Plus
                size={18}
                className={'bg-brand rounded-[50%] text-white'}
                onClick={() => {
                  setIsAddTimeline(true);
                }}
              />
            </div>
          </div>
          <Timeline
            active={1}
            lineWidth={2}
            className={'mx-2.5 overflow-y-auto max-h-[500px]'}
            css={timeLineCss}
          >
            {data.item?.TimeLines?.map((item, index) => (
              <Timeline.Item bulletSize={12} className={'px-3.5'} key={index}>
                <div className={'flex items-center'}>
                  <Text className={'mr-1.5 text-xs'}>
                    {format(new Date(item.CreateDate), 'HH:mm')}
                  </Text>
                  <Text className={'mr-1.5 text-sm font-semibold'}>
                    {item.CreateUser}
                  </Text>
                  <Text className={'text-xs italic'}>
                    {getActionTitle(item)}
                  </Text>
                </div>
                {getEntity(item)}
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      ) : (
        <AddTimeline
          setIsAddTimeline={setIsAddTimeline}
          setFiles={setFiles}
          onSubmit={onSubmit}
          item={data.item}
          isMutating={isMutating}
        />
      )}
    </>
  );
}

export default TicketTimeline;
