import buildQuery from 'odata-query';
import { MemberEditData, User, UserRole } from 'types/Users';
import * as Request from 'Shared/helpers/request';
import { FilterParams } from 'Admin/components/Filter';

export const addUsers = async (data: MemberEditData) => {
  return await Request.POST({
    url: 'Api/Users/Detail',
    params: {
      ...data.user,
      UserName: data.user.Email,
      Password: 'Test123!',
    },
    type: 'User',
  });
};

export const updateUsers = async (data: MemberEditData) => {
  return await Request.PUT({
    url: 'Api/Users/Detail',
    params: data.user,
    type: 'User',
  });
};

export const deleteUsers = async (data: MemberEditData) => {
  return await Request.DELETE('Api/Users/Activity', data.user.Id);
};

export const setUserPassword = async (data: MemberEditData) => {
  return await Request.POST({
    url: `Api/Users/ResetUserPassword/${data.user.Id}?password=${data.user.Password}`,
    params: {},
  });
};

export const fetchAllUsers = async ({
  filterParams,
  searchElement,
  skip = 0,
  top,
  id,
}: {
  filterParams?: FilterParams;
  searchElement?: string;
  skip?: number;
  top?: number;
  id?: string;
}) => {
  const MemberDataList =
    'FirstName,Surname,JobTitle,PhoneNumber,Email,UserRole,Id,LockoutEnd,LockoutEnabled';
  const now = new Date();
  const unlockedUser = {
    or: [
      {
        LockoutEnd: {
          ne: {
            type: 'raw',
            value: null,
          },
        },
      },
      {
        LockoutEnd: {
          lt: {
            type: 'raw',
            value: now.toISOString(),
          },
        },
      },
      {
        LockoutEnabled: {
          ne: {
            type: 'raw',
            value: false,
          },
        },
      },
    ],
  };

  const filterdUsers = (filterParams?.reporterIds || [])
    .filter((id) => {
      const findUser = filterParams?.assigneeIds?.find(
        (userId) => userId === id
      );
      if (!findUser) {
        return id;
      }
    })
    .concat(filterParams?.assigneeIds || []);

  const Id =
    filterdUsers && filterdUsers.length
      ? {
          and: {
            or: filterdUsers.map((id) => ({
              '': id,
            })),
          },
        }
      : {};
  const search = searchElement
    ? {
        or: [
          {
            [`indexof(toupper(FirstName), '${String(
              searchElement.toUpperCase()
            )}')`]: {
              ge: 0,
            },
          },
          {
            [`indexof(toupper(Surname), '${String(
              searchElement.toUpperCase()
            )}')`]: { ge: 0 },
          },
          {
            [`indexof(toupper(JobTitle), '${String(
              searchElement.toUpperCase()
            )}')`]: { ge: 0 },
          },
        ],
      }
    : {};

  const query = buildQuery({
    filter: id
      ? { ...unlockedUser, Id: id }
      : { ...unlockedUser, Id, '': search },
    select: MemberDataList,
  });
  return await Request.GET(
    `Api/Users/-1${query}${top ? `&$top=${top}&$skip=${skip}` : ''}&$count=true`
  );
};

export const defaultUser: User = {
  Email: '',
  FirstName: '',
  JobTitle: '',
  PhoneNumber: '',
  Surname: '',
  UserRole: UserRole.Regular,
};
