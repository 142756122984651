import { Badge, Button, LoadingOverlay, Text } from '@mantine/core';
import { ArrowBackUp, CircleMinus, Plus } from 'tabler-icons-react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import PageHeader from 'Admin/components/PageHeader';
import TicketCard from 'Admin/components/Ticket/TicketCard';
import TicketDetail from 'Admin/components/Ticket/TicketDetail';
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FormTicketMode,
  Ticket,
  TicketEditData,
  TicketStatus,
} from 'types/Tickets';
import CreateTicket from 'Admin/components/Ticket/CreateTicket';
import TicketTimeline from 'Admin/components/Ticket/TicketTimeline';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addTicket, fetchTickets, updateTicket } from 'Shared/data/Tickets';
import AddLabels from 'Admin/components/Ticket/AddLabels';
import { fetchAllUsers } from 'Shared/data/Users';
import { User } from 'types/Users';
import {
  addEntityTag,
  deleteEntityTag,
  fetchEntityTags,
  fetchTags,
} from 'Shared/data/Tags';
import DeleteModal from 'Admin/components/DeleteModal';
import Filter, { FilterParams } from 'Admin/components/Filter';
import {
  buttonIconClasses,
  customScrollYCss,
  tagClasses,
} from 'Shared/helpers/styles';
import TicketSearch from 'Admin/components/Ticket/TicketSearch';
import { Reminder } from 'types/Reminders';
import { fetchReminders } from 'Shared/data/Reminders';
import { useStore } from 'Shared/data/Store';
import useDebounce from 'Shared/hooks/useDebounce';
import SortBySelect from 'Admin/components/SortBySelect';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { fetchLocations } from 'Shared/data/Locations';
import { Location } from 'types/Locations';

export interface Tag {
  Id: number;
  TagName: string;
}

interface Labels {
  labels?: string[];
}

const sortByOptions = [
  { value: 'CreateDate', label: 'Create date' },
  { value: 'UpdateDate', label: 'Update date' },
  { value: 'DueDate', label: 'Due date' },
];

const notificationMessages = {
  [FormTicketMode.AddTicket]: 'The new tikcet has been added successfully',
  [FormTicketMode.EditTicket]: 'The tikcet has been changed successfully',
  [FormTicketMode.DeleteTicket]: 'The ticket has been deleted successfully',
  [FormTicketMode.AddLabel]: 'The new label has been added successfully',
  [FormTicketMode.AddNewTimeline]:
    'The new timeline has been added successfully',
  error:
    'Error encountered. Please try again or contact the administrator if the issue persists.',
};

const statusTicket = Object.values(TicketStatus);

function Tickets() {
  const { ticketId, mode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultEditData = { item: undefined, mode: undefined };
  const [ticketBeingEdited, setTicketBeingEdited] =
    useState<TicketEditData>(defaultEditData);
  const [tickets, setTickets] = useState<Ticket[] | undefined>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [isAddLabel, setIsAddLabel] = useState<boolean>(false);
  const [labelsId, setLabelsId] = useState<string[] | []>([]);
  const [selectedLebels, setSelectedLebels] = useState<string[] | []>([]);
  const [filterParams] = useState<FilterParams>({
    locationIds: searchParams.get('locationIds')
      ? [...(searchParams.get('locationIds') || '').split(',')].map((id) =>
          Number(id)
        )
      : [],
    labelsNames: searchParams.get('labelsNames')
      ? [...(searchParams.get('labelsNames') || '').split(',')]
      : [],
    statuses: searchParams.get('statuses')
      ? [...(searchParams.get('statuses') || '').split(',')]
      : [],
    createdate: searchParams.get('createDate')
      ? [...(searchParams.get('createDate') || '').split(',')].map(
          (date) => new Date(date)
        )
      : [],
    duedate: searchParams.get('dueDate')
      ? [...(searchParams.get('dueDate') || '').split(',')].map(
          (date) => new Date(date)
        )
      : [],
    reporterIds: searchParams.get('reporterIds')
      ? [...(searchParams.get('reporterIds') || '').split(',')]
      : [],
    assigneeIds: searchParams.get('assigneeIds')
      ? [...(searchParams.get('assigneeIds') || '').split(',')]
      : [],
  });
  const [deletedImages, setDeletedImages] = useState<string[] | []>([]);
  const [searchResult, setSearchResult] = useState<Ticket[] | undefined>(
    undefined
  );
  const [isRefetching, setIsRefetching] = useState(false);
  const [deleteLabel, setDeleteLabel] = useState<undefined | string>(undefined);
  const perPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchPageNumber, setSearchPageNumber] = useState<number>(1);
  const [skipSearch, setSkipSearch] = useState<number>(
    perPage * (searchPageNumber - 1)
  );
  const [searchHasMore, setSearchHasMore] = useState<boolean>(false);
  const [isAddTickeLabel, setIsAddTickeLabel] = useState<boolean>(false);
  const [sortByDate] = useState(searchParams.get('sortByDate') || 'CreateDate');
  const [sortByOrder] = useState(searchParams.get('sortByOrder') || 'desc');
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [reminders, setReminders] = useState<Reminder[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { currentUser } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
    currentUser: state.user,
  }));

  const deferredSearchQuery = useDebounce(
    useDeferredValue(searchParams.get('search') || ''),
    500
  );
  const isAddTicketMode = ticketBeingEdited?.mode === FormTicketMode.AddTicket;
  const isEditTicketMode =
    ticketBeingEdited?.mode === FormTicketMode.EditTicket;
  const isDeleteTicketMode =
    ticketBeingEdited?.mode === FormTicketMode.DeleteTicket;

  const { isFetching, isError, refetch } = useQuery<
    { value: Ticket[]; ['@odata.count']: number },
    Error
  >(
    ['tickets'],
    async () =>
      await fetchTickets({
        filterParams,
        searchElement: deferredSearchQuery,
        skip: searchParams.get('search') ? skipSearch : 0,
        top: searchParams.get('search')
          ? perPage
          : Number(searchParams.get('top') || perPage),
        id: Number(ticketId),
        sortByValue: sortByDate,
        sortByOrder: sortByOrder,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
    }
  );

  const setData = useCallback(
    async ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: Ticket[];
      ['@odata.count']: number;
    }) => {
      setIsLoading(true);
      const filterdData = data?.filter((ticket) => !ticket.TimeLines) ?? [];
      if (!searchParams.get('search') && !ticketId) {
        setTickets([...(filterdData || [])]);
        setHasMore(dataCount > Number(searchParams.get('top') || perPage));
      }
      if (ticketId && !searchParams.get('search')) {
        if (data[0].Reminders?.length) {
          const response = await fetchReminders({
            id: data[0].Reminders[0].ReminderId,
          });
          if (response && response.value) {
            setReminders(response.value);
          }
        }
        setTicketBeingEdited({
          item: data[0],
          mode: FormTicketMode.EditTicket,
        });
        if (mode) {
          setEditMode(true);
        }
      }
      if (searchParams.get('search')) {
        if (!tickets?.length) {
          const response = await fetchTickets({
            filterParams,
            top: Number(searchParams.get('top') || perPage),
            skip: 0,
            sortByValue: sortByDate,
            sortByOrder: sortByOrder,
          });
          if (response && response.value) {
            setTickets(response.value);
            setHasMore(
              response['@odata.count'] > Number(searchParams.get('top'))
            );
          }
        }
        setSearchResult([
          ...(skipSearch === 0 ? [] : searchResult || []),
          ...(filterdData || []),
        ]);
        setSearchHasMore(dataCount > skipSearch + perPage);
      }
      setIsLoading(false);
    },
    [
      tickets,
      filterParams,
      searchParams.get('search'),
      searchPageNumber,
      skipSearch,
      sortByDate,
      sortByOrder,
    ]
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetching || searchParams.get('search') || ticketId) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          searchParams.set(
            'top',
            String(perPage * (Number(searchParams.get('page') || 1) + 1))
          );
          searchParams.set(
            'page',
            String(Number(searchParams.get('page') || 1) + 1)
          );
          setSearchParams(searchParams);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetching, hasMore]
  );

  const observerSearch: any = useRef();
  const lastSearchElementRef = useCallback(
    (node: any) => {
      if (isRefetching || !searchParams.get('search')) return;
      if (observerSearch.current) observerSearch.current.disconnect();
      observerSearch.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          searchHasMore &&
          node !== observerSearch.current
        ) {
          setSearchPageNumber(searchPageNumber + 1);
          setSkipSearch(perPage * searchPageNumber);
        }
      });
      if (node) observerSearch.current.observe(node);
    },
    [isRefetching, searchHasMore, searchParams.get('search')]
  );

  const refetchData = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  useEffect(() => {
    if (!ticketId) {
      refetchData();
    }
    if (!deferredSearchQuery) {
      setSearchResult([]);
    }
  }, [searchPageNumber]);

  const mutationRequst = async (params: TicketEditData & Labels) => {
    setIsMutating(true);
    if (deleteLabel) {
      const response = await fetchEntityTags(
        ticketBeingEdited.item?.FDSTicketId,
        deleteLabel,
        'TicketId'
      );
      if (response && response.value.length) {
        return await deleteEntityTag(response.value[0].EntityTagId);
      } else return '';
    }
    if (params.mode === FormTicketMode.AddNewTimeline) {
      setFiles([]);
      return await updateTicket(
        params.item?.FDSTicketId,
        {
          Content: params.item?.Content,
        },
        files
      );
    }
    if (isAddTickeLabel) {
      return await addEntityTag(params.labels, {
        FDSTicketId: ticketBeingEdited.item?.FDSTicketId,
      });
    }
    if (isEditTicketMode) {
      setFiles([]);
      setDeletedImages([]);
      return await updateTicket(
        Number(ticketId),
        {
          Title: params.item?.Title,
          Description: params.item?.Description,
          Status: params.item?.Status,
          AssignUserId: params.item?.AssignUserId,
        },
        files,
        deletedImages
      );
    }
    if (isDeleteTicketMode) {
      return await updateTicket(
        params.item?.FDSTicketId,
        { Status: 'Cancelled' },
        files
      );
    }

    return await addTicket({
      ...params,
      item: {
        ...params.item,
        TagIds: labelsId.toString(),
      },
    });
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationRequst, {
    onSuccess: () => {
      setIsMutating(false);
      if (isEditTicketMode) {
        navigate(`/tickets/${ticketBeingEdited.item?.FDSTicketId}`, {
          state: location.state,
        });
      }
      if (!isEditTicketMode) {
        queryClient.invalidateQueries(['tickets']);
        setTicketBeingEdited(defaultEditData);
      }
      if (isAddTickeLabel) {
        setIsAddTickeLabel(false);
        setIsAddLabel(false);
      }

      setLabelsId([]);
      setSearchResult([]);
      setSelectedLebels([]);
      setDeleteLabel(undefined);
    },
    onError: () => {
      setIsMutating(false);
    },
  });

  const onSubmit = (data: TicketEditData) => {
    mutation.mutate({
      item: data.item,
      mode: data.mode,
    });
  };

  const handleReset = () => {
    if (isAddTicketMode) {
      setTicketBeingEdited(defaultEditData);
      setLabelsId([]);
      setSelectedLebels([]);
    } else {
      navigate(location.key !== 'default' ? -1 : '/tickets');
    }
  };

  const handleAddLabels = (labels?: any[]) => {
    const filterdLabels = labels
      ?.filter((tag) => {
        const findLabel = ticketBeingEdited.item?.Labels?.find(
          (tagName) => tagName === tag.label
        );
        if (!findLabel) return tag;
      })
      .map((tag) => tag.value);
    mutation.mutate({ labels: filterdLabels, mode: FormTicketMode.AddLabel });
  };

  useEffect(() => {
    if (mutation.variables) {
      const data = mutation.variables;

      if (data.mode && mutation.isSuccess) {
        showOkToast(notificationMessages[data.mode]);
      } else if (mutation.isError) {
        showErrorToast(notificationMessages['error']);
      }
    }
  }, [mutation.variables, mutation.isSuccess, mutation.isError]);

  return (
    <div>
      <PageHeader pageName={'Tickets'}>
        {!ticketId ? (
          <TicketSearch
            searchResult={searchResult}
            setTickets={setTickets}
            lastElementRef={lastSearchElementRef}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ) : (
          ''
        )}
        {isAddTicketMode || isEditTicketMode || editMode ? (
          <Button
            className={'ml-4 text-brand border-brand w-80'}
            leftIcon={<ArrowBackUp size={20} />}
            size="md"
            variant="outline"
            onClick={() => handleReset()}
          >
            Go back
          </Button>
        ) : (
          <Button
            className={'bg-brand ml-4 w-80'}
            leftIcon={<Plus size={20} />}
            size="md"
            onClick={() =>
              setTicketBeingEdited({
                item: undefined,
                mode: FormTicketMode.AddTicket,
              })
            }
          >
            Create new ticket
          </Button>
        )}
      </PageHeader>
      <div className={'relative w-full max-h-max flex mt-2 justify-between'}>
        <LoadingOverlay visible={isLoading || isFetching || isRefetching} />
        {!isEditTicketMode && !editMode ? (
          <>
            <div className={'w-72'}>
              <Filter
                title={'Filter tickets'}
                filterParams={filterParams}
                statusesOptions={statusTicket}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                isTicketFilter={true}
              />
            </div>
            <div className={'mr-5 h-4/5 w-3/5'}>
              <SortBySelect
                data={sortByOptions}
                classNames={'mb-4 font-medium text-base'}
                value={sortByDate}
                order={sortByOrder}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />

              <div
                className={'overflow-y-auto overflow-x-hidden pr-2'}
                css={customScrollYCss}
              >
                {tickets?.length ? (
                  tickets.map((ticket, index) => (
                    <TicketCard
                      key={'ticket' + ticket.FDSTicketId}
                      ticket={ticket}
                      status={ticket.Status}
                      title={ticket.Title}
                      description={ticket.Description}
                      labels={ticket.Labels}
                      CreateDate={ticket.CreateDate}
                      UpdateDate={ticket.UpdateDate}
                      setTicketBeingEdited={setTicketBeingEdited}
                      setEditMode={setEditMode}
                      lastElementRef={
                        tickets.length === index + 1
                          ? lastElementRef
                          : undefined
                      }
                      currentUser={currentUser}
                    />
                  ))
                ) : (
                  <Text
                    className={'w-full text-center my-20 text-3xl font-medium'}
                  >
                    No Data
                  </Text>
                )}
              </div>
            </div>
          </>
        ) : (
          <TicketDetail
            data={ticketBeingEdited}
            setTicketBeingEdited={setTicketBeingEdited}
            onSubmit={onSubmit}
            editMode={editMode}
            setEditMode={setEditMode}
            setFiles={setFiles}
            isMutating={mutation.isLoading || isMutating}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
            reminders={reminders}
          />
        )}
        {!isAddTicketMode && !isEditTicketMode ? (
          <div className={'w-80'}></div>
        ) : (
          ''
        )}
        {isAddTicketMode ? (
          <div className={'max-w-80'}>
            <CreateTicket
              data={ticketBeingEdited}
              onSubmit={onSubmit}
              setIsAddLabel={setIsAddLabel}
              labels={selectedLebels}
              setSelectedLebels={setSelectedLebels}
              labelsId={labelsId}
              setLabelsId={setLabelsId}
              isMutating={mutation.isLoading}
            />
          </div>
        ) : (
          ''
        )}
        {isEditTicketMode ? (
          <div className={'flex flex-col w-2/6'}>
            <TicketTimeline
              setFiles={setFiles}
              onSubmit={onSubmit}
              data={ticketBeingEdited}
              isMutating={mutation.isLoading || isMutating}
            />
            <div className={'h-fit bg-greyLight p-6 mt-5'}>
              <div className={'flex items-center w-full'}>
                <Text className={'text-sm mr-3'}>Labels</Text>
                <div
                  className={buttonIconClasses}
                  onClick={() => {
                    setIsAddLabel(true);
                    setIsAddTickeLabel(true);
                  }}
                >
                  <Plus
                    size={18}
                    className={'bg-brand rounded-[50%] text-white'}
                  />
                </div>
              </div>
              <div
                className={'flex flex-wrap w-full mt-6 h-28 overflow-y-auto'}
                css={customScrollYCss}
              >
                {ticketBeingEdited.item?.Labels?.map((label, index) => (
                  <Badge
                    className={`${tagClasses} h-7 mr-1.5 mb-2.5 rounded-2xl`}
                    key={index}
                    leftSection={
                      <CircleMinus
                        onClick={() => setDeleteLabel(label)}
                        className={'text-brand cursor-pointer'}
                        size={20}
                      />
                    }
                  >
                    {label}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {isAddLabel ? (
          <AddLabels
            title={'Add labels'}
            opened={isAddLabel || isAddTickeLabel}
            onClose={() => {
              setIsAddLabel(false);
              setIsAddTickeLabel(false);
            }}
            labelsId={labelsId}
            setLabelsId={setLabelsId}
            selectedTags={
              isAddTickeLabel ? ticketBeingEdited.item?.Labels : selectedLebels
            }
            setSelectedLebels={setSelectedLebels}
            isAddTicketMode={isAddTicketMode}
            handleAddLabels={isEditTicketMode ? handleAddLabels : undefined}
          />
        ) : (
          ''
        )}
        {deleteLabel ? (
          <DeleteModal
            opened={deleteLabel}
            item={ticketBeingEdited.item}
            mode={ticketBeingEdited.mode}
            isMutating={mutation.isLoading}
            hasMutationError={isError}
            onClose={() => setDeleteLabel(undefined)}
            onSubmit={onSubmit}
          />
        ) : (
          ''
        )}
        {isDeleteTicketMode ? (
          <DeleteModal
            opened={isDeleteTicketMode}
            item={ticketBeingEdited.item}
            mode={ticketBeingEdited.mode}
            isMutating={mutation.isLoading}
            hasMutationError={isError}
            onClose={() => setTicketBeingEdited(defaultEditData)}
            onSubmit={onSubmit}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default Tickets;
