import { Text, Select, MantineSize, MantineNumberSize } from '@mantine/core';
import {
  forwardRef,
  ReactElement,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import useDebounce from 'Shared/hooks/useDebounce';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string;
  label: string;
  index: number;
}

interface CustomSelectProps {
  classNames?: string;
  options: any[];
  request: any;
  onChange(value: any): void;
  setData(value: any): void;
  placeholder: string;
  defaultValue?: string;
  dataList?: any[];
  size?: MantineSize;
  rightSection?: ReactElement;
  cssStyles?: any;
  label?: string;
  radius?: MantineNumberSize;
  value?: any;
  itemComponent?: React.FC<any>;
}

const CustomSelect = ({
  classNames,
  options,
  request,
  onChange,
  setData,
  placeholder,
  defaultValue,
  dataList,
  size,
  rightSection,
  cssStyles,
  label,
  radius,
  value,
  itemComponent,
}: CustomSelectProps) => {
  const [showList, setShowList] = useState(false);
  const [searchElement, setSearchElement] = useState<string>('');
  const perPage = 20;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [skip, setSkip] = useState<number>(perPage * (pageNumber - 1));
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const deferredSearchQuery = useDebounce(useDeferredValue(searchElement), 500);

  const { refetch } = useQuery<
    { value: any[]; ['@odata.count']: number },
    Error
  >(
    [placeholder],
    () =>
      showList
        ? request({
            searchElement,
            skip,
            top: perPage,
          })
        : [],
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data) {
          setSelectData(data);
        }
      },
    }
  );

  const setSelectData = useCallback(
    ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: any[];
      ['@odata.count']: number;
    }) => {
      setData([...(skip === 0 ? [] : dataList || []), ...(data || [])]);
      setHasMore(dataCount > skip + perPage);
    },
    [dataList, pageNumber, skip, searchElement]
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetching) return '';
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          setPageNumber(pageNumber + 1);
          setSkip(perPage * pageNumber);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetching, hasMore]
  );

  const refetchData = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  useEffect(() => {
    refetchData();
  }, [pageNumber, deferredSearchQuery, showList]);

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, index, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Text
          className={'w-full cursor-pointer'}
          ref={options.length === index + 1 ? lastElementRef : undefined}
          key={value}
        >
          {label}
        </Text>
      </div>
    )
  );
  SelectItem.displayName = placeholder;

  return (
    <div className={'w-full'} onClick={() => setShowList(true)}>
      <Select
        placeholder={placeholder}
        itemComponent={itemComponent ? itemComponent : SelectItem}
        className={classNames}
        css={cssStyles}
        data={options}
        maxDropdownHeight={400}
        nothingFound="No data"
        value={value}
        defaultValue={defaultValue}
        filter={(value, item) =>
          (item?.label || '')
            .toLowerCase()
            .includes((value || '').toLowerCase().trim())
        }
        onChange={(value) => {
          onChange(String(value));
        }}
        onSearchChange={(value) => setSearchElement(value)}
        size={size}
        rightSection={rightSection}
        radius={radius}
        label={label}
      />
    </div>
  );
};

export default CustomSelect;
