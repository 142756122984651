import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button } from '@mantine/core';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import DeleteModal from 'Admin/components/DeleteModal';
import PageHeader from 'Admin/components/PageHeader';
import CreateEditModal from 'Admin/components/Remainder/CreateEditModal';
import ReminderList from 'Admin/components/Remainder/ReminderList';
import {
  addReminder,
  deleteReminder,
  fetchReminders,
  updateReminder,
} from 'Shared/data/Reminders';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import { FormReminderMode, Reminder, ReminderEditData } from 'types/Reminders';
import ReminderSearch from 'Admin/components/Remainder/ReminderSearch';
import { useStore } from 'Shared/data/Store';
import useDebounce from 'Shared/hooks/useDebounce';

const notificationMessages = {
  [FormReminderMode.Add]: 'The new reminder has been added successfully',
  [FormReminderMode.Edit]: 'Reminder details saved successfully',
  [FormReminderMode.Delete]: 'The reminder has been deleted successfully',
  error:
    'Error encountered. Please try again or contact the administrator if the issue persists.',
};

const Reminders = () => {
  const { reminderId } = useParams();
  const navigate = useNavigate();
  const defaultEditData = { item: undefined, mode: undefined };
  const [searchParams, setSearchParams] = useSearchParams();
  const [reminderBeingEdited, setReminderBeingEdited] =
    useState<ReminderEditData>(defaultEditData);
  const [reminders, setReminders] = useState<Reminder[] | undefined>(undefined);
  const [searchResult, setSearchResult] = useState<Reminder[] | []>([]);
  const perPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchPageNumber, setSearchPageNumber] = useState<number>(1);
  const [skipSearch, setSkipSearch] = useState<number>(
    perPage * (searchPageNumber - 1)
  );
  const [searchHasMore, setSearchHasMore] = useState<boolean>(false);
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deferredSearchQuery = useDebounce(
    useDeferredValue(searchParams.get('search') || ''),
    500
  );

  const { currentUser } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
    currentUser: state.user,
  }));

  const isAddMode = reminderBeingEdited.mode === FormReminderMode.Add;
  const isEditMode = reminderBeingEdited.mode === FormReminderMode.Edit;
  const isDeleteMode = reminderBeingEdited.mode === FormReminderMode.Delete;

  const { isFetching, isError, error, refetch } = useQuery<
    {
      value: Reminder[];
      ['@odata.count']: number;
    },
    Error
  >(
    ['reminders'],
    async () =>
      await fetchReminders({
        searchElement: deferredSearchQuery,
        skip: searchParams.get('search') ? skipSearch : 0,
        top: searchParams.get('search')
          ? perPage
          : Number(searchParams.get('top') || perPage),
        id: Number(reminderId),
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
    }
  );

  const setData = useCallback(
    async ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: Reminder[];
      ['@odata.count']: number;
    }) => {
      setIsLoading(true);
      if (data && !searchParams.get('search')) {
        setReminders([...(data || [])]);
        setHasMore(dataCount > Number(searchParams.get('top') || perPage));
        if (reminderId && dataCount === 1) {
          setReminderBeingEdited({
            item: data[0],
            mode: FormReminderMode.Edit,
          });
        }
      }
      if (searchParams.get('search')) {
        if (!reminders?.length) {
          const response = await fetchReminders({
            top: Number(searchParams.get('top') || perPage),
            skip: 0,
          });
          if (response && response.value) {
            setReminders(response.value);
            setHasMore(dataCount > Number(searchParams.get('top') || perPage));
          }
        }
        setSearchResult([
          ...(skipSearch === 0 ? [] : searchResult || []),
          ...(data || []),
        ]);
        setSearchHasMore(dataCount > skipSearch + perPage);
      }
      setIsLoading(false);
    },
    [reminders, searchParams.get('search'), searchPageNumber, skipSearch]
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetch || searchParams.get('search') || reminderId) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          searchParams.set(
            'top',
            String(perPage * (Number(searchParams.get('page') || 1) + 1))
          );
          searchParams.set(
            'page',
            String(Number(searchParams.get('page') || 1) + 1)
          );
          setSearchParams(searchParams);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetch, hasMore]
  );

  const observerSearch: any = useRef();
  const lastSearchElementRef = useCallback(
    (node: any) => {
      if (isRefetch || !searchParams.get('search')) return;
      if (observerSearch.current) observerSearch.current.disconnect();
      observerSearch.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          searchHasMore &&
          node !== observerSearch.current
        ) {
          setSearchPageNumber(searchPageNumber + 1);
          setSkipSearch(perPage * searchPageNumber);
        }
      });
      if (node) observerSearch.current.observe(node);
    },
    [isRefetch, searchHasMore, searchParams.get('search')]
  );

  const refetchReminders = async () => {
    setIsRefetch(true);
    await refetch();
    setIsRefetch(false);
  };

  useEffect(() => {
    if (!reminderId) {
      refetchReminders();
    }
    if (!deferredSearchQuery) {
      setSearchResult([]);
    }
  }, [searchPageNumber]);

  const mutationRequst = (params: ReminderEditData) => {
    if (isEditMode) {
      const payload = {
        Title: params.item?.Title,
        DueDate: params.item?.DueDate,
        TicketId: params.item?.TicketId,
        AssignUserId: params.item?.AssignUserId,
      };
      if (!params.item?.TicketId) delete payload.TicketId;
      if (!params.item?.AssignUserId) {
        delete payload.AssignUserId;
      }
      return updateReminder(params.item?.ReminderId, payload);
    }
    if (isDeleteMode) return deleteReminder(params.item?.ReminderId);
    if (!params.item?.TicketId) delete params.item?.TicketId;
    return addReminder(params);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationRequst, {
    onSuccess: () => {
      navigate('/reminders');
    },
  });
  const onSubmit = (data: ReminderEditData) => {
    mutation.mutate({
      item: data.item,
      mode: data.mode,
    });
  };

  useEffect(() => {
    if (mutation.variables) {
      const data = mutation.variables;

      if (data.mode && mutation.isSuccess) {
        showOkToast(notificationMessages[data.mode]);
      } else if (mutation.isError) {
        showErrorToast(notificationMessages['error']);
      }
    }
  }, [mutation.variables, mutation.isSuccess, mutation.isError]);

  return (
    <div className={'reminders'}>
      <PageHeader pageName={'Reminders'}>
        <ReminderSearch
          searchResult={searchResult}
          setReminders={setReminders}
          lastElementRef={lastSearchElementRef}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <Button
          className={'bg-brand ml-4'}
          leftIcon={<Plus size={20} />}
          size="md"
          onClick={() =>
            setReminderBeingEdited({
              item: undefined,
              mode: FormReminderMode.Add,
            })
          }
        >
          Add reminder
        </Button>
      </PageHeader>
      <ReminderList
        hasOverlay={isFetching || isLoading}
        isError={isError}
        error={error ?? undefined}
        onActionClicked={setReminderBeingEdited}
        reminders={reminders}
        currentUser={currentUser}
        lastElementRef={lastElementRef}
      />
      {isAddMode || isEditMode ? (
        <CreateEditModal
          opened={!!reminderBeingEdited.mode}
          data={reminderBeingEdited}
          onClose={() => {
            setReminderBeingEdited(defaultEditData);
            if (isEditMode) {
              navigate(-1);
            }
          }}
          isMutating={mutation.isLoading}
          onSubmit={onSubmit}
        />
      ) : (
        ''
      )}
      {isDeleteMode ? (
        <DeleteModal
          item={reminderBeingEdited.item}
          mode={reminderBeingEdited.mode}
          opened={'reminder'}
          onClose={() => setReminderBeingEdited(defaultEditData)}
          isMutating={mutation.isLoading}
          hasMutationError={mutation.isError}
          onSubmit={onSubmit}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Reminders;
