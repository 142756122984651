import { Route as AppRoute } from 'types/App';
import React, { ReactElement } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import {
  BellRinging,
  BuildingWarehouse,
  Dashboard as DashboardIcon,
  ListCheck,
  Settings as SettingsIcon,
  Template,
  Ticket,
  Users,
} from 'tabler-icons-react';
import Dashboard from 'Admin/pages/Dashboard';
import Login from 'Admin/pages/Login';
import Locations from 'Admin/pages/Locations';
import Checklists from 'Admin/pages/Checklists';
import ChecklistsDetails from 'Admin/pages/Checklists/Details';
import Tickets from 'Admin/pages/Tickets';
import Members from 'Admin/pages/Members';
import Reminders from 'Admin/pages/Reminders';
import Settings from 'Admin/pages/Settings';
import MainLayout from 'Admin/components/MainLayout';
import Templates from 'Admin/pages/Templates';

export const routes: AppRoute[] = [
  {
    path: 'dashboard',
    component: <Dashboard />,
    menuItem: { parent: '', icon: DashboardIcon, label: 'Dashboard' },
  },
  {
    path: 'login',
    component: <Login />,
    menuItem: { parent: '', icon: DashboardIcon, label: '' },
  },
  {
    path: 'locations',
    component: <Locations />,
    menuItem: { parent: '', icon: BuildingWarehouse, label: 'Locations' },
  },
  {
    path: 'locations/:locationId',
    component: <Locations />,
    menuItem: {
      parent: '/locations',
      icon: BuildingWarehouse,
      label: 'Locations',
    },
  },
  {
    path: 'locations/:locationId/floors/:floorId',
    component: <Locations />,
    menuItem: {
      parent: '/locations',
      icon: BuildingWarehouse,
      label: 'Locations',
    },
  },
  {
    path: 'locations/:locationId/floors/:floorId/area/:areaId',
    component: <Locations />,
    menuItem: {
      parent: '/locations',
      icon: BuildingWarehouse,
      label: 'Locations',
    },
  },
  {
    path: 'checklists',
    component: <Checklists />,
    menuItem: { parent: '', icon: ListCheck, label: 'Checklists' },
  },
  {
    path: 'checklists/templates',
    component: <Templates />,
    menuItem: { parent: '/checklists', icon: Template, label: 'Templates' },
  },
  {
    path: 'checklists/:checklistId',
    component: <Checklists />,
    menuItem: {
      parent: '/checklists',
      icon: ListCheck,
      label: 'View Checklist',
    },
  },
  {
    path: 'checklists/templates/:templateId',
    component: <Templates />,
    menuItem: { parent: '/checklists', icon: Template, label: 'Templates' },
  },
  {
    path: 'checklists/templates/:templateId/:mode',
    component: <Templates />,
    menuItem: { parent: '/checklists', icon: Template, label: 'Templates' },
  },
  {
    path: 'tickets',
    component: <Tickets />,
    menuItem: { parent: '', icon: Ticket, label: 'Tickets' },
  },
  {
    path: 'tickets/:ticketId',
    component: <Tickets />,
    menuItem: { parent: 'tickets', icon: Ticket, label: 'Tickets' },
  },
  {
    path: 'tickets/:ticketId/:mode',
    component: <Tickets />,
    menuItem: { parent: 'tickets', icon: Ticket, label: 'Tickets' },
  },
  {
    path: 'reminders',
    component: <Reminders />,
    menuItem: { parent: '', icon: BellRinging, label: 'Reminders' },
  },
  {
    path: 'reminders/:reminderId',
    component: <Reminders />,
    menuItem: { parent: 'reminders', icon: BellRinging, label: 'Reminders' },
  },
  {
    path: 'members',
    component: <Members />,
    menuItem: { parent: '', icon: Users, label: 'Members' },
  },
  {
    path: 'settings',
    component: <Settings />,
    menuItem: { parent: '', icon: SettingsIcon, label: 'Settings' },
  },
];

function AdminRoutes() {
  const location = useLocation();
  const routeComponents = [
    <Route
      path=""
      key={'redirect'}
      element={<Navigate to="dashboard" replace />}
    />,
    ...routes
      .filter((route: AppRoute) => Boolean(route.path))
      .map((route: AppRoute) => (
        <Route key={route.path} path={route.path} element={route.component} />
      )),
    <Route
      path="*"
      key={'fallback'}
      element={<Navigate to="dashboard" replace />}
    />,
  ];

  return (
    <Routes location={location}>
      <Route path={'/'} element={<MainLayout />}>
        {routeComponents}
      </Route>
    </Routes>
  );
}

export default AdminRoutes;
