import { ChevronDown, ChevronUp, Icon, Template } from 'tabler-icons-react';
import { Tooltip } from '@mantine/core';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

interface NavbarLinkProps {
  icon: Icon;
  label: string;
  path?: string;
  className?: string;
  onClick?(): void;
}

export default function NavbarLink({
  icon: Icon,
  label,
  path,
  className,
  onClick,
}: NavbarLinkProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const isChecklistPage = location.pathname?.startsWith('/checklists');
  const isTemplatePage = location.pathname?.startsWith('/checklists/templates');
  const [isOpenedDropdow, setIsOpenedDropdow] = useState<boolean>(
    !!isTemplatePage
  );

  const item = (
    <Tooltip
      label={label}
      className={`p-4 flex items-center ${className ?? ''}`}
      position="right"
      withArrow
      onClick={onClick}
    >
      <Icon />
      <span className="ml-3">{label}</span>
    </Tooltip>
  );

  const linkClass = 'inline-block rounded-md my-2 select-none';

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshTokenString');
    navigate('/login');
  };
  if (path === 'checklists') {
    return (
      <>
        <div className={'flex items-center'}>
          <NavLink
            className={({ isActive }) =>
              `w-full rounded-l-lg rounded-r-none ${linkClass} ${
                !isTemplatePage && isActive
                  ? ' bg-brand text-white'
                  : ' hover:bg-slate-100'
              }`
            }
            role="button"
            to={path}
          >
            {item}
          </NavLink>
          {isOpenedDropdow ? (
            <div
              className={`pt-4 cursor-pointer h-14 rounded-r-lg pr-2 ${
                isChecklistPage && !isTemplatePage
                  ? 'bg-brand text-white'
                  : 'hover:bg-slate-100 '
              }`}
            >
              <ChevronUp onClick={() => setIsOpenedDropdow(false)} />
            </div>
          ) : (
            <div
              className={`flex items-center cursor-pointer h-14 rounded-r-lg pr-2 ${
                isChecklistPage && !isTemplatePage
                  ? 'bg-brand text-white'
                  : 'hover:bg-slate-100 '
              }`}
            >
              <ChevronDown onClick={() => setIsOpenedDropdow(true)} />
            </div>
          )}{' '}
        </div>
        {isOpenedDropdow ? (
          <NavLink
            className={({ isActive }) =>
              `w-full rounded-l-lg h-14 ${linkClass} ${
                isActive ? ' bg-brand text-white' : ' hover:bg-slate-100'
              }`
            }
            role="button"
            to={'checklists/templates'}
          >
            <div className={'flex items-center justify-center h-full'}>
              <Template />
              <span className={'ml-3'}>Templates</span>
            </div>
          </NavLink>
        ) : (
          ''
        )}
      </>
    );
  }

  return path ? (
    <NavLink
      className={({ isActive }) =>
        linkClass + (isActive ? ' bg-brand text-white' : ' hover:bg-slate-100')
      }
      role="button"
      to={path}
    >
      {item}
    </NavLink>
  ) : (
    <button className={linkClass} onClick={() => logout()}>
      {item}
    </button>
  );
}
