import { css } from '@emotion/react';
import { Badge, LoadingOverlay } from '@mantine/core';
import { ResponsivePie } from '@nivo/pie';
import { dataItem } from 'Admin/pages/Dashboard';

interface DashboardSectionProps {
  data: dataItem[];
  allDataLength?: number;
  title: string;
  getLogo(): any;
  navigate?(value: string): void;
  isLoading: boolean;
}

const theme = {
  axis: {
    fontSize: '14px',
    tickColor: '#eee',
    ticks: {
      line: {
        stroke: '#555555',
      },
      text: {
        fill: '#ffffff',
      },
    },
    legend: {
      text: {
        fill: '#aaaaaa',
      },
    },
  },
  grid: {
    line: {
      stroke: '#555555',
    },
  },
};

const DashboardSection = ({
  data,
  allDataLength,
  title,
  getLogo,
  navigate,
  isLoading,
}: DashboardSectionProps) => {
  return (
    <div
      className={
        'relative flex flex-col h-fit bg-greyLight100 rounded-xl mb-3.5 mr-4 h-[230px]'
      }
    >
      <LoadingOverlay visible={isLoading} />
      <Badge
        className={
          'bg-transparent justify-start h-10 text-black100 font-medium text-base  normal-case'
        }
        leftSection={getLogo()}
      >
        {title}
      </Badge>
      <div className={'relative flex w-full items-center'}>
        <div className={'relative h-36 w-1/2'}>
          <ResponsivePie
            margin={{ top: 0, right: 20, bottom: 0, left: 0 }}
            data={data}
            innerRadius={0.7}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            theme={theme}
            borderWidth={1.5}
            borderColor="#ffffff"
            colors={{ datum: 'data.color' }}
            sortByValue={true}
            isInteractive={false}
          />
          <div
            className={
              'absolute flex items-center justify-center inset-0 pointer-events-none text-3xl font-bold right-5'
            }
          >
            {allDataLength}
          </div>
        </div>
        <div className={'w-1/2 flex flex-col mb-3'}>
          {data.map((item) => (
            <Badge
              key={item.label + ' ' + title}
              css={css`
                .mantine-Badge-rightSection {
                  margin-left: auto;
                }
              `}
              className={
                'justify-start bg-greyLightOpacity mt-3 normal-case text-xs font-semibold text-black h-7 w-60 cursor-pointer hover:bg-brand hover:text-white'
              }
              leftSection={
                <div
                  className={'h-3 w-3 rounded-[50%]'}
                  style={{ background: item.color }}
                ></div>
              }
              rightSection={
                <div
                  className={'flex items-center h-3 w-fit'}
                >{`(${item.count} Tickets)`}</div>
              }
              radius={'md'}
              onClick={() => navigate && navigate(item.label)}
            >
              {item.value ? item.value : 0}% {item.label}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardSection;
