import buildQuery from 'odata-query';
import { FilterParams } from 'Admin/components/Filter';
import * as Request from 'Shared/helpers/request';

export const fetchTags = async ({
  filterParams,
  skip = 0,
  top,
}: {
  filterParams?: FilterParams;
  skip?: number;
  top?: number;
}) => {
  const TagName = filterParams?.labelsNames?.length
    ? {
        and: {
          or: filterParams?.labelsNames?.map((id) => ({
            '': id,
          })),
        },
      }
    : {};
  const query = buildQuery({
    filter: { TagName },
  });
  return await Request.GET(
    `Api/Data/Read/FDSTag${query}${
      Object.keys(TagName).length ? '&$' : '?$'
    }count=true${top ? `&$top=${top}&$skip=${skip}` : ''}`
  );
};

export const fetchEntityTags = async (
  itemId?: number,
  tagName?: string,
  type?: string
) => {
  return await Request.GET(
    `Api/EntityTags/Odata?$filter=${type} eq ${itemId} and TagName eq '${tagName}'`
  );
};

export const addTag = async (name: string) => {
  return await Request.POST({
    url: 'Api/Data/FDSTag',
    params: { TagName: name },
    type: 'Label',
  });
};

export const addEntityTag = async (
  tagIds: any,
  data: { CheckListId?: number; FDSTicketId?: number }
) => {
  await Promise.all(
    tagIds.map(
      async (id: string) =>
        await Request.POST({
          url: 'Api/Data/FDSEntityTag',
          params: { TagId: Number(id), ...data },
        })
    )
  );
};

export const deleteEntityTag = async (tagId: number) => {
  await Request.DELETE(`Api/Data/FDSEntityTag`, tagId);
};
