import {
  Badge,
  Button,
  Group,
  Input,
  Select,
  Text,
  Textarea,
} from '@mantine/core';
import { forwardRef, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Calendar,
  ChevronDown,
  ChevronLeft,
  CircleMinus,
  Clock,
  Plus,
  Template as TemplateIcon,
} from 'tabler-icons-react';
import { FormTicketMode, Ticket, TicketEditData } from 'types/Tickets';
import { Location } from 'types/Locations';
import { Checklist, ChecklistEditData } from 'types/Checklist';
import { DatePicker, TimeInput } from '@mantine/dates';
import { Template } from 'types/Template';
import {
  buttonIconClasses,
  textAreaCss,
  inputCss,
  tagClasses,
  inputTimeCss,
} from 'Shared/helpers/styles';
import { UserState } from 'types/Store';
import { fetchLocations } from 'Shared/data/Locations';
import CustomSelect from '../CustomSelect';
import { showErrorToast } from 'Shared/helpers/ui';
import { fetchChecklistsTemplates } from 'Shared/data/Templates';

interface CreateChecklistProps {
  data: ChecklistEditData;
  onSubmit(data: ChecklistEditData & TicketEditData): void;
  setIsAddLabel(value: boolean): void;
  setIsAddTickeLabel(value: boolean): void;
  labels?: string[] | [];
  ticketLabels?: string[] | [];
  isMutating: boolean;
  createPage: number;
  setCreatePage(value: number): void;
  ticketBeingEdited: TicketEditData;
  setTicketBeingEdited(data: TicketEditData): void;
  setSelectedTemplateId(value?: number): void;
  currentUser: UserState;
  selectedTemplateId?: number;
  setSelectedLebels(value: string[]): void;
  setSelectedTicketsLebels(value: string[]): void;
  labelsId?: string[];
  setLabelsId(value: string[]): void;
  ticketLabelsId?: string[];
  setTicketLabelsId(value: string[]): void;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  templateName: string;
}

interface handleAddLabelProps {
  label: string;
  index: number;
}

const CreateChecklist = ({
  data,
  onSubmit,
  setIsAddLabel,
  labels,
  ticketLabels,
  isMutating,
  createPage,
  setCreatePage,
  ticketBeingEdited,
  setTicketBeingEdited,
  setSelectedTemplateId,
  currentUser,
  setIsAddTickeLabel,
  selectedTemplateId,
  setSelectedLebels,
  setSelectedTicketsLebels,
  labelsId,
  setLabelsId,
  ticketLabelsId,
  setTicketLabelsId,
}: CreateChecklistProps) => {
  const [locationId, setLocationId] = useState<number | undefined>(undefined);
  const [locations, setLocations] = useState<Location[] | undefined>(undefined);
  const [templates, setTemplates] = useState<Template[] | undefined>(undefined);
  const { register, handleSubmit } = useForm<Checklist>({
    defaultValues: data.item,
  });
  const submitHandler: SubmitHandler<Checklist & Ticket> = (item) => {
    if (!selectedTemplateId) {
      return showErrorToast('Please select template');
    }
    if (!item.CheckListName) {
      return showErrorToast('Please add new checklist name');
    }
    onSubmit({
      item: { ...item, LocationId: locationId },
      mode: data.mode,
    });
  };

  const locationsList = useMemo(() => {
    return (
      locations?.map((location, index) => ({
        value: String(location.LocationId),
        label: location.Name,
        index,
      })) || [{ value: '', label: '' }]
    );
  }, [locations]);

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ templateName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <TemplateIcon size={20} />
          {templateName}
        </Group>
      </div>
    )
  );
  SelectItem.displayName = 'SelectItem';

  const templateOptions =
    templates?.map((tempalte, index) => ({
      templateName: tempalte.TemplateName,
      label: tempalte.TemplateName || '',
      value: String(tempalte.CheckListTemplateId) || '',
      index,
    })) ?? [];

  const handleDeleteChecklistLabel = ({
    label,
    index,
  }: handleAddLabelProps) => {
    if (labels?.length) {
      setSelectedLebels(labels.filter((tag) => tag !== label));
    }
    setLabelsId(labelsId?.filter((_, i) => i !== index) || []);
  };

  const handleDeleteTicketLabel = ({ label, index }: handleAddLabelProps) => {
    if (ticketLabels?.length) {
      setSelectedTicketsLebels(ticketLabels.filter((tag) => tag !== label));
    }
    setTicketLabelsId(ticketLabelsId?.filter((_, i) => i !== index) || []);
  };

  return (
    <div className={'w-80 min-h-[560px] rounded-xl'}>
      {createPage === 1 ? (
        <div className={'max-w-80 bg-greyLight rounded-xl'}>
          <div
            className={
              'flex justify-between border-b py-3.5 px-4 font-semibold opacity-80'
            }
          >
            <Text>Create checklist</Text>
            <Text>1/2</Text>
          </div>
          <div
            className={
              'flex flex-col justify-between h-fit items-center p-4 rounded-b-xl'
            }
          >
            <Text className={'w-full mb-2.5 opacity-80'}>Template</Text>
            <CustomSelect
              setData={setTemplates}
              request={fetchChecklistsTemplates}
              options={templateOptions}
              placeholder={'Select template'}
              classNames={'w-full'}
              onChange={(value) => setSelectedTemplateId(Number(value))}
              defaultValue={String(data.item?.LocationId)}
              dataList={templates}
              rightSection={
                <ChevronDown
                  className={'text-brand'}
                  size={30}
                  strokeWidth={1.5}
                />
              }
              cssStyles={inputCss}
              itemComponent={SelectItem}
            />
            <Text className={'w-full mt-6 mb-2.5 opacity-80'}>
              Checklist details
            </Text>
            <Input
              css={inputCss}
              className={'w-full mb-2.5'}
              placeholder="Checklist name"
              required
              {...register('CheckListName', { required: true })}
            />
            <CustomSelect
              setData={setLocations}
              request={fetchLocations}
              options={locationsList}
              placeholder={'Location'}
              classNames={'w-full mb-2.5'}
              onChange={setLocationId}
              defaultValue={String(data.item?.LocationId)}
              dataList={locations}
              rightSection={
                <ChevronDown
                  className={'text-brand'}
                  size={30}
                  strokeWidth={1.5}
                />
              }
              cssStyles={inputCss}
            />
            <div className={'flex items-center w-full mt-6'}>
              <Text className={'mr-3 opacity-80'}>Labels</Text>
              <div
                className={buttonIconClasses}
                onClick={() => setIsAddLabel(true)}
              >
                <Plus
                  size={18}
                  className={'bg-brand rounded-[50%] text-white'}
                />
              </div>
            </div>
            <div
              className={
                'flex flex-wrap w-full mt-3 mb-7 h-28 overflow-y-auto overflow-x-hidden'
              }
            >
              {labels?.map((label, index) => (
                <Badge
                  className={`${tagClasses} h-7 mr-1.5 mb-2.5 rounded`}
                  key={index}
                  leftSection={
                    <CircleMinus
                      className={'text-brand cursor-pointer'}
                      size={20}
                      onClick={() =>
                        handleDeleteChecklistLabel({ label, index })
                      }
                    />
                  }
                >
                  {label}
                </Badge>
              ))}
            </div>
            <Button
              className={'bg-brand w-full text-lg h-12 rounded-3xl mb-3'}
              disabled={isMutating}
              onClick={() => setCreatePage(2)}
            >
              Continue
            </Button>
          </div>
        </div>
      ) : (
        ''
      )}
      {createPage === 2 ? (
        <div className={'bg-white rounded-xl'}>
          <div
            className={
              'flex justify-between border-b py-3.5 px-4 font-semibold opacity-80'
            }
          >
            <Text>Add ticket</Text>
            <Text>2/2</Text>
          </div>

          <form
            className={
              'flex flex-col justify-between h-fit items-center p-4 rounded-b-xl'
            }
            onSubmit={handleSubmit(submitHandler)}
          >
            <Input
              css={inputCss}
              className={'w-full mb-3.5'}
              placeholder="Ticket name"
              onChange={(e: any) =>
                setTicketBeingEdited({
                  item: {
                    ...ticketBeingEdited.item,
                    Title: String(e.target.value),
                  },
                  mode: FormTicketMode.AddTicket,
                })
              }
              required
            />
            {currentUser.isAdmin ? (
              <>
                <DatePicker
                  css={inputCss}
                  className={'w-full mb-3.5'}
                  placeholder="Due date"
                  rightSection={<Calendar size={30} />}
                  onChange={(date) =>
                    setTicketBeingEdited({
                      item: {
                        ...ticketBeingEdited.item,
                        Date: String(date),
                      },
                      mode: FormTicketMode.AddTicket,
                    })
                  }
                />
                <TimeInput
                  css={inputTimeCss}
                  className={'w-full mb-3.5'}
                  rightSection={<Clock size={30} className={'text-brand'} />}
                  onChange={(value) =>
                    setTicketBeingEdited({
                      item: {
                        ...ticketBeingEdited.item,
                        Time: String(value),
                      },
                      mode: FormTicketMode.AddTicket,
                    })
                  }
                  format={'12'}
                />
              </>
            ) : (
              ''
            )}
            <CustomSelect
              setData={setLocations}
              request={fetchLocations}
              options={locationsList}
              placeholder={'Location'}
              classNames={'w-full mb-2.5'}
              onChange={setLocationId}
              defaultValue={String(data.item?.LocationId)}
              dataList={locations}
              rightSection={
                <ChevronDown
                  className={'text-brand'}
                  size={30}
                  strokeWidth={1.5}
                />
              }
              cssStyles={inputCss}
            />
            <Textarea
              css={textAreaCss}
              className={'w-full'}
              placeholder="Ticket description"
              onChange={(e) =>
                setTicketBeingEdited({
                  item: {
                    ...ticketBeingEdited.item,
                    Description: e.target.value,
                  },
                  mode: FormTicketMode.AddTicket,
                })
              }
            />
            <div className={'flex items-center w-full mt-6'}>
              <Text className={'text-base mr-3'}>Labels</Text>
              <div
                className={buttonIconClasses}
                onClick={() => {
                  setIsAddLabel(true);
                  setIsAddTickeLabel(true);
                }}
              >
                <Plus
                  size={18}
                  className={'bg-brand rounded-[50%] text-white'}
                />
              </div>
            </div>
            <div className={'flex flex-wrap w-full  mt-3 mb-7 h-28'}>
              {ticketLabels?.map((label, index) => (
                <Badge
                  className={`${tagClasses} h-7 mr-1.5 mb-2.5 rounded`}
                  key={index}
                  leftSection={
                    <CircleMinus
                      className={'text-brand cursor-pointer'}
                      size={20}
                      onClick={() => handleDeleteTicketLabel({ label, index })}
                    />
                  }
                >
                  {label}
                </Badge>
              ))}
            </div>
            <Button
              className={'bg-brand w-full text-lg h-12 rounded-3xl mb-3'}
              type="submit"
              disabled={isMutating}
            >
              Finish
            </Button>
            <Button
              className={
                'bg-transparent w-full text-lg text-brand h-12 rounded-3xl mb-2'
              }
              disabled={isMutating}
              onClick={() => {
                setCreatePage(1);
              }}
              leftIcon={<ChevronLeft className="mt-0.5" size={30} />}
            >
              Step back
            </Button>
          </form>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CreateChecklist;
