import React, { ReactNode } from 'react';

interface ListPageHeaderProps {
  pageName: string;
  children: ReactNode;
}

function PageHeader({ pageName, children }: ListPageHeaderProps) {
  return (
    <header className="bg-white p-4 grid gap-4 grid-cols-12 grid-rows-1 rounded-sm sticky top-4 z-10 flex items-center drop-shadow">
      <h1 className="col-span-3 min-w-sm text-2xl font-bold">{pageName}</h1>
      <div className="col-span-9 px-4 flex justify-end">{children}</div>
    </header>
  );
}

export default PageHeader;
