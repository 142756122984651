import { useMemo, useState } from 'react';
import {
  AdjustmentsHorizontal,
  Calendar,
  CircleMinus,
  Refresh,
} from 'tabler-icons-react';
import { useQuery } from 'react-query';
import { Badge, Select } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { filterSelectCss, filterTitelCss } from 'Shared/helpers/styles';
import { Tag } from 'Admin/pages/Tickets';
import { Location } from 'types/Locations';
import { Template } from 'types/Template';
import { User } from 'types/Users';
import CustomSelect from './CustomSelect';
import { fetchLocations } from 'Shared/data/Locations';
import { fetchAllUsers } from 'Shared/data/Users';
import { fetchTags } from 'Shared/data/Tags';
import { fetchChecklistsTemplates } from 'Shared/data/Templates';

export interface FilterParams {
  locationIds: number[] | [];
  labelsNames: string[] | [];
  templatesIds?: number[] | [];
  statuses?: string[] | [];
  createdate?: any;
  duedate?: any;
  reporterIds?: string[] | [];
  assigneeIds?: string[] | [];
  percentagePassed?: string;
}

interface FilterProps {
  title: string;
  statusesOptions: string[];
  filterParams: FilterParams;
  isChecklistFilter?: boolean;
  isTicketFilter?: boolean;
  searchParams: any;
  setSearchParams(value: any): void;
}

const selectClasses = 'bg-greyLight w-full mb-3 rounded-md';

const optionClasses =
  'w-full justify-start bg-transparent text-xs text-black font-medium normal-case mb-1.5';

const Filter = ({
  title,
  statusesOptions,
  filterParams,
  isChecklistFilter,
  isTicketFilter,
  searchParams,
  setSearchParams,
}: FilterProps) => {
  const [selectedLocations, setSelectedLocations] = useState<Location[] | []>(
    []
  );
  const [selectedTemplates, setSelectedTemplates] = useState<Template[] | []>(
    []
  );
  const [selectedLabels, setSelectedLabels] = useState<Tag[] | []>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<any[] | []>([]);
  const [selectedReorter, setSelectedReporter] = useState<User[] | []>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<User[] | []>([]);
  const [locations, setLocations] = useState<Location[] | undefined>(undefined);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [labels, setLabels] = useState<Tag[] | undefined>(undefined);
  const [templates, setTemplates] = useState<Template[] | undefined>(undefined);

  useQuery<{ value: User[]; ['@odata.count']: number }, Error>(
    ['users'],
    async () =>
      filterParams.reporterIds?.length || filterParams.assigneeIds?.length
        ? await fetchAllUsers({
            filterParams,
          })
        : [],
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if (response && response.value && response.value.length) {
          setUsers(response.value);
        }
      },
    }
  );
  useQuery<{ value: Location[]; ['@odata.count']: number }, Error>(
    ['locations'],
    async () =>
      filterParams.locationIds?.length
        ? await fetchLocations({
            filterParams,
          })
        : [],
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if (response && response.value && response.value.length) {
          setLocations(response.value);
        }
      },
    }
  );
  useQuery<{ value: Tag[]; ['@odata.count']: number }, Error>(
    ['tags'],
    async () =>
      filterParams.labelsNames?.length
        ? await fetchTags({
            filterParams,
          })
        : [],
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if (response && response.value && response.value.length) {
          setLabels(response.value);
        }
      },
    }
  );
  useQuery<{ value: Template[]; ['@odata.count']: number }, Error>(
    ['templates'],
    async () =>
      filterParams.templatesIds?.length
        ? await fetchChecklistsTemplates({
            filterParams,
          })
        : [],
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (response) => {
        if (response && response.value && response.value.length) {
          setTemplates(response.value);
        }
      },
    }
  );

  const locationOptions = useMemo(() => {
    if (locations) {
      const filtredLocations = locations.filter((location) => {
        const findId = filterParams.locationIds.find(
          (id) => id === location.LocationId
        );
        if (findId) return location;
      });
      setSelectedLocations(filtredLocations);
    }
    return (
      locations
        ?.filter((location) => {
          const findId = filterParams.locationIds.find(
            (id) => id === location.LocationId
          );
          if (!findId) return location;
        })
        .map((location, index) => ({
          value: String(location.LocationId),
          label: String(location.Name),
          index,
        })) ?? []
    );
  }, [locations, filterParams.locationIds]);

  const templatesOptions = useMemo(() => {
    if (templates) {
      const filtredTempalates = templates.filter((tempalate) => {
        const findId = filterParams.templatesIds?.find(
          (id) => id === tempalate.CheckListTemplateId
        );
        if (findId) return tempalate;
      });
      setSelectedTemplates(filtredTempalates);
    }
    return (
      templates
        ?.filter((template) => {
          const findId = filterParams.templatesIds?.find(
            (id) => id === template.CheckListTemplateId
          );
          if (!findId) return template;
        })
        .map((template, index) => ({
          value: String(template.CheckListTemplateId),
          label: template.TemplateName,
          index,
        })) ?? []
    );
  }, [templates, filterParams.templatesIds]);

  const labelsOptions = useMemo(() => {
    if (labels) {
      const filtredLabels = labels.filter((label) => {
        const findName = filterParams.labelsNames?.find(
          (name) => name === label.TagName
        );
        if (findName) return label;
      });
      setSelectedLabels(filtredLabels);
    }
    return (
      labels
        ?.filter((label) => {
          const findLabel = filterParams.labelsNames.find(
            (name) => name === label.TagName
          );
          if (!findLabel) return label;
        })
        .map((label, index) => ({
          value: label.TagName,
          label: label.TagName,
          index,
        })) ?? []
    );
  }, [labels, filterParams.labelsNames]);

  const statusOptions = useMemo(() => {
    const filtredStatus = statusesOptions.filter((status) => {
      const findStatus = filterParams.statuses?.find((name) => name === status);
      if (findStatus) return status;
    });
    setSelectedStatuses(filtredStatus);
    return (
      statusesOptions
        ?.filter((status) => {
          const findStatus = filterParams.statuses?.find(
            (name) => name === status
          );
          if (!findStatus) return status;
        })
        .map((status) => ({
          value: status,
          label: status,
        })) ?? []
    );
  }, [statusesOptions, filterParams.statuses]);

  const reporterOptions = useMemo(() => {
    if (users) {
      const filtredUsers = users.filter((user) => {
        const findUser = filterParams.reporterIds?.find((id) => id === user.Id);
        if (findUser) return user;
      });
      setSelectedReporter(filtredUsers);
    }
    return (
      users
        ?.filter((user) => {
          const findUser = filterParams.reporterIds?.find(
            (id) => id === user.Id
          );
          if (!findUser) return user;
        })
        .map((user, index) => ({
          value: user.Id || '',
          label: user.FirstName + ' ' + user.Surname,
          index,
        })) ?? []
    );
  }, [users, filterParams.reporterIds]);

  const assigneeOptions = useMemo(() => {
    if (users) {
      const filtredUsers = users.filter((user) => {
        const findUser = filterParams.assigneeIds?.find((id) => id === user.Id);
        if (findUser) return user;
      });
      setSelectedAssignee(filtredUsers);
    }
    return (
      users
        ?.filter((user) => {
          const findUser = filterParams.assigneeIds?.find(
            (id) => id === user.Id
          );
          if (!findUser) return user;
        })
        .map((user, index) => ({
          value: user.Id || '',
          label: user.FirstName + ' ' + user.Surname,
          index,
        })) ?? []
    );
  }, [users, filterParams.assigneeIds]);

  const handleDeleteLocation = (locationId?: number) => {
    setSelectedLocations(
      selectedLocations.filter((location) => location.LocationId !== locationId)
    );
    const filtredLocations = filterParams.locationIds.filter(
      (id) => id !== locationId
    );
    if (filtredLocations?.length) {
      searchParams.set('locationIds', filtredLocations);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('locationIds');
      setSearchParams(searchParams);
    }
  };

  const handleDeleteTemplates = (templateId?: number) => {
    setSelectedTemplates(
      selectedTemplates.filter(
        (template) => template.CheckListTemplateId !== templateId
      )
    );
    const filtredTemlpates = filterParams.templatesIds?.filter(
      (id) => id !== templateId
    );
    if (filtredTemlpates?.length) {
      searchParams.set('templatesIds', filtredTemlpates);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('templatesIds');
      setSearchParams(searchParams);
    }
  };

  const handleDeleteLabels = (labelName?: string) => {
    setSelectedLabels(
      selectedLabels.filter((location) => location.TagName !== labelName)
    );
    const filtredLabels = filterParams.labelsNames.filter(
      (name) => name !== labelName
    );
    if (filtredLabels?.length) {
      searchParams.set('labelsNames', filtredLabels);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('labelsNames');
      setSearchParams(searchParams);
    }
  };
  const handleDeleteStatus = (statusName?: string) => {
    setSelectedStatuses(
      selectedStatuses.filter((status) => status !== statusName)
    );
    const filtredStatuses = filterParams.statuses?.filter(
      (name) => name !== statusName
    );
    if (filtredStatuses?.length) {
      searchParams.set('statuses', filtredStatuses);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('statuses');
      setSearchParams(searchParams);
    }
  };
  const handleDeleteReporter = (id?: string) => {
    setSelectedReporter(selectedReorter.filter((user) => user.Id !== id));
    const filtredReporters = filterParams.reporterIds?.filter(
      (item) => item !== id
    );
    if (filtredReporters?.length) {
      searchParams.set('reporterIds', filtredReporters);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('reporterIds');
      setSearchParams(searchParams);
    }
  };
  const handleDeleteAssignee = (id?: string) => {
    setSelectedAssignee(selectedAssignee.filter((user) => user.Id !== id));
    const filtredAssigne = filterParams.assigneeIds?.filter(
      (item) => item !== id
    );
    if (filtredAssigne?.length) {
      searchParams.set('assigneeIds', filtredAssigne);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('assigneeIds');
      setSearchParams(searchParams);
    }
  };

  const onReset = () => {
    searchParams.delete('locationIds');
    searchParams.delete('labelsNames');
    searchParams.delete('templatesIds');
    searchParams.delete('statuses');
    searchParams.delete('createDate');
    searchParams.delete('dueDate');
    searchParams.delete('reporterIds');
    searchParams.delete('assigneeIds');
    setSearchParams(searchParams);
  };

  const onChange = (item: string, value: string) => {
    if (searchParams.get(item)) {
      searchParams.set(item, [searchParams.get(item), value]);
      setSearchParams(searchParams);
    } else {
      searchParams.set(item, value);
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="mr-7 max-w-xs min-w-[225px] drop-shadow-sm rounded-sm relative">
      <Badge
        css={filterTitelCss}
        className={
          'w-full justify-start bg-greyLight text-sm text-black font-medium normal-case h-10 mb-3'
        }
        radius={'md'}
        leftSection={<AdjustmentsHorizontal size={18} />}
        rightSection={
          <Refresh
            className={'cursor-pointer'}
            size={18}
            onClick={() => {
              onReset();
            }}
          />
        }
      >
        {title}
      </Badge>
      <div className={'flex flex-col'}>
        <div className={selectClasses}>
          {!isTicketFilter ? (
            <div className={'px-5 pt-2 pb-3 border-b'}>
              <CustomSelect
                setData={setTemplates}
                request={fetchChecklistsTemplates}
                options={templatesOptions}
                label={'Templates'}
                placeholder={'Select templates'}
                onChange={(value) => {
                  onChange('templatesIds', String(value));
                }}
                dataList={templates}
                cssStyles={filterSelectCss}
                radius={'md'}
              />
              {selectedTemplates.length ? (
                <div className={'w-full py-3'}>
                  {selectedTemplates.map((template) => (
                    <Badge
                      key={template.CheckListTemplateId}
                      className={optionClasses}
                      leftSection={
                        <CircleMinus
                          size={18}
                          className={'text-brand cursor-pointer'}
                          onClick={() =>
                            handleDeleteTemplates(template.CheckListTemplateId)
                          }
                        />
                      }
                    >
                      {template.TemplateName}
                    </Badge>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          <div className={'px-5 pt-2 pb-3 border-b'}>
            <CustomSelect
              setData={setLocations}
              request={fetchLocations}
              options={locationOptions}
              label={'Locations'}
              placeholder={'Select locations'}
              onChange={(value) => {
                onChange('locationIds', String(value));
              }}
              dataList={locations}
              cssStyles={filterSelectCss}
              radius={'md'}
            />
            {selectedLocations.length ? (
              <div className={'w-full py-2'}>
                {selectedLocations.map((location) => (
                  <Badge
                    key={location.LocationId}
                    className={optionClasses}
                    leftSection={
                      <CircleMinus
                        size={18}
                        className={'text-brand cursor-pointer'}
                        onClick={() =>
                          handleDeleteLocation(location.LocationId)
                        }
                      />
                    }
                  >
                    {location.Name}
                  </Badge>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={'px-5 pt-2 pb-3 border-b'}>
            <CustomSelect
              setData={setLabels}
              request={fetchTags}
              options={labelsOptions}
              label={'Labels'}
              placeholder={'Select labels'}
              onChange={(value) => {
                onChange('labelsNames', String(value));
              }}
              dataList={labels}
              cssStyles={filterSelectCss}
              radius={'md'}
            />
            {selectedLabels.length ? (
              <div className={'w-full py-2'}>
                {selectedLabels.map((label) => (
                  <Badge
                    key={label.Id}
                    className={optionClasses}
                    leftSection={
                      <CircleMinus
                        size={18}
                        className={'text-brand cursor-pointer'}
                        onClick={() => handleDeleteLabels(label.TagName)}
                      />
                    }
                  >
                    {label.TagName}
                  </Badge>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={'px-5 pt-2 pb-3'}>
            <Select
              css={filterSelectCss}
              label={`${isChecklistFilter ? 'Checklist' : 'Ticket'} status`}
              placeholder={'Select status'}
              radius={'md'}
              data={statusOptions}
              value={''}
              onChange={(value) => {
                onChange('statuses', String(value));
              }}
            />
            {selectedStatuses.length ? (
              <div className={'w-full py-2'}>
                {selectedStatuses.map((status) => (
                  <Badge
                    key={status}
                    className={optionClasses}
                    leftSection={
                      <CircleMinus
                        size={18}
                        className={'text-brand cursor-pointer'}
                        onClick={() => handleDeleteStatus(status)}
                      />
                    }
                  >
                    {status}
                  </Badge>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className={'flex flex-col'}>
        <div className={selectClasses}>
          <div className={'px-5 pt-2 pb-3 border-b'}>
            <DateRangePicker
              css={filterSelectCss}
              label={'Create date range'}
              placeholder={'Select date'}
              radius={'md'}
              inputFormat="MM.DD.YYYY"
              rightSection={<Calendar size={20} className={'text-brand'} />}
              defaultValue={filterParams.createdate}
              onChange={(value) => {
                if (
                  value?.filter((date: Date | null) => date === null).length ==
                  2
                ) {
                  searchParams.delete('createDate');
                  setSearchParams(searchParams);
                } else {
                  if (
                    value?.filter((date: Date | null) => date !== null)
                      .length !== 1
                  ) {
                    onChange('createDate', String(value));
                  }
                }
              }}
            />
          </div>
          <div className={'px-5 pt-2 pb-3'}>
            <DateRangePicker
              css={filterSelectCss}
              label={'Due date range'}
              placeholder={'Select date'}
              radius={'md'}
              inputFormat="MM.DD.YYYY"
              rightSection={<Calendar size={20} className={'text-brand'} />}
              defaultValue={filterParams.duedate}
              onChange={(value) => {
                if (
                  value?.filter((date: Date | null) => date === null).length ==
                  2
                ) {
                  searchParams.delete('dueDate');
                  setSearchParams(searchParams);
                } else {
                  if (
                    value?.filter((date: Date | null) => date !== null)
                      .length !== 1
                  ) {
                    onChange('dueDate', String(value));
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={'flex flex-col'}>
        <div className={selectClasses}>
          <div className={'px-5 pt-2 pb-3 border-b'}>
            <CustomSelect
              setData={setUsers}
              request={fetchAllUsers}
              options={reporterOptions}
              label={'Reporter'}
              placeholder={'Select reporter'}
              onChange={(value) => {
                onChange('reporterIds', String(value));
              }}
              dataList={users}
              cssStyles={filterSelectCss}
              radius={'md'}
            />
            {selectedReorter.length ? (
              <div className={'w-full py-2'}>
                {selectedReorter.map((user) => (
                  <Badge
                    key={user.Id}
                    className={optionClasses}
                    leftSection={
                      <CircleMinus
                        size={18}
                        className={'text-brand cursor-pointer'}
                        onClick={() => handleDeleteReporter(user.Id)}
                      />
                    }
                  >
                    {user.FirstName + ' ' + user.Surname}
                  </Badge>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
          {!isChecklistFilter ? (
            <div className={'px-5 pt-2 pb-3'}>
              <CustomSelect
                setData={setUsers}
                request={fetchAllUsers}
                options={assigneeOptions}
                label={'Assignee'}
                placeholder={'Select assignee'}
                onChange={(value) => {
                  onChange('assigneeIds', String(value));
                }}
                dataList={users}
                cssStyles={filterSelectCss}
                radius={'md'}
              />
              {selectedAssignee.length ? (
                <div className={'w-full py-2'}>
                  {selectedAssignee.map((user) => (
                    <Badge
                      key={user.Id}
                      className={optionClasses}
                      leftSection={
                        <CircleMinus
                          size={18}
                          className={'text-brand cursor-pointer'}
                          onClick={() => handleDeleteAssignee(user.Id)}
                        />
                      }
                    >
                      {user.FirstName + ' ' + user.Surname}
                    </Badge>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
