import { Button, Group, Modal, Text, TextInput, Badge } from '@mantine/core';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Plus, Trash } from 'tabler-icons-react';
import {
  LocationEditData,
  FormLocationMode,
  Location,
  FormFloorMode,
  FormAreaMode,
} from 'types/Locations';
import { getImage } from 'Shared/helpers/request';
import PlacesAutocomplete from './PlacesAutocomplete';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { confirmModalCss, customScrollXCss } from 'Shared/helpers/styles';

interface CreateEditModal {
  title: string;
  data: LocationEditData;
  opened: string;
  onClose(): void;
  onSubmit(data: LocationEditData): void;
  isMutating: boolean;
  setFiles(files: any): void;
  deletedImages: string[] | [];
  setDeletedImages(files: string[] | []): void;
  isLoaded: boolean;
  mapOptions: any;
  center: { lat: number; lng: number };
  onLoad(value: any): void;
  onUnmount(value: any): void;
}

const containerStyle = {
  width: '290px',
  height: '390px',
};

const CreateEditModal = ({
  title,
  data,
  opened,
  onClose,
  onSubmit,
  isMutating,
  setFiles,
  deletedImages,
  setDeletedImages,
  isLoaded,
  mapOptions,
  center,
  onLoad,
  onUnmount,
}: CreateEditModal) => {
  const [images, setImages] = useState<string[] | []>([]);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [location, setLocation] = useState<
    { lat: number; lng: number } | undefined
  >(undefined);
  const { register, handleSubmit } = useForm<Location>({
    defaultValues:
      data.mode === FormLocationMode.AddLocation ||
      data.mode === FormFloorMode.AddFloor ||
      data.mode === FormAreaMode.AddArea
        ? undefined
        : data.item,
  });
  const cantBeAddEdit = opened === 'locations' && location === undefined;

  const submitHandler: SubmitHandler<Location> = (item) => {
    if (opened === 'locations') {
      onSubmit({
        item: {
          ...item,
          Address1: address,
          Latitude: String(location?.lat),
          Longtitude: String(location?.lng),
        },
        mode: data.mode,
      });
    } else {
      onSubmit({ item: { ...item, Images: images }, mode: data.mode });
    }
  };

  const uploadFiles = async (event: any) => {
    if (event.target.files[0].type.match(/image.*/)) {
      setImages([...images, URL.createObjectURL(event.target.files[0])]);
      setFiles((prevState: string[] | []) => [
        ...prevState,
        event.target.files[0],
      ]);
    }
  };

  const getButtonTitle = () => {
    if (opened === 'locations' && data.mode === FormLocationMode.AddLocation) {
      return 'Add location';
    }
    if (opened === 'locations' && data.mode === FormLocationMode.EditLocation) {
      return 'Edit location';
    }
    if (opened === 'floors' && data.mode === FormFloorMode.AddFloor) {
      return 'Create floor';
    }
    if (opened === 'floors' && data.mode === FormFloorMode.EditFloor) {
      return 'Edit floor';
    }
    if (opened === 'areas' && data.mode === FormAreaMode.AddArea) {
      return 'Create area';
    }
    if (opened === 'areas' && data.mode === FormAreaMode.EditArea) {
      return 'Edit area';
    }
  };

  const getInputPlaceholder = () => {
    switch (opened) {
      case 'locations':
        return 'Location name here';
      case 'floors':
        return 'Floor name here';
      case 'areas':
        return 'Area name here';
      default:
        '';
    }
  };

  const setImage = async (img: string) => {
    const response = await getImage(img);
    response && setImages((prevState) => [...prevState, response]);
  };

  useMemo(async () => {
    if (opened === 'locations' && data?.item?.Attachments?.length) {
      await Promise.all<any>(
        data.item.Attachments.map((img) => {
          setImage(img);
        })
      );
    }
    if (
      (opened === 'floors' ||
        (opened === 'areas' && data.mode === FormAreaMode.EditArea)) &&
      data?.item?.Images?.length
    ) {
      await Promise.all<any>(
        data.item.Images.map((img) => {
          setImage(img);
        })
      );
    }
  }, [opened]);

  const handleDeleteImage = (image: string) => {
    setImages([...images.filter((img: string) => img !== image)]);
    const findimage = data.item?.[
      opened === 'locations' ? 'Attachments' : 'Images'
    ]?.find((img) => image.includes(img));
    findimage && setDeletedImages([...deletedImages, findimage]);
  };

  return (
    <Modal
      centered
      opened={!!opened}
      onClose={() => {
        setImages([]);
        onClose();
      }}
      css={confirmModalCss}
    >
      <form className={'px-8'} onSubmit={handleSubmit(submitHandler)}>
        <div className={'flex'}>
          <div className={'w-full'}>
            <Text className={'text-[40px] font-semibold mb-6 leading-10'}>
              {title}
            </Text>
            <TextInput
              className={'h-16 mb-7 min-w-[420px]'}
              placeholder={getInputPlaceholder()}
              size={'xl'}
              radius={'md'}
              required
              {...register('Name', { required: true })}
            />
            {isLoaded && opened === 'locations' ? (
              <PlacesAutocomplete
                isLoaded={isLoaded}
                locationData={data}
                setAddress={setAddress}
                setLocation={setLocation}
              />
            ) : (
              ''
            )}
            <label htmlFor="inputTag">
              <Badge
                className={
                  'bg-stone text-grey ml-4 ml-0 h-14 w-52 mb-6 rounded-xl normal-case cursor-pointer'
                }
                leftSection={<Plus size={25} />}
                size="xl"
              >
                Upload photos
              </Badge>
              <input
                className={'hidden'}
                id="inputTag"
                type="file"
                accept="image/*"
                onChange={uploadFiles}
              />
            </label>
            <div
              className={
                'flex bg-white w-full z-10 top-10 overflow-x-auto overflow-y-hidden h-44 max-w-[600px]'
              }
              css={customScrollXCss}
            >
              {images.map((img: string) => (
                <div
                  key={img}
                  className={'relative rounded-xl h-44 w-44 m-2.5'}
                >
                  <img className={'h-44 w-44 max-w-none p-1'} src={img} />
                  <div
                    className={
                      'bg-white rounded-[50%] absolute z-10 right-2 top-2.5 w-8 h-8 cursor-pointer'
                    }
                    onClick={() => handleDeleteImage(img)}
                  >
                    <Trash className={'m-1 z-10 text-brand'} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {isLoaded && opened === 'locations' ? (
            <div className={'bg-white p-4 mt-14 ml-8 shadow-xs'}>
              <Text className={'text-base font-medium mb-4'}>
                Selected location on the map
              </Text>
              <GoogleMap
                options={mapOptions}
                mapContainerStyle={containerStyle}
                center={location ? location : center}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {location ? <MarkerF position={location} /> : ''}
              </GoogleMap>
            </div>
          ) : (
            ''
          )}
        </div>
        <Group className={'mt-8 flex justify-start mb-11'} position="right">
          <Button
            className={'bg-brand rounded-lg'}
            type="submit"
            leftIcon={<Plus size={20} />}
            size={'lg'}
            disabled={isMutating || cantBeAddEdit}
          >
            {getButtonTitle()}
          </Button>

          <Button
            className={'w-[182px] rounded-lg'}
            variant={'outline'}
            size={'lg'}
            disabled={isMutating}
            onClick={() => onClose()}
          >
            Go back
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default CreateEditModal;
