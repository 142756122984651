import { useCallback, useMemo, useState } from 'react';
import DashboardSection from 'Admin/components/Dashborad/DashboardSection';
import { useQuery } from 'react-query';
import { ChartPie2, Checks, Ticket as TicketIcon } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { fetchChecklists } from 'Shared/data/Checklists';
import { fetchTickets } from 'Shared/data/Tickets';
import { Checklist } from 'types/Checklist';
import { Ticket } from 'types/Tickets';
import DashboardReminderSection from 'Admin/components/Dashborad/DashboardReminderSection';
import DashboardChecklistOverview from 'Admin/components/Dashborad/DashboardChecklistOverview';

export interface dataItem {
  count: number;
  label: string;
  value: number;
  color: string;
}

const ticketLogo = () => {
  return (
    <TicketIcon size={25} strokeWidth={1.5} className={'rotate-[270deg]'} />
  );
};

const checklistLogo = (value: string) => {
  if (value === 'Complete') {
    return <Checks size={25} strokeWidth={1.5} />;
  }
  if (value === 'In progress') {
    return <ChartPie2 size={25} strokeWidth={1.5} />;
  }
};
interface TicketCount {
  Status: string;
  COUNT: number;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [ticketData, setTicketData] = useState<dataItem[] | []>([]);
  const [ticketsCount, setTicketsCount] = useState<number | undefined>(
    undefined
  );
  const [inProgressChecklists, setInProgressChecklists] = useState<
    dataItem[] | []
  >([]);
  const [completedChecklists, setCompletedChecklists] = useState<
    dataItem[] | []
  >([]);
  const [checklists, setChecklists] = useState<Checklist[] | undefined>(
    undefined
  );

  const { isFetching: isLoadingTickets } = useQuery<
    { value: TicketCount[]; ['@odata.count']: number },
    Error
  >(['tickets'], () => fetchTickets({ isDashboard: true }), {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data) {
        setTickets(data);
      }
    },
  });
  const { isFetching: isLoadingChecklists } = useQuery<
    { value: Checklist[]; ['@odata.count']: number },
    Error
  >(['checklist'], () => fetchChecklists({}), {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data) {
        setChecklists(data.value);
      }
    },
  });

  const setTickets = useCallback(
    ({ value: tickets }: { value: TicketCount[] }) => {
      if (tickets) {
        const completedTickes =
          tickets?.find((ticket) => ticket.Status === 'Complete')?.COUNT || 0;
        const cancelledTickes =
          tickets?.find((ticket) => ticket.Status === 'Cancelled')?.COUNT || 0;
        const openTickes =
          tickets?.find((ticket) => ticket.Status === 'Open')?.COUNT || 0;
        const draftTickes =
          tickets?.find((ticket) => ticket.Status === 'InProgress')?.COUNT || 0;
        const count =
          completedTickes + cancelledTickes + openTickes + draftTickes;

        setTicketsCount(count);
        setTicketData([
          {
            count: completedTickes,
            label: 'Complete',
            value: Math.round((100 * completedTickes) / count),
            color: '#6CDC77',
          },
          {
            count: cancelledTickes,
            label: 'Cancelled',
            value: Math.round((100 * cancelledTickes) / count),
            color: '#D66171',
          },
          {
            count: openTickes,
            label: 'Open',
            value: Math.round((100 * openTickes) / count),
            color: '#2EC2E2',
          },
          {
            count: draftTickes,
            label: 'In progress',
            value: Math.round((100 * draftTickes) / count),
            color: '#F6E5A8',
          },
        ]);
      }
    },
    [ticketData]
  );

  useMemo(() => {
    if (checklists) {
      const now = new Date();

      const overdueChecklists = checklists?.filter(
        (checklist) => checklist.DueDate && new Date(checklist.DueDate) < now
      ).length;

      const dueInChecklists = checklists?.filter((checklist) => {
        if (checklist.DueDate) {
          const then = new Date(checklist.DueDate);
          const msBetweenDates = then.getTime() - now.getTime();
          const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
          if (hoursBetweenDates > 0 && hoursBetweenDates < 24) return checklist;
        }
      }).length;
      const dueInfutureChecklists = checklists?.filter(
        (checklist) => checklist.DueDate && new Date(checklist.DueDate) > now
      ).length;
      const noDueDateChecklists = checklists?.filter(
        (checklist) => !checklist.DueDate
      ).length;

      setInProgressChecklists([
        {
          count: overdueChecklists,
          label: 'Overdue',
          value: Number(
            Math.round((100 * overdueChecklists) / checklists?.length)
          ),
          color: '#6CDC77',
        },
        {
          count: dueInChecklists,
          label: 'Due in 24hours',
          value: Number(
            Math.round((100 * dueInChecklists) / checklists?.length)
          ),
          color: '#D66171',
        },
        {
          count: dueInfutureChecklists,
          label: 'Due in the future',
          value: Number(
            Math.round((100 * dueInfutureChecklists) / checklists?.length)
          ),
          color: '#2EC2E2',
        },
        {
          count: noDueDateChecklists,
          label: 'No due date',
          value: Number(
            Math.round((100 * noDueDateChecklists) / checklists?.length)
          ),
          color: '#F6E5A8',
        },
      ]);

      const completedChecklists = checklists?.filter(
        (checklist) => checklist.PercentagePassed === 1
      ).length;
      const passedChecklists = checklists?.filter(
        (checklist) => (checklist.PercentagePassed || 0) >= 0.8
      ).length;
      const failedChecklists = checklists?.filter(
        (checklist) => (checklist.PercentagePassed || 0) < 0.8
      ).length;
      const disabledChecklists = checklists?.filter(
        (checklist) => checklist.Status === 'Disabled'
      ).length;

      setCompletedChecklists([
        {
          count: completedChecklists,
          label: 'Complete',
          value: Math.round((100 * completedChecklists) / checklists?.length),
          color: '#6CDC77',
        },
        {
          count: completedChecklists,
          label: 'Passed',
          value: Math.round(
            (100 * (passedChecklists - completedChecklists)) /
              checklists?.length
          ),
          color: '#D66171',
        },
        {
          count: failedChecklists,
          label: 'Failed',
          value: Math.round((100 * failedChecklists) / checklists?.length),
          color: '#2EC2E2',
        },
        {
          count: disabledChecklists,
          label: 'Disabled',
          value: Math.round((100 * disabledChecklists) / checklists?.length),
          color: '#F6E5A8',
        },
      ]);
    }
  }, [checklists]);

  const getQueryDueDate = (status: string) => {
    const now = new Date();
    switch (status) {
      case 'Overdue':
        return [
          new Date(new Date(0).toUTCString()),
          new Date(
            new Date(now.setSeconds(now.getSeconds() - 1)).toUTCString()
          ),
        ];
      case 'Due in 24hours':
        return [
          new Date(now.toUTCString()),
          new Date(new Date(now.setHours(now.getHours() + 24)).toUTCString()),
        ];
      case 'Due in the future': {
        const maxYear = 2040;
        return [
          new Date(now.toUTCString()),
          new Date(new Date(maxYear, 1).toUTCString()),
        ];
      }
      case 'No due date':
        return [null];
      default:
        return [];
    }
  };
  const getQueryStatus = (status: string) => {
    switch (status) {
      case 'Complete':
        return 'statuses=Submit';
      case 'Disabled':
        return 'statuses=Disable';
      case 'Passed':
        return 'completed=Passed';
      case 'Failed':
        return 'completed=Failed';
      default:
        return [];
    }
  };

  return (
    <div className="dashboard">
      <DashboardChecklistOverview />
      <div className="flex w-full">
        <div className="w-full">
          <DashboardSection
            data={ticketData}
            allDataLength={ticketsCount}
            title={'All tickets'}
            getLogo={ticketLogo}
            navigate={(status) => navigate(`/tickets?statuses=${status}`)}
            isLoading={isLoadingTickets}
          />
          <DashboardSection
            data={inProgressChecklists}
            allDataLength={checklists?.length}
            title={'In progress checklists'}
            getLogo={() => checklistLogo('In progress')}
            navigate={(status) =>
              navigate(`/checklists?dueDate=${getQueryDueDate(status)}`)
            }
            isLoading={isLoadingChecklists}
          />
        </div>
        <div className="w-full">
          <DashboardReminderSection />
          <DashboardSection
            data={completedChecklists}
            allDataLength={checklists?.length}
            title={'Completed checklists'}
            getLogo={() => checklistLogo('Complete')}
            navigate={(status) =>
              navigate(`/checklists?${getQueryStatus(status)}`)
            }
            isLoading={isLoadingChecklists}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
