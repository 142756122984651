import { css } from '@emotion/react';
import { Button, Table, Text, Badge, LoadingOverlay } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { customScrollYCss } from 'Shared/helpers/styles';
import { ArrowBackUp } from 'tabler-icons-react';
import { Checklist } from 'types/Checklist';
import { Location } from 'types/Locations';
import { FormTemplateMode, Template, TemplateEditData } from 'types/Template';

interface TemplateInfoProps {
  template?: Template;
  checkLists?: Checklist[];
  locations?: Location[];
  hasOverlay: boolean;
}

const thCss = css`
  padding: 5px 20px;
  border: none !important;
`;

const TemplateInfo = ({
  template,
  checkLists,
  locations,
  hasOverlay,
}: TemplateInfoProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={'relative bg-white h-[80vh] w-full px-7 py-4 overflow-auto'}
      css={customScrollYCss}
    >
      <LoadingOverlay visible={hasOverlay} className={'w-full h-full'} />
      <Text size={'lg'} weight={600}>
        {template?.TemplateName}
      </Text>
      <Table
        striped
        className={'w-fit'}
        verticalSpacing={'md'}
        horizontalSpacing={'lg'}
        highlightOnHover
      >
        <thead>
          <tr>
            <th className={'max-w-60'} css={thCss}>
              <Button
                className={'text-brand border-none w-32'}
                leftIcon={<ArrowBackUp size={24} />}
                size="lg"
                variant="outline"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </th>
            {locations?.map((location) => (
              <th key={location.LocationId} className={'w-52'} css={thCss}>
                <Badge
                  radius={'lg'}
                  className={
                    'bg-gray-100 text-black text-sm font-normal normal-case h-20 w-52 mb-1'
                  }
                >
                  {location.Name}
                </Badge>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {checkLists
            ?.filter(
              (checklist) =>
                checklist.TemplateId === template?.CheckListTemplateId
            )
            .map((checklist) => (
              <tr key={checklist.CheckListId}>
                <td css={thCss}>{checklist.CheckListName}</td>
                {locations?.map((location) => (
                  <td
                    key={`location-${location.LocationId}`}
                    className={'text-center'}
                    css={thCss}
                  >
                    {checklist.LocationId === location.LocationId ? (
                      <Badge className={'rounded-[50%] bg-green'} />
                    ) : (
                      <Badge className={'rounded-[50%] bg-brand'} />
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TemplateInfo;
