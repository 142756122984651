import { Alert, LoadingOverlay, Table, Text } from '@mantine/core';
import { AlertCircle, Edit, Lock, Trash } from 'tabler-icons-react';
import { MemberEditData, User } from 'types/Users';
import { UserState } from 'types/Store';
import { css } from '@emotion/react';
import { FormUIMode } from 'types/UI';
import { actionsCss, rowClasses, tdCss, thClass } from 'Shared/helpers/styles';

interface MemberListProps {
  hasOverlay: boolean;
  isError: boolean;
  error?: Error;
  users: User[];
  currentUser: UserState;
  onActionClicked(data: MemberEditData): void;
  lastElementRef: any;
}

const thCss = css`
  font-size: 1em !important;
  font-weight: bold !important;
  top: 100px;
`;

export default function MemberList({
  hasOverlay,
  isError,
  error,
  users,
  currentUser,
  onActionClicked,
  lastElementRef,
}: MemberListProps) {
  return (
    <div className="mt-12 col-span-9 flex flex-wrap gap-4 relative">
      {isError && (
        <Alert icon={<AlertCircle size={20} />} title="Oops!" color="red">
          We encountered an error! Please refresh or try again later. If the
          problem persists, please contact the administrator.
          <br />
          Error: {error?.message ?? 'Unknown error'}.
        </Alert>
      )}
      <LoadingOverlay visible={hasOverlay} className={'w-full'} />

      <Table
        className={'drop-shadow-sm rounded-sm'}
        verticalSpacing={'md'}
        horizontalSpacing={'lg'}
        highlightOnHover
      >
        <thead>
          <tr>
            <th className={thClass} css={thCss}>
              Firstname
            </th>
            <th className={thClass} css={thCss}>
              Surname
            </th>
            <th className={thClass} css={thCss}>
              Phone
            </th>
            <th className={thClass} css={thCss}>
              User Level
            </th>
            <th className={thClass} css={thCss}>
              Job Title
            </th>
            <th className={thClass} css={thCss}>
              Email
            </th>
            {currentUser.isAdmin ? (
              <th className={thClass} css={thCss}>
                Actions
              </th>
            ) : (
              ''
            )}
          </tr>
        </thead>
        <tbody>
          {users?.length ? (
            users.map((user, index) => (
              <tr
                key={'member' + user.Id}
                ref={users.length === index + 1 ? lastElementRef : undefined}
              >
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center`}>
                    {user.FirstName}
                  </div>
                </td>
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center`}>
                    {user.Surname}
                  </div>
                </td>
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center`}>
                    {user.PhoneNumber}
                  </div>
                </td>
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center`}>
                    {user.UserRole}
                  </div>
                </td>
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center`}>
                    {user.JobTitle}
                  </div>
                </td>
                <td css={tdCss}>
                  <div className={`${rowClasses} items-center`}>
                    {user.Email}
                  </div>
                </td>

                {currentUser.isAdmin ? (
                  <td css={tdCss}>
                    <div
                      className={`${rowClasses} items-center`}
                      css={actionsCss}
                    >
                      <button
                        className={'mr-8'}
                        onClick={() =>
                          onActionClicked({
                            user,
                            mode: FormUIMode.SetPassword,
                          })
                        }
                      >
                        <Lock size={'20'} />
                      </button>
                      <button
                        className={'mr-8'}
                        onClick={() =>
                          onActionClicked({ user, mode: FormUIMode.Edit })
                        }
                      >
                        <Edit size={'20'} />
                      </button>
                      <button
                        onClick={() =>
                          onActionClicked({ user, mode: FormUIMode.Delete })
                        }
                      >
                        <Trash size={'20'} />
                      </button>
                    </div>
                  </td>
                ) : (
                  ''
                )}
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {!users?.length ? (
        <Text className={'w-full text-center my-20 text-3xl font-medium'}>
          No Data
        </Text>
      ) : (
        ''
      )}
    </div>
  );
}
