import buildQuery from 'odata-query';
import { Reminder, ReminderEditData } from 'types/Reminders';
import * as Request from 'Shared/helpers/request';
import { FilterParamsDashboard } from 'Admin/components/Dashborad/DashboardReminderSection';

export const fetchReminders = async ({
  filterParams,
  searchElement,
  skip = 0,
  top,
  id,
  sortBy = 'DueDate',
}: {
  filterParams?: FilterParamsDashboard;
  searchElement?: string;
  skip?: number;
  top?: number;
  id?: number;
  sortBy?: string;
}) => {
  const reminderDataList =
    'AssignUser,AssignUserId,CreateUser,CreateUserId,DueDate,ReminderId,TicketId,TicketTitle,Title';
  const search = searchElement
    ? {
        or: [
          {
            [`indexof(toupper(Title), '${String(
              searchElement.toUpperCase()
            )}')`]: {
              ge: 0,
            },
          },
          {
            [`indexof(toupper(AssignUser), '${String(
              searchElement.toUpperCase()
            )}')`]: {
              ge: 0,
            },
          },
        ],
      }
    : {};
  const dueDateRange = Array.isArray(filterParams?.duedate)
    ? filterParams?.duedate?.filter((date: Date) => date !== null) || []
    : filterParams?.duedate;
  const DueDate =
    Array.isArray(dueDateRange) && dueDateRange.length
      ? { ge: dueDateRange[0], le: dueDateRange[1] }
      : dueDateRange === null
      ? { eq: null }
      : {};
  const query = buildQuery({
    filter: id ? { ReminderId: id } : { '': search, DueDate },
    select: reminderDataList,
    orderBy: [`${sortBy} asc`],
  });
  return await Request.GET(
    `Api/Reminders/odata${query}${
      top ? `&$top=${top}&$skip=${skip}` : ''
    }&$count=true`
  );
};

export const addReminder = async (data: ReminderEditData) => {
  return await Request.POST({
    url: 'Api/Data/FDSReminder',
    params: data.item,
  });
};

export const updateReminder = async (
  id: number | undefined,
  data: Reminder | undefined
) => {
  return await Request.PUT({
    url: `Api/Data/FDSReminder/${id}`,
    params: data,
  });
};

export const deleteReminder = async (id: number | undefined) => {
  return await Request.DELETE('Api/Data/FDSReminder', id);
};
