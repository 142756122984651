import { Button, Text, Textarea, Badge } from '@mantine/core';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Camera, FileText, MessagePlus, X } from 'tabler-icons-react';
import { FormTicketMode, Ticket, TicketEditData } from 'types/Tickets';

interface AddTimelineProps {
  setIsAddTimeline(value: boolean): void;
  setFiles(files: any): void;
  onSubmit(data: TicketEditData): void;
  item?: Ticket;
  isMutating: boolean;
}

function AddTimeline({
  setIsAddTimeline,
  setFiles,
  onSubmit,
  item,
  isMutating,
}: AddTimelineProps) {
  const [documents, setDocuments] = useState<any[] | []>([]);
  const [images, setImages] = useState<string[] | []>([]);
  const [comment, setComment] = useState<string>('');
  const { register, handleSubmit } = useForm<Ticket>({
    defaultValues: item,
  });
  const fileLimitSize = 1e7;

  const submitHandler: SubmitHandler<Ticket> = (item) => {
    onSubmit({
      item: {
        ...item,
        FDSTicketId: item.FDSTicketId,
        Content: item.Content,
      },
      mode: FormTicketMode.AddNewTimeline,
    });
    setIsAddTimeline(false);
  };

  const uploadFiles = (event: any) => {
    const allowedFileTypes = ['pdf', 'docx', 'xlsx', 'heic'];
    const fileSize = event.target.files[0].size;
    const fileType = event.target.files[0].type;
    const isCorrectType = allowedFileTypes.some((item) =>
      fileType.endsWith(item)
    );

    if (fileSize <= fileLimitSize && isCorrectType) {
      setDocuments([...documents, event.target.files[0]]);
      setFiles((prevState: string[] | []) => [
        ...prevState,
        event.target.files[0],
      ]);
    }
  };

  const uploadImages = (event: any) => {
    const allowedFileTypes = ['jpg', 'jpeg', 'png'];
    const fileSize = event.target.files[0].size;
    const fileType = event.target.files[0].type;
    const isCorrectType = allowedFileTypes.some((item) =>
      fileType.endsWith(item)
    );

    if (fileSize <= fileLimitSize && isCorrectType) {
      setImages([...images, URL.createObjectURL(event.target.files[0])]);
      setFiles((prevState: string[] | []) => [
        ...prevState,
        event.target.files[0],
      ]);
    }
  };

  return (
    <form
      className={'w-80 h-fit bg-greyLight'}
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className={'flex items-center justify-between p-3.5'}>
        <Text className={'text-sm mr-3'}>Add comment or upload media</Text>
        <X
          size={20}
          className={'text-brand cursor-pointer'}
          onClick={() => setIsAddTimeline(false)}
        />
      </div>
      <div className={'p-3.5 w-full'}>
        <Textarea
          placeholder="Your comment"
          value={comment}
          {...register('Content')}
          onChange={(e) => setComment(e.target.value)}
        />
        <label
          htmlFor="inputFile"
          className={'flex items-center justify-center cursor-pointer'}
        >
          <Badge
            className={
              'text-brand border-brand w-full rounded-lg mt-5 h-12 cursor-pointer'
            }
            leftSection={<FileText size={20} />}
            size="lg"
            variant="outline"
          >
            Upload documents
          </Badge>
          <input
            className={'hidden'}
            id="inputFile"
            type="file"
            accept=".pdf,.docx,.xlsx,.heic"
            onChange={uploadFiles}
          />
        </label>
        <div className={'max-h-24 mt-5 overflow-y-auto'}>
          {documents.map((doc, index) => (
            <div className={'w-72'} key={index}>
              {doc.name}
            </div>
          ))}
        </div>
        <label
          htmlFor="inputAttachments"
          className={'flex items-center justify-center cursor-pointer'}
        >
          <Badge
            className={
              'text-brand border-brand w-full rounded-lg mt-3 h-12 cursor-pointer'
            }
            leftSection={<Camera size={20} />}
            size="lg"
            variant="outline"
          >
            Upload photos
          </Badge>
          <input
            className={'hidden'}
            id="inputAttachments"
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={uploadImages}
          />
        </label>
        <div className={'flex h-24 mb-11 mt-5 overflow-y-auto'}>
          {images.map((image, index) => (
            <img key={index} className={'rounded-xl'} src={image} alt={image} />
          ))}
        </div>
        <Button
          className={'bg-brand rounded-lg w-full'}
          type="submit"
          leftIcon={<MessagePlus size={20} />}
          size={'md'}
          disabled={isMutating}
        >
          Add comment
        </Button>
      </div>
    </form>
  );
}

export default AddTimeline;
