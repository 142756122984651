import { css } from '@emotion/react';
import { Button } from '@mantine/core';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { CircleCheck, Trash } from 'tabler-icons-react';
import { Question, Section } from 'types/Template';

interface SectionProps {
  section: Section;
  index: number;
  selectedSection?: Section;
  setSelectedSection(value: Section | undefined): void;
  sections: Section[];
  setSections(value: Section[]): void;
  handleDeleteSection(value: number): void;
}

const sectionCss = css`
  .mantine-Button-icon {
    padding-right: 13px;
  }
  .mantine-Button-rightIcon {
    svg {
      stroke: #c30d24;
    }
  }
  .mantine-Button-inner {
    justify-content: unset;
  }
  .mantine-Button-label {
    width: 100%;
    font-size: 14px;
  }
`;

const selectedSectionCss = css`
  ${sectionCss}
  & {
    border: 1px solid #c30d24 !important;
    .mantine-Button-icon {
      svg {
        stroke: #c30d24;
      }
    }
  }
`;

const TemplateSection = ({
  section,
  index,
  selectedSection,
  setSelectedSection,
  sections,
  setSections,
  handleDeleteSection,
}: SectionProps) => {
  const ref = useRef(null);
  const [collected, drop] = useDrop({
    accept: ['sections', 'question'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    drop: (item: Section & Question) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.Index || 0;
      const hoverIndex = index;
      const canDrop = !section?.Questions?.some(
        (question) =>
          question.CheckListQuestionTemplateId ===
          item.CheckListQuestionTemplateId
      );
      const filtredSectionsByOrder = update(sections, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, { ...sections[dragIndex], Order: hoverIndex }],
        ],
      });

      if (item.Question && canDrop) {
        const filtredSections = sections.map((s) => {
          const filtredQuestions = s?.Questions?.filter(
            (question) =>
              question['CheckListQuestionTemplateId' || 'Index'] !==
              item['CheckListQuestionTemplateId' || 'Index']
          );
          return {
            ...s,
            Questions: filtredQuestions,
          };
        });

        const fromIndex = [...filtredSections].splice(0, hoverIndex);
        const toIndex = [...filtredSections].splice(
          hoverIndex + 1,
          filtredSections.length
        );
        const sectionList: Section[] | [] =
          [
            ...(fromIndex || []),
            {
              ...section,
              Questions: [
                ...(section.Questions || []),
                item.Question ? item : [],
              ],
              Order: index,
            },
            ...(toIndex || []),
          ] || [];
        setSections(sectionList);
        if (selectedSection) {
          setSelectedSection(
            sectionList.length
              ? sectionList.find(
                  (section: Section) =>
                    section['CheckListSectionTemplateId' || 'Order'] ===
                    selectedSection['CheckListSectionTemplateId' || 'Order']
                )
              : undefined
          );
        }
      }

      if (!item.Question) {
        const filtredSections = filtredSectionsByOrder.map(
          (section, index) => ({
            ...section,
            Order: index,
          })
        );
        setSections(filtredSections);
        if (selectedSection) {
          setSelectedSection(
            filtredSections.find(
              (item) =>
                item['CheckListSectionTemplateId' || 'Order'] ===
                selectedSection['CheckListSectionTemplateId' || 'Order']
            )
          );
        }
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'sections',
    item: { ...section, Index: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      className={
        'flex items-center justify-between h-14 w-full mr-5 mb-3 border-none rounded-lg text-black text-sm'
      }
      css={
        selectedSection &&
        (selectedSection.Order === section.Order ||
          (selectedSection.CheckListSectionTemplateId &&
            selectedSection.CheckListSectionTemplateId ===
              section.CheckListSectionTemplateId))
          ? selectedSectionCss
          : sectionCss
      }
      ref={ref}
      key={index}
    >
      <Button
        className={
          'w-full h-14 border-none rounded-xl text-black text-sm p-0 pl-3 hover:bg-transparent'
        }
        leftIcon={<CircleCheck />}
        variant="outline"
        onClick={() => setSelectedSection({ ...section, Order: index })}
      >
        {section.SectionName}
      </Button>
      <div
        className={'cursor-pointer text-brand h-full flex items-center pr-2.5'}
        onClick={() => handleDeleteSection(index)}
      >
        <Trash />
      </div>
    </div>
  );
};

export default TemplateSection;
