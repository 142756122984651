import { css } from '@emotion/react';
import { Modal, Title, Badge, TextInput, Group, Button } from '@mantine/core';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  confirmModalCss,
  nameCss,
  tailwindConfig,
} from 'Shared/helpers/styles';
import { Eye, EyeOff, Lock, UserCircle } from 'tabler-icons-react';
import { FormUIMode } from 'types/UI';
import { MemberEditData, User } from 'types/Users';

interface ConfirmModalProps {
  user?: User;
  mode: any;
  onSubmit(data: MemberEditData): void;
  isMutating: boolean;
  opened: boolean;
  onClose(): void;
}

const textInputCss = css`
  input {
    font-size: 16px;
    background: ${(tailwindConfig.theme.backgroundColor as any)?.greyLight};
    border: 1px solid rgba(1, 1, 1, 0.1);
    border-radius: 10px;
    padding: 0 55px 0 70px;
    width: 100%;
  }
  & > div {
    div:first-of-type {
      svg {
        stroke: ${(tailwindConfig.theme.backgroundColor as any)?.brand};
      }
    }
  }
`;

const ConfirmModal = ({
  user,
  mode,
  opened,
  onClose,
  onSubmit,
  isMutating,
}: ConfirmModalProps) => {
  const { register, handleSubmit } = useForm<User>({
    defaultValues: user,
  });
  const submitHandler: SubmitHandler<User> = (user) => {
    onSubmit({ user, mode });
  };
  const [isClosedEye, setIsClosedEye] = useState<boolean>(false);

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => onClose()}
      css={confirmModalCss}
    >
      <form
        className={
          'flex flex-col gap-6 justify-center items-center max-w-[600px]'
        }
        onSubmit={handleSubmit(submitHandler)}
      >
        <Title order={1}>
          {mode === FormUIMode.SetPassword
            ? 'Set password for user'
            : 'Are you sure you want to delete a member?'}
        </Title>

        <Badge
          css={nameCss}
          color="dark"
          size="xl"
          radius="md"
          variant="light"
          leftSection={<UserCircle size={24} />}
        >
          {`${user?.FirstName} ${user?.Surname}`}
        </Badge>
        {mode === FormUIMode.SetPassword ? (
          <TextInput
            css={textInputCss}
            autoComplete={'current-password'}
            required
            size={'xl'}
            placeholder={'Password here'}
            type={isClosedEye ? 'text' : 'password'}
            icon={<Lock size={35} />}
            rightSection={
              isClosedEye ? (
                <EyeOff
                  size={25}
                  strokeWidth={1.5}
                  className={'relative right-5 text-grey'}
                  onClick={() => setIsClosedEye(false)}
                />
              ) : (
                <Eye
                  size={25}
                  strokeWidth={1.5}
                  className={'relative right-5 text-grey'}
                  onClick={() => setIsClosedEye(true)}
                />
              )
            }
            {...register('Password', { required: true })}
          />
        ) : (
          ''
        )}
        <Group className={'mt-10 m-auto'} position="right">
          <Button
            size="lg"
            className={'bg-brand w-52 rounded-lg'}
            type="submit"
            disabled={isMutating}
          >
            {mode === FormUIMode.SetPassword ? 'Set password' : 'Delete it'}
          </Button>
          <Button
            size="lg"
            className={'w-52 rounded-lg'}
            onClick={() => onClose()}
            variant="outline"
            disabled={isMutating}
          >
            Go back
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default ConfirmModal;
