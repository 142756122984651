import buildQuery from 'odata-query';
import {
  Checklist,
  ChecklistDetails,
  ChecklistEditData,
  ChecklistQuestionAnswer,
  ChecklistStatus,
  ChecklistTemplate,
} from 'types/Checklist';
import { fetchLabels } from 'Shared/data/Labels';
import { delay } from 'Shared/helpers/async';
import { TicketEditData } from 'types/Tickets';
import * as Request from 'Shared/helpers/request';
import { FilterParams } from 'Admin/components/Filter';

interface fetchChecklistsProps {
  filterParams?: FilterParams;
  searchElement?: string;
  skip?: number;
  top?: number;
  id?: number;
  sortByValue?: string;
  sortByOrder?: string;
}

export const defaultChecklistFilters: any = {
  labels: [],
  templateId: '0',
  status: ChecklistStatus.Draft,
  locationId: '0',
  dateRage: [null, null],
};

export const statusClassMap = {
  [ChecklistStatus.Draft]: 'yellow',
  [ChecklistStatus.InProgress]: 'yellow',
  [ChecklistStatus.Submit]: 'green',
  [ChecklistStatus.Disable]: 'gray',
};

export const fetchChecklists = async ({
  filterParams,
  searchElement,
  skip = 0,
  top,
  id,
  sortByValue = 'UpdateDate',
  sortByOrder = 'desc',
}: fetchChecklistsProps) => {
  const checklistDataList = `${
    id ? 'Sections,' : ''
  }CheckListId,CheckListName,TemplateId,LocationId,LocationName,Labels,Status,CreateDate,CreateUserId,CreateUserName,UpdateDate,CompletedDate,SubmitDate,TotalAttachment,TotalQuestion,TotalPassed,DueDate,PercentagePassed,CompletedUserName,PercentageAnswered`;
  const TemplateId =
    filterParams?.templatesIds && filterParams.templatesIds.length
      ? {
          and: {
            or: filterParams.templatesIds.map((id) => ({
              '': id,
            })),
          },
        }
      : {};
  const LocationId =
    filterParams?.locationIds && filterParams.locationIds.length
      ? {
          and: {
            or: filterParams.locationIds.map((id) => ({
              '': id,
            })),
          },
        }
      : {};

  const Labels = filterParams?.labelsNames.length
    ? {
        any: {
          '': {
            in: filterParams.labelsNames,
          },
        },
      }
    : {};
  const Status = filterParams?.statuses?.length
    ? {
        and: {
          or: filterParams.statuses.map((status) => ({
            '': status,
          })),
        },
      }
    : {};
  const createDateRange =
    filterParams?.createdate?.filter((date: string) => date !== null) || [];
  const CreateDate = createDateRange.length
    ? { ge: createDateRange[0], le: createDateRange[1] }
    : {};

  const dueDateRange = Array.isArray(filterParams?.duedate)
    ? filterParams?.duedate?.filter((date: string) => date !== null) || []
    : filterParams?.duedate;
  const DueDate =
    Array.isArray(dueDateRange) && dueDateRange.length
      ? { ge: dueDateRange[0], le: dueDateRange[1] }
      : dueDateRange === null
      ? { eq: null }
      : {};
  const CreateUserId = filterParams?.reporterIds?.length
    ? {
        and: {
          or: filterParams.reporterIds.map((id) => ({
            '': id,
          })),
        },
      }
    : {};
  const PercentagePassed =
    filterParams?.percentagePassed === 'Passed'
      ? {
          ge: 0.8,
        }
      : filterParams?.percentagePassed === 'Failed'
      ? { lt: 0.8 }
      : {};

  const filterExist =
    Object.keys(TemplateId).length ||
    Object.keys(LocationId).length ||
    Object.keys(Labels).length ||
    Object.keys(Status).length ||
    Object.keys(CreateDate).length ||
    Object.keys(DueDate).length ||
    Object.keys(CreateUserId).length ||
    Object.keys(PercentagePassed).length;
  const search = searchElement
    ? `${
        filterExist ? ' and ' : '&$filter='
      }(indexof(toupper(CheckListName), '${searchElement.toLocaleUpperCase()}') ge 0 or indexof(toupper(LocationName), '${searchElement.toLocaleUpperCase()}') ge 0)`
    : '';

  const query = buildQuery({
    filter: id
      ? { CheckListId: id }
      : {
          TemplateId,
          LocationId,
          Labels,
          Status,
          CreateDate,
          DueDate,
          CreateUserId,
          PercentagePassed,
        },
    select: checklistDataList,
  });

  return await Request.GET(
    `Api/CheckLists/odata${query}${search}${
      top ? `&$top=${top}&$skip=${skip}` : ''
    }&$orderBy=${sortByValue} ${sortByOrder}&$count=true
    `
  );
};

export const addChecklist = async (
  data: ChecklistEditData,
  ticketData: TicketEditData
) => {
  return await Request.POST({
    url: 'Api/Data/CheckList',
    params: data.item,
    type: 'Checklist',
    ticketData,
  });
};

export const updateChecklist = async (
  id: number | undefined,
  data: Checklist,
  files: any,
  deletedImages?: string[]
) => {
  return await Request.PUT({
    url: `Api/Data/CheckList/${id}`,
    params: data,
    files,
    type: 'Checklist',
    id,
    deletedImages,
  });
};

export const updateCheckListQuestion = async ({
  questionId,
  answer,
}: ChecklistQuestionAnswer) => {
  return await Request.PUT({
    url: `Api/Data/CheckListQuestion/${questionId}`,
    params: { Answer: answer },
  });
};

// export async function fetchChecklists({
//   filters,
// }: {
//   filters?: ChecklistFilters;
// }): Promise<Checklist[]> {
//   const labels = await fetchLabels();
//   await delay(500);

//   let result: Checklist[] = [];

//   if (filters) {
//     const hasAnyFilter =
//       filters.labels.length ||
//       filters.templateId !== '0' ||
//       filters.status !== ChecklistStatus.Any ||
//       filters.locationId !== '0' ||
//       filters.dateRage.filter(Boolean).length > 0;

//     if (hasAnyFilter) {
//       result = result
//         .filter(
//           (c) =>
//             filters.labels.every((f) => c.labels.includes(f)) ||
//             filters.labels.length === 0
//         )
//         .filter(
//           (c) =>
//             c.templateId === filters.templateId || filters.templateId === "0"
//         )
//         .filter(
//           (c) =>
//             c.status === filters.status ||
//             filters.status === ChecklistStatus.Any
//         )
//         .filter(
//           (c) =>
//             c.locationId === filters.locationId || filters.locationId === "0"
//         )
//         .filter((c) => true);
//     }

//     console.log("hasAnyFilter", hasAnyFilter, result);
//   }

//   return Promise.resolve(result);
// }

export async function fetchChecklistById({
  id,
}: {
  id: string;
}): Promise<ChecklistDetails> {
  const labels = await fetchLabels();
  await delay(500);

  // const result = getAllChecklistsMock(labels);
  // const checklist = result.find((checklist) => checklist.id === id);
  // const details = checklist ? getChecklistDetailsMock(checklist) : undefined;

  // if (!details) {
  //   throw new Error('Checklist details not found');
  // }

  // return Promise.resolve(details);
  throw new Error('Checklist details not found');
}

export async function fetchChecklistTemplates(): Promise<ChecklistTemplate[]> {
  return Promise.resolve([]);
}
