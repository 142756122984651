import { useMemo, useState } from 'react';
import { Alert, Button, LoadingOverlay, Table, Text } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import {
  FormTemplateMode,
  Section,
  Template,
  TemplateEditData,
} from 'types/Template';
import Actions from '../Actions';
import format from 'date-fns/format';
import SectionsList from './SectionsList';
import { rowClasses, tdCss, thClass, thCss } from 'Shared/helpers/styles';

interface TemplateListProps {
  hasOverlay: boolean;
  isError: boolean;
  error?: Error;
  templates: Template[];
  onActionClicked(data: TemplateEditData): void;
  openDetails: number;
  setOpenDetails(data: number): void;
  templateBeingEdited?: TemplateEditData;
  onSubmit(data: TemplateEditData): void;
  lastElementRef: any;
}

const TemplateList = ({
  hasOverlay,
  isError,
  error,
  templates,
  onActionClicked,
  openDetails,
  setOpenDetails,
  templateBeingEdited,
  onSubmit,
  lastElementRef,
}: TemplateListProps) => {
  const navigate = useNavigate();
  const [sections, setSections] = useState<Section[] | [] | undefined>([]);
  const [selectedSection, setSelectedSection] = useState<Section | undefined>(
    undefined
  );

  useMemo(() => {
    if (templateBeingEdited?.item?.Sections) {
      setSections(templateBeingEdited?.item?.Sections);
      setSelectedSection(
        templateBeingEdited?.item?.Sections?.length
          ? templateBeingEdited?.item?.Sections[0]
          : undefined
      );
    }
  }, [templateBeingEdited?.item?.Sections]);

  useMemo(() => {
    if (sections && sections.length) {
      const currentSections = sections.find(
        (section) =>
          section.CheckListSectionTemplateId ===
          selectedSection?.CheckListSectionTemplateId
      );

      if (!currentSections || !selectedSection) {
        setSelectedSection(sections[0]);
      }
    }
  }, [sections]);

  return (
    <div className="mt-12 col-span-9 flex flex-wrap gap-4 relative">
      <div className={'flex w-full'}>
        {isError ? (
          <Alert icon={<AlertCircle size={20} />} title="Oops!" color="red">
            We encountered an error! Please refresh or try again later. If the
            problem persists, please contact the administrator.
            <br />
            Error: {error?.message ?? 'Unknown error'}.
          </Alert>
        ) : (
          <>
            <LoadingOverlay visible={hasOverlay} className={'w-full h-full'} />
            <Table
              className={'drop-shadow-sm rounded-sm'}
              verticalSpacing={'md'}
              horizontalSpacing={'lg'}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th className={`${thClass} w-8/12`} css={thCss}>
                    Template name
                  </th>
                  <th className={`${thClass} w-2/12`} css={thCss}>
                    Date created
                  </th>
                  <th className={`${thClass} w-1/12`} css={thCss}>
                    Checklists
                  </th>
                  <th className={`${thClass} w-1/12`} css={thCss}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {templates?.length ? (
                  templates.map((template, index) => (
                    <tr
                      ref={
                        templates.length === index + 1
                          ? lastElementRef
                          : undefined
                      }
                      key={template.CheckListTemplateId}
                      className="relative"
                    >
                      <td css={tdCss}>
                        <div className={`${rowClasses} items-center`}>
                          {template.TemplateName}
                        </div>
                      </td>
                      <td css={tdCss}>
                        <div
                          className={`${rowClasses} items-center justify-center`}
                        >
                          {format(
                            new Date(template.CreateDate || ''),
                            'dd.MM.yyyy'
                          )}
                        </div>
                      </td>
                      <td css={tdCss}>
                        <div
                          className={`${rowClasses} items-center justify-center`}
                        >
                          {template.CheckListCount}
                        </div>
                      </td>
                      <td css={tdCss}>
                        <div
                          className={`${rowClasses} items-center justify-center`}
                        >
                          <Actions
                            hideDelete={true}
                            id={template.CheckListTemplateId}
                            onActionClicked={onActionClicked}
                            item={template}
                            openDetails={openDetails}
                            setOpenDetails={setOpenDetails}
                            editMode={FormTemplateMode.Edit}
                            setEditMode={(isEditMode) => {
                              onActionClicked({
                                item: template,
                                mode: FormTemplateMode.Edit,
                              });
                            }}
                            isAdmin={true}
                            route={`/checklists/templates/${template.CheckListTemplateId}`}
                            editRoute={`/checklists/templates/${template.CheckListTemplateId}/edit`}
                          />
                        </div>
                      </td>
                      {templateBeingEdited?.mode === FormTemplateMode.Edit ? (
                        <td
                          className={
                            'w-full absolute bg-white top-14 left-0 z-10 px-8 py-5'
                          }
                        >
                          <LoadingOverlay
                            visible={hasOverlay}
                            className={'w-full h-full'}
                          />
                          <SectionsList
                            sections={sections}
                            setSections={setSections}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                          />
                          <div className={'flex items-center mt-12'}>
                            <Button
                              size="lg"
                              className={'bg-brand w-52 rounded-lg p-0 mr-4'}
                              onClick={() =>
                                onSubmit({
                                  item: {
                                    ...templateBeingEdited?.item,
                                    Sections: sections,
                                  },
                                  mode: templateBeingEdited?.mode,
                                })
                              }
                            >
                              Save changes
                            </Button>
                            <Button
                              size="lg"
                              className={
                                'w-52 rounded-lg border-brand text-brand'
                              }
                              onClick={() => navigate(-1)}
                              variant="outline"
                              type="submit"
                            >
                              Go back
                            </Button>
                          </div>
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </>
        )}
      </div>
      {!templates?.length ? (
        <Text className={'w-full text-center my-20 text-3xl font-medium'}>
          No Data
        </Text>
      ) : (
        ''
      )}
    </div>
  );
};

export default TemplateList;
