import { css } from '@emotion/react';
import { Badge } from '@mantine/core';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { Check, Trash } from 'tabler-icons-react';
import { Question, Section } from 'types/Template';

interface SectionQuestionsProps {
  question: Question;
  index: number;
  handleAddQuestion(value: number): void;
  sections: Section[];
  setSections(value: Section[]): void;
  selectedSection?: Section;
  setSelectedSection(value: Section): void;
}

const questionCss = css`
  .mantine-Badge-root {
    height: 56px;
  }
  .mantine-Badge-inner {
    width: 100%;
    margin-left: 30px;
  }
  .mantine-Badge-rightSection {
    svg {
      stroke: #c30d24;
    }
  }
`;

const SectionQuestions = ({
  question,
  index,
  handleAddQuestion,
  sections,
  setSections,
  selectedSection,
  setSelectedSection,
}: SectionQuestionsProps) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'question',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    drop: (item: Section) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.Index || 0;
      const hoverIndex = index;

      if (selectedSection && selectedSection.Questions?.length) {
        const fromIndex = [...sections].splice(0, selectedSection.Order);
        const toIndex = [...sections].splice(
          selectedSection.Order + 1,
          sections.length
        );
        const filtredQuestios = update(selectedSection.Questions, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, selectedSection.Questions[dragIndex]],
          ],
        });
        setSections([
          ...fromIndex,
          {
            ...selectedSection,
            Questions: filtredQuestios.map((question, index) => ({
              ...question,
              Order: index,
            })),
          },
          ...toIndex,
        ]);
        setSelectedSection({
          ...selectedSection,
          Questions: update(selectedSection.Questions, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, selectedSection.Questions[dragIndex]],
            ],
          }),
        });
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'question',
    item: { ...question, Index: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Badge
      ref={ref}
      key={index}
      className={
        'w-full h-14 bg-stone text-black text-sm normal-case font-normal mb-3'
      }
      radius={'md'}
      css={questionCss}
      leftSection={<Check />}
      rightSection={
        <Trash
          className={'cursor-pointer'}
          onClick={() => {
            handleAddQuestion(index + 1);
          }}
        />
      }
    >
      {question.Question}
    </Badge>
  );
};

export default SectionQuestions;
