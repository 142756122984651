import { createRef } from 'react';
import { Outlet } from 'react-router-dom';
import RequiresAuth from 'Shared/components/RequiresAuth';
import MainNav from 'Admin/components/MainNav';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { css } from '@emotion/react';
import ScrollToTop from 'Shared/hooks/ScrollToTop';

const mainContentCss = css`
  width: calc(100vw - 16.3rem);
  margin-left: 15rem;
  &.fade-enter {
    opacity: 0;
    transform: translate(0, 25px);
    z-index: 1;
  }
  &.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translate(0, 0);

    transition: opacity 250ms ease-out, transform 300ms ease;
  }
  &.fade-exit {
    opacity: 0;
  }
`;

function MainLayout() {
  const location = useLocation();
  const transitionNode = createRef<HTMLDivElement>();

  return location.pathname === '/login' ? (
    <Outlet />
  ) : (
    <RequiresAuth>
      <div className={'max-w-screen-2xl relative'}>
        <MainNav />
        <TransitionGroup component={null}>
          <CSSTransition
            nodeRef={transitionNode}
            key={location.key}
            classNames="fade"
            timeout={300}
          >
            <div
              ref={transitionNode}
              className="px-8 pt-4 pb-32 main-content"
              css={mainContentCss}
            >
              <ScrollToTop>
                <Outlet />
              </ScrollToTop>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </RequiresAuth>
  );
}

export default MainLayout;
