export enum FormReminderMode {
  Add = 'Add',
  Edit = 'Edit',
  Delete = 'Delete',
}

export interface Reminder {
  AssignUser?: string;
  AssignUserId?: string;
  CreateUser?: string;
  CreateUserId?: string;
  DueDate?: Date | string;
  ReminderId?: number;
  TicketId?: number;
  TicketTitle?: string;
  Title?: string;
}

export interface ReminderEditData {
  item?: Reminder;
  mode?: FormReminderMode;
}
