import { css } from '@emotion/react';
import { Text, TextInput } from '@mantine/core';
import { useState } from 'react';
import { customScrollYCss, tailwindConfig } from 'Shared/helpers/styles';
import { Check, CircleCheck, Plus } from 'tabler-icons-react';
import { Section } from 'types/Template';
import SectionQuestions from './SectionQuestions';
import TemplateSection from './TemplateSection';

interface SectionsProps {
  sections?: Section[];
  setSections(value: Section[]): void;
  selectedSection?: Section;
  setSelectedSection(value?: Section): void;
}

const textInputCss = css`
  input {
    height: 50px;
    font-size: 14px;
    background: ${(tailwindConfig.theme.backgroundColor as any)?.stone};
  }
`;

const SectionsList = ({
  sections = [],
  setSections,
  selectedSection,
  setSelectedSection,
}: SectionsProps) => {
  const [sectionName, setSectionName] = useState<string>('');
  const [questionName, setQuestionName] = useState<string>('');

  const handleAddSection = () => {
    if (sectionName) {
      setSections([
        ...sections,
        {
          SectionName: sectionName,
          Order: sections.length ? sections.length : 0,
        },
      ]);
      setSelectedSection({
        SectionName: sectionName,
        Order: sections.length ? sections.length : 0,
      });
      setSectionName('');
    }
  };

  const handleDeleteSection = (index: number) => {
    const currentSection = sections
      .filter((_, i) => i === index)
      .find(
        (section) =>
          section.CheckListSectionTemplateId ===
          selectedSection?.CheckListSectionTemplateId
      );
    setSelectedSection(currentSection ? undefined : selectedSection);
    setSections([...sections.filter((_, i) => i !== index)]);
  };

  const handleAddQuestion = (index: number | undefined) => {
    if (selectedSection) {
      const fromIndex = [...sections].splice(0, selectedSection.Order);
      const toIndex = [...sections].splice(
        selectedSection.Order + 1,
        sections.length
      );
      if (!index && questionName) {
        setSections([
          ...fromIndex,
          {
            ...selectedSection,
            Questions: [
              ...(selectedSection.Questions || []),
              { Question: questionName },
            ],
          },
          ...toIndex,
        ]);
        setSelectedSection({
          ...selectedSection,
          Questions: [
            ...(selectedSection.Questions || []),
            { Question: questionName },
          ],
        });
      }
      if (index) {
        setSections([
          ...fromIndex,
          {
            ...selectedSection,
            Questions: [
              ...(selectedSection.Questions || []).filter(
                (_, i) => i !== index - 1
              ),
            ],
          },
          ...toIndex,
        ]);
        setSelectedSection({
          ...selectedSection,
          Questions: [
            ...(selectedSection.Questions || []).filter(
              (_, i) => i !== index - 1
            ),
          ],
        });
      }
      setQuestionName('');
    }
  };

  return (
    <div className={'flex'}>
      <div className={'w-1/2 mr-5'}>
        <Text className={'ml-5 mb-5'} weight={'600'}>
          Section
        </Text>
        <TextInput
          css={textInputCss}
          className={'mb-3.5'}
          placeholder={'Add new section'}
          radius={'md'}
          size={'lg'}
          icon={<CircleCheck />}
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          rightSection={
            <Plus
              className={'cursor-pointer'}
              onClick={() => handleAddSection()}
            />
          }
        />
        <div
          className={'overflow-y-auto overflow-x-hidden h-64'}
          css={customScrollYCss}
        >
          {sections.map((section, index) => (
            <TemplateSection
              key={index}
              index={index}
              section={section}
              sections={sections}
              setSections={setSections}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              handleDeleteSection={handleDeleteSection}
            />
          ))}
        </div>
      </div>
      <div className={'w-1/2 ml-5'}>
        <Text className={'ml-5 mb-5'} weight={'600'}>
          Questions
        </Text>
        <TextInput
          css={textInputCss}
          disabled={!selectedSection}
          className={'mb-3.5'}
          placeholder={'Add new question'}
          radius={'md'}
          size={'lg'}
          icon={<Check />}
          value={questionName}
          onChange={(e) => setQuestionName(e.target.value)}
          rightSection={
            <Plus
              className={`${
                selectedSection ? '' : 'cursor-not-allowed opacity-40'
              } cursor-pointer`}
              onClick={() => handleAddQuestion(undefined)}
            />
          }
        />
        <div
          className={'overflow-y-auto overflow-x-hidden h-64'}
          css={customScrollYCss}
        >
          {selectedSection?.Questions?.map((question, index) => (
            <SectionQuestions
              key={'questions' + index}
              question={question}
              index={index}
              handleAddQuestion={handleAddQuestion}
              sections={sections}
              setSections={setSections}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionsList;
