import { UserLoginCredentials } from 'types/Users';
import config from 'config';
import { useMutation } from 'react-query';
import { reportError } from 'Shared/helpers/logging';
import { useStore } from 'Shared/data/Store';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Request from 'Shared/helpers/request';

async function login(user: UserLoginCredentials): Promise<void> {
  let response: Response | undefined;

  try {
    const userParams = await Request.POST({
      url: 'Api/Token/Login',
      params: {
        ...user,
      },
      type: 'Login',
    });

    if (userParams) {
      localStorage.setItem('token', userParams.TokenString);
      localStorage.setItem('expireUCTTime', userParams.ExpireUCTTime);
      localStorage.setItem('refreshTokenString', userParams.RefreshTokenString);
      return userParams as Promise<any>;
    }
  } catch (e) {
    if (e instanceof Error) {
      reportError(e);
    }

    return Promise.reject(e);
  }
}

export function useAuthentication() {
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    (location.state as Record<string, any>)?.from?.pathname || '/admin';
  const setUserState = useStore((state) => state.setUserState);
  const mutation = useMutation<void, Error, UserLoginCredentials>(
    'login',
    (user) => {
      return login(user);
    },
    {
      onSuccess: (data: any, variables, context) => {
        // todo: update isAdmin correctly
        setUserState({
          userId: data.UserId,
          isLoggedIn: true,
          isAdmin: data.Roles[0] === 'Admin' ? true : false,
        });
        navigate(from, { replace: true });
      },
      onError: () => {
        localStorage.removeItem('token');
      },
    }
  );

  function onLoginSubmit({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    mutation.mutate({
      username,
      password,
      keepLogin: true,
      returnUrl: '',
    });
  }

  return { onLoginSubmit, loginMutation: mutation };
}
