import { Button, Group, Modal, MultiSelect, Text } from '@mantine/core';
import { Tag } from 'Admin/pages/Tickets';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addTag, fetchTags } from 'Shared/data/Tags';
import { confirmModalCss } from 'Shared/helpers/styles';

interface AddLabelsProps {
  title: string;
  opened: boolean;
  onClose(): void;
  labelsId?: string[];
  setLabelsId(value: string[]): void;
  handleAddLabels?(value: any[]): void;
  selectedTags?: string[];
  isAddTicketMode: boolean;
  isAddTickeLabel?: boolean;
  setSelectedLebels(value: string[]): void;
  setSelectedTicketsLebels?(value: string[]): void;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string;
  label: string;
  index: number;
}

const AddLabels = ({
  title,
  opened,
  onClose,
  labelsId,
  setLabelsId,
  handleAddLabels,
  selectedTags,
  isAddTicketMode,
  isAddTickeLabel,
  setSelectedLebels,
  setSelectedTicketsLebels,
}: AddLabelsProps) => {
  const perPage = 20;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [skip, setSkip] = useState<number>(perPage * (pageNumber - 1));
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [tags, setTags] = useState<Tag[] | undefined>(undefined);
  const [labels, setLabels] = useState<string[] | []>([]);
  const { refetch } = useQuery<
    { value: Tag[]; ['@odata.count']: number },
    Error
  >(
    ['tags'],
    async () =>
      await fetchTags({
        skip,
        top: perPage,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data && data.value.length) {
          setTagsData(data);
        }
      },
    }
  );

  const setTagsData = useCallback(
    ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: any[];
      ['@odata.count']: number;
    }) => {
      setTags([...(skip === 0 ? [] : tags || []), ...(data || [])]);
      setHasMore(dataCount > skip + perPage);
    },
    [tags, pageNumber, skip]
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetching) return '';
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          setPageNumber(pageNumber + 1);
          setSkip(perPage * pageNumber);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetching, hasMore]
  );

  const refetchData = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  useEffect(() => {
    refetchData();
  }, [pageNumber]);

  const tagsOptions = useMemo(
    () =>
      tags
        ?.filter((tag) => {
          const findTag = selectedTags?.find((label) => label === tag.TagName);
          if (!findTag) return tag;
        })
        .map((tag, index) => ({
          value: String(tag.Id),
          label: tag.TagName,
          index,
        })),
    [tags]
  );

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, index, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Text
          className={'w-full cursor-pointer'}
          ref={
            (tagsOptions || []).length === index + 1
              ? lastElementRef
              : undefined
          }
          key={value}
        >
          {label}
        </Text>
      </div>
    )
  );
  SelectItem.displayName = 'tagsSelect';

  const queryClient = useQueryClient();
  const mutation = useMutation(addTag, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['tags']);
      const filterdLabes = labels.filter((label) => {
        const findLabel = selectedTags?.find((tag) => tag === label);
        if (!findLabel) return label;
      });
      setLabels([...filterdLabes, ...(selectedTags || []), String(data)]);
      setLabelsId([...(selectedTags || []), String(data)]);
    },
  });
  const handleCreateLabel = (name: string) => {
    mutation.mutate(name);
  };

  const handleSave = () => {
    if (handleAddLabels && !isAddTicketMode) {
      handleAddLabels(
        tagsOptions?.filter((tag) => {
          const findTag = labels.find((id) => id === tag.value);
          if (findTag) {
            return tag;
          }
        }) || []
      );
    } else {
      const filtredTags = tags?.filter((tag) => {
        const findTag = labels.find(
          (label) => label === String(tag.Id) || label === tag.TagName
        );
        if (findTag) {
          return tag;
        }
      });
      isAddTickeLabel && setSelectedTicketsLebels
        ? setSelectedTicketsLebels(
            filtredTags?.length
              ? filtredTags.map((tag) => tag.TagName).concat(selectedTags || [])
              : selectedTags || []
          )
        : setSelectedLebels(
            filtredTags?.length
              ? filtredTags.map((tag) => tag.TagName).concat(selectedTags || [])
              : selectedTags || []
          );
      onClose();
    }
    setLabelsId([...(labelsId || []), ...labels]);
  };

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => onClose()}
      css={confirmModalCss}
    >
      <div className={'px-8 flex flex-col justify-between'}>
        <Text className={'text-[40px] font-semibold mb-6 leading-10'}>
          {title}
        </Text>
        <MultiSelect
          className={'my-12'}
          itemComponent={SelectItem}
          data={tagsOptions || []}
          size={'xl'}
          placeholder="Select items"
          creatable
          searchable
          getCreateLabel={(query) => `+ Create ${query}`}
          onCreate={(query) => {
            handleCreateLabel(query);
          }}
          onChange={(items) => setLabels(items)}
        />
        <Group
          className={'mt-8 flex justify-start mb-11 w-full'}
          position="right"
        >
          <Button
            className={'bg-brand rounded-lg w-1/2'}
            type="submit"
            size={'lg'}
            disabled={mutation.isLoading}
            onClick={() => handleSave()}
          >
            Save changes
          </Button>

          <Button
            className={'rounded-lg w-2/6'}
            variant={'outline'}
            size={'lg'}
            disabled={mutation.isLoading}
            onClick={() => {
              onClose();
            }}
          >
            Go back
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default AddLabels;
