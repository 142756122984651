import { Button, Text } from '@mantine/core';
import {
  Check,
  CircleMinus,
  FileUnknown,
  Ticket as TicketIcon,
} from 'tabler-icons-react';
import { ChecklistResult, ChecklistSectionQuestions } from 'types/Checklist';
import { Ticket, TicketEditData } from 'types/Tickets';
import { User } from 'types/Users';
import TicketCard from 'Admin/components/Ticket/TicketCard';
import { UserState } from 'types/Store';

interface CheckListQuestionProps {
  question: ChecklistSectionQuestions;
  setChecklistAnswer(questionId: number, answer: string | null): void;
  setIsAddTicketMode?(value: boolean): void;
  questionTicket?: Ticket;
  setTicketBeingEdited(data: TicketEditData): void;
  hasPremision: boolean;
  isCompletedChecklist: boolean;
  currentUser: UserState;
}

const CheckListQuestion = ({
  question,
  setChecklistAnswer,
  setIsAddTicketMode,
  questionTicket,
  setTicketBeingEdited,
  hasPremision,
  isCompletedChecklist,
  currentUser,
}: CheckListQuestionProps) => {
  return (
    <div className={'ml-12 mb-3.5'}>
      {hasPremision ? (
        <>
          <Text className={'ml-4 mb-3.5'}>Answer</Text>
          <Button
            className={'bg-green w-32 rounded-3xl mr-6'}
            leftIcon={<Check />}
            onClick={() => {
              if (question.Answer !== ChecklistResult.Passed) {
                setChecklistAnswer(
                  question.CheckListQuestionId,
                  ChecklistResult.Passed
                );
              }
            }}
            disabled={isCompletedChecklist}
          >
            Passed
          </Button>
          <Button
            className={'bg-brand w-32 rounded-3xl mr-6'}
            leftIcon={<CircleMinus />}
            disabled={
              question.Answer === ChecklistResult.Passed || isCompletedChecklist
            }
            onClick={() =>
              setChecklistAnswer(
                question.CheckListQuestionId,
                ChecklistResult.Failed
              )
            }
          >
            Failed
          </Button>
          <Button
            className={'bg-grey w-32 rounded-3xl'}
            leftIcon={<FileUnknown />}
            disabled={
              question.Answer === ChecklistResult.Passed || isCompletedChecklist
            }
            onClick={() =>
              setChecklistAnswer(
                question.CheckListQuestionId,
                ChecklistResult.NotApplicable
              )
            }
          >
            N/A
          </Button>
        </>
      ) : (
        ''
      )}
      {question.Answer === ChecklistResult.Failed && questionTicket ? (
        <div className={'mt-7'}>
          <TicketCard
            ticket={questionTicket}
            status={questionTicket.Status}
            title={questionTicket.Title}
            description={questionTicket.Description}
            labels={questionTicket.Labels}
            CreateDate={questionTicket.CreateDate}
            setTicketBeingEdited={setTicketBeingEdited}
            setEditMode={() => ''}
            setIsPreviewTicket={() => ''}
            UpdateDate={questionTicket.UpdateDate}
            lastElementRef={undefined}
            currentUser={currentUser}
          />
        </div>
      ) : (
        ''
      )}
      {question.Answer === ChecklistResult.Failed &&
      !questionTicket &&
      hasPremision ? (
        <div className={'flex items-center my-5 pt-5 border-t'}>
          <Text className={'text-sm mr-3'}>Attach a ticket to this task</Text>
          <Button
            className={'bg-brand h-10'}
            radius={'md'}
            leftIcon={<TicketIcon size={20} className={'rotate-[270deg]'} />}
            onClick={() => {
              setIsAddTicketMode && setIsAddTicketMode(true);
            }}
          >
            Create ticket
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CheckListQuestion;
