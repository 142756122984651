import { Badge, Text } from '@mantine/core';
import { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Direction, SortAscending2, SortDescending2 } from 'tabler-icons-react';

interface SortBySelectProps {
  classNames?: string;
  data: any[];
  value: string;
  order: string;
  searchParams: any;
  setSearchParams(value: any): void;
}

const SortBySelect = ({
  classNames,
  data = [],
  value,
  order,
  searchParams,
  setSearchParams,
}: SortBySelectProps) => {
  const [showList, setShowList] = useState(false);
  const ref = useOnclickOutside(() => {
    setShowList(false);
  });
  return (
    <div
      className={`w-full relative pl-2 pr-4 ${classNames}`}
      ref={ref}
      onClick={() => setShowList(true)}
    >
      <div
        className={
          'w-full flex justify-between py-2 items-center cursor-pointer'
        }
      >
        {value ? (
          <div className={'ml-2'}>{value}</div>
        ) : (
          <Text className={'text-sm font-semibold opacity-50 ml-2'}>
            Sort by
          </Text>
        )}

        <Direction className={'text-brand'} size={40} />
      </div>
      {showList ? (
        <div
          className={
            'relative w-full bg-white z-10 flex items-center justify-between p-4 rounded-lg'
          }
        >
          <div>
            {data.map((item, index) => (
              <Badge
                className={`h-7 mr-1.5 rounded-2xl normal-case font-medium text-sm cursor-pointer ${
                  value === item.value
                    ? 'bg-brand text-white'
                    : 'bg-transparent text-brand'
                }`}
                key={item + index}
                onClick={() => {
                  searchParams.set('sortByDate', item.value);
                  setSearchParams(searchParams);
                }}
              >
                {item.label}
              </Badge>
            ))}
          </div>
          <div>
            <div
              className={'flex items-center'}
              onClick={() => {
                searchParams.set('sortByOrder', 'asc');
                setSearchParams(searchParams);
              }}
            >
              <SortAscending2
                className={`cursor-pointer ${
                  order === 'asc' ? 'text-brand' : 'text-grey opacity-70'
                }`}
                strokeWidth={1.5}
                size={20}
              />
              <Text
                className={`cursor-pointer ${
                  order === 'asc' ? 'text-brand' : 'text-grey opacity-70'
                } font-medium text-sm`}
              >
                Ascending
              </Text>
            </div>
            <div
              className={'flex items-center'}
              onClick={() => {
                searchParams.set('sortByOrder', 'desc');
                setSearchParams(searchParams);
              }}
            >
              <SortDescending2
                className={`cursor-pointer ${
                  order === 'desc' ? 'text-brand' : 'text-grey opacity-70'
                }`}
                strokeWidth={1.5}
                size={20}
              />
              <Text
                className={`cursor-pointer ${
                  order === 'desc' ? 'text-brand' : 'text-grey opacity-70'
                } font-medium text-sm`}
              >
                Descending
              </Text>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SortBySelect;
