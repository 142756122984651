export enum FormLocationMode {
  AddLocation = 'AddLocation',
  EditLocation = 'EditLocation',
  DeleteLocation = 'DeleteLocation',
}
export enum FormFloorMode {
  AddFloor = 'AddFloor',
  EditFloor = 'EditFloor',
  DeleteFloor = 'DeleteFloor',
}
export enum FormAreaMode {
  AddArea = 'AddArea',
  EditArea = 'EditArea',
  DeleteArea = 'DeleteArea',
}

export interface Location {
  LocationId?: number;
  Floors?: number;
  Photos?: number;
  Name?: string;
  Attachments?: string[];
  Address1?: string;
  Latitude?: string;
  Longtitude?: string;
}
export interface Floor {
  FloorId?: number;
  LocationId?: number;
  Name?: string;
  FloorPlans?: string[];
  Images?: string[];
}

export interface Area {
  AreaId?: number;
  FloorId?: number;
  LocationId?: number;
  Name?: string;
  Images?: string[];
}

export interface LocationPerformace {
  LocationId: number;
  LocationName: string;
  Month: number;
  Passed: number;
  Performing: number;
  Total: number;
  Year: number;
}

export interface LocationEditData {
  item?: Location & Floor & Area;
  mode?: FormLocationMode | FormFloorMode | FormAreaMode;
}
