import { Logout } from 'tabler-icons-react';
import NavbarLink from 'Admin/components/NavbarLink';
import { routes } from 'Admin/AdminRoutes';
import { css } from '@emotion/react';
import { logoCss } from 'Shared/helpers/styles';

// left: calc((100vw - 1536px) / 2 - 8px);
const mainNavCss = css`
  left: 0;
`;

export default function MainNav() {
  const items = routes
    .filter((route) => route.menuItem.parent === '' && route.path !== 'login')
    .map((route, index) => (
      <NavbarLink
        {...route.menuItem}
        key={route.menuItem.label}
        path={route.path}
      />
    ));

  return (
    <nav
      className={
        'w-60 h-screen fixed top-0 flex flex-col justify-between bg-white drop-shadow z-50'
      }
      css={mainNavCss}
    >
      <section className={'mt-4 px-4 py-8'}>
        <header className={'flex flex-col items-center'}>
          <div className={'w-8/12'} css={logoCss} />
        </header>
        <div className={'mt-8 flex flex-col'}>{items}</div>
      </section>

      <section>
        <NavbarLink className={'test'} icon={Logout} label="Logout" />
      </section>
    </nav>
  );
}
