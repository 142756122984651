import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserLoginFields } from 'types/Users';
import { useAuthentication } from 'Shared/hooks/Auth';
import { Button, Group, TextInput, Text, Title } from '@mantine/core';
import { Lock, User, Eye, EyeOff } from 'tabler-icons-react';
import { showErrorToast } from 'Shared/helpers/ui';
import { css } from '@emotion/react';
import logo from 'Shared/assets/images/fds-logo.png';
import loginBackground from 'Shared/assets/images/login-background.png';
import { tailwindConfig } from 'Shared/helpers/styles';

function Login() {
  const { register, handleSubmit } = useForm<UserLoginFields>();
  const { onLoginSubmit, loginMutation } = useAuthentication();
  const submitHandler: SubmitHandler<UserLoginFields> = (user) => {
    onLoginSubmit(user);
  };
  const [isClosedEye, setIsClosedEye] = useState<boolean>(false);
  const logoCss = css`
    background: url(${logo}) no-repeat center center / cover;
    width: 156px;
    height: 125px;
  `;
  const formCss = css`
    height: 540px;
    width: 600px;
    border-radius: 24px;
    border: 1px solid rgb(248, 250, 252, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    form {
      --tw-border-opacity: 0.1;
    }
    .mantine-Title-root {
      font-weight: 600;
      font-size: 40px;
      line-height: 53px;
      color: ${(tailwindConfig.theme.backgroundColor as any)?.greyLight};
    }
    .mantine-Text-root {
      font-weight: 300;
      line-height: 53px;
      color: ${(tailwindConfig.theme.backgroundColor as any)?.greyLight};
    }
  `;
  const TextInputCss = css`
    input {
      height: 55px;
      border: 1px solid rgba(1, 1, 1, 0.1);
      border-radius: 10px;
      padding: 0 55px 0 80px;
      background: rgba(251, 251, 251, 0.22);
      color: #ffffff;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0.22) inset !important;
    }
    svg {
      height: 45px;
      stroke: #ffffff;
    }
    & > div {
      & > div:nth-child(1) {
        height: 45px;
        width: 68px;
        margin-top: 4px;
        border-right: 1px solid rgb(255, 255, 255, 0.15);
      }
    }
  `;
  const blurCss = css`
    margin-top: 40px;
    background: rgba(112, 102, 102, 0.06);
    backdrop-filter: blur(6px);
    height: 540px;
    width: 600px;
    position: absolute;
    border-radius: 24px;
  `;

  useEffect(() => {
    if (loginMutation.isError) {
      showErrorToast(
        `Error when logging in. ${loginMutation.error?.message ?? ''}`
      );
    }
  }, [loginMutation.isError, loginMutation.error?.message]);

  return (
    <div
      className="login h-[100vh] flex justify-center"
      style={{
        background: `url(${loginBackground}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      }}
    >
      <div css={blurCss}></div>
      <div className={'drop-shadow'} css={formCss} style={{}}>
        <div css={logoCss} />
        <form
          className={'flex flex-col w-full px-12 border-t border-slate-50'}
          onSubmit={handleSubmit(submitHandler)}
        >
          <Title align={'center'}>Login to continue</Title>
          <Text size={'xl'} align={'center'}>
            Enter your login data to continue
          </Text>
          <TextInput
            className={'mt-5'}
            css={TextInputCss}
            size={'lg'}
            radius={'md'}
            placeholder={'Your username'}
            type={'email'}
            autoComplete={'email'}
            required
            icon={<User size={30} strokeWidth={1.5} />}
            {...register('username', { required: true })}
          />
          <TextInput
            className={'mt-6'}
            css={TextInputCss}
            size={'lg'}
            radius={'md'}
            placeholder={'Your password'}
            type={isClosedEye ? 'text' : 'password'}
            autoComplete={'current-password'}
            required
            icon={<Lock size={30} strokeWidth={1.5} />}
            rightSection={
              isClosedEye ? (
                <EyeOff
                  size={25}
                  strokeWidth={1.5}
                  className={'relative right-5 text-white cursor-pointer'}
                  onClick={() => setIsClosedEye(false)}
                />
              ) : (
                <Eye
                  size={25}
                  strokeWidth={1.5}
                  className={'relative right-5 text-white cursor-pointer'}
                  onClick={() => setIsClosedEye(true)}
                />
              )
            }
            {...register('password', { required: true })}
          />
          <Group className={'mt-10 m-auto'} position="right">
            <Button
              size="lg"
              className={'bg-brand w-52 rounded-lg text-xl'}
              type="submit"
              disabled={loginMutation.isLoading}
            >
              Login
            </Button>
          </Group>
        </form>
      </div>
    </div>
  );
}

export default Login;
