import { ReactElement, useEffect } from 'react';

const ScrollToTop = ({ children }: { children: ReactElement }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return <>{children}</>;
};

export default ScrollToTop;
