import { MemberEditData, User, UserRole } from 'types/Users';
import {
  Button,
  Group,
  Input,
  NativeSelect,
  NumberInput,
  Text,
  TextInput,
} from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { FormUIMode } from 'types/UI';

export interface MemberEditFormProps extends MemberEditData {
  user: User;
  onSubmit(data: MemberEditData): void;
  isMutating: boolean;
  hasMutationError: boolean;
  onClose(): void;
}

const userRoleSelectData = [UserRole.Regular, UserRole.Admin];

export default function MemberEditForm({
  user,
  mode,
  onSubmit,
  isMutating,
  onClose,
}: MemberEditFormProps) {
  const { register, handleSubmit, formState } = useForm<User>({
    defaultValues: mode === FormUIMode.Add ? {} : user,
  });
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(
    Number(user.PhoneNumber) || undefined
  );
  const [deleteConfirmed, setDeleteConfirmed] = useState<boolean>(false);
  const submitHandler: SubmitHandler<User> = (user) => {
    onSubmit({ user: { ...user, PhoneNumber: String(phoneNumber) }, mode });
  };

  return (
    <div>
      <Text className={'text-[40px] font-semibold mb-6 leading-10 mx-14'}>
        {mode === FormUIMode.Add ? 'Add new member' : 'Edit member'}
      </Text>
      <form
        className={'mt-4 flex flex-col gap-4 mx-8'}
        onSubmit={handleSubmit(submitHandler)}
      >
        <TextInput
          className={'h-16 min-w-[580px]'}
          placeholder="First name"
          size={'xl'}
          radius={'md'}
          required
          {...register('FirstName', { required: true })}
        />
        <TextInput
          className={'h-16'}
          placeholder="Surname"
          size={'xl'}
          radius={'md'}
          required
          {...register('Surname', { required: true })}
        />
        <Group>
          <NativeSelect
            placeholder="User level"
            data={userRoleSelectData}
            className={'flex-1'}
            size={'xl'}
            radius={'md'}
            required
            {...register('UserRole', { required: true })}
          />
          <TextInput
            placeholder="Job Title"
            className={'flex-1'}
            size={'xl'}
            radius={'md'}
            required
            {...register('JobTitle', { required: true })}
          />
        </Group>
        <TextInput
          placeholder="E-mail adress"
          size={'xl'}
          radius={'md'}
          required
          {...register('Email', { required: true })}
        />
        <Input
          type={'number'}
          placeholder="Phone number"
          size={'xl'}
          radius={'md'}
          value={phoneNumber}
          required
          onChange={(e: any) =>
            setPhoneNumber(Number(e.target.value) || undefined)
          }
        />
        <Group className={'mt-8 flex justify-start mb-11'}>
          <Button
            className={'bg-brand rounded-lg w-52'}
            type="submit"
            size={'lg'}
            disabled={isMutating}
          >
            {mode === FormUIMode.Edit ? 'Save Changes' : 'Add Member'}
          </Button>

          <Button
            className={'w-48 rounded-lg'}
            variant={'outline'}
            size={'lg'}
            disabled={isMutating}
            onClick={() => onClose()}
          >
            Go back
          </Button>
        </Group>
      </form>
    </div>
  );
}
