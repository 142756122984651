import buildQuery from 'odata-query';
import { TemplateEditData } from 'types/Template';
import * as Request from 'Shared/helpers/request';
import { FilterParams } from 'Admin/components/Filter';

export const fetchChecklistsTemplates = async ({
  filterParams,
  searchElement,
  skip = 0,
  top,
  id,
}: {
  filterParams?: FilterParams;
  searchElement?: string;
  skip?: number;
  top?: number;
  id?: number;
}) => {
  const templateDataList = `${
    id ? 'Sections,' : ''
  }CheckListCount,CheckListTemplateId,CreateDate,QueryKey,TemplateName`;
  const CheckListTemplateId = filterParams?.templatesIds?.length
    ? {
        and: {
          or: filterParams.templatesIds.map((id) => ({
            '': id,
          })),
        },
      }
    : {};
  const search = searchElement
    ? {
        or: [
          {
            [`indexof(toupper(TemplateName), '${String(
              searchElement.toUpperCase()
            )}')`]: {
              ge: 0,
            },
          },
        ],
      }
    : {};
  const query = buildQuery({
    filter: id
      ? { CheckListTemplateId: id }
      : { CheckListTemplateId, '': search },
    select: templateDataList,
  });
  return await Request.GET(
    `Api/CheckListTemplates/odata${query}${
      top ? `&$top=${top}&$skip=${skip}` : ''
    }&$count=true`
  );
};

export const addTemplate = async (data: TemplateEditData) => {
  return await Request.POST({
    url: 'Api/CheckListTemplates',
    params: data.item,
  });
};

export const updateTemplate = async (data: TemplateEditData) => {
  return await Request.PUT({
    url: `Api/CheckListTemplates/${data.item?.CheckListTemplateId}`,
    params: { sections: data.item?.Sections },
  });
};
