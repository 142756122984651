export enum ChecklistStatus {
  Draft = 'Draft',
  InProgress = 'In Progress',
  Submit = 'Submit',
  Disable = 'Disable',
}

export enum FormChecklistMode {
  Add = 'Add',
  Edit = 'Edit',
  Delete = 'Delete',
  AddLabel = 'AddLabel',
  UpdateQuesionAnswer = 'UpdateQuesionAnswer',
  UpdateChecklist = 'UpdateChecklist',
  AddPhoto = 'AddPhoto',
}

export enum ChecklistResult {
  Passed = 'Passed',
  Failed = 'Failed',
  NotApplicable = 'NotApplicable',
}

export interface Checklist {
  CheckListId?: number;
  CheckListName?: string;
  TemplateId?: number;
  LocationId?: number;
  LocationName?: string;
  Labels?: string[];
  Status?: string;
  CreateDate?: Date;
  CreateUserId?: string;
  CreateUserName?: string;
  UpdateDate?: Date;
  CompletedDate?: Date;
  SubmitDate?: Date;
  TotalAttachment?: number;
  FirstAttachmentUUID?: number;
  TotalQuestion?: number;
  TotalPassed?: number;
  DueDate?: Date;
  QueryKey?: number;
  TagIds?: string;
  Sections?: ChecklistSection[];
  PercentagePassed?: number;
  CompletedUserName?: string;
  PercentageAnswered?: number;
}

export interface ChecklistEditData {
  item?: Checklist;
  mode?: FormChecklistMode;
}

export interface ChecklistTemplate {
  id: string;
  name: string;
}

export interface ChecklistAnswer {
  question: string;
  answer: ChecklistResult;
}

export interface ChecklistSectionQuestions {
  CheckListQuestionId: number;
  Order: number;
  Question: string;
  Answer: string | null;
  Attachments: string[];
}

export interface ChecklistQuestionAnswer {
  questionId?: number;
  answer?: string | null;
}

export interface ChecklistSection {
  CheckListSectionId: number;
  SectionName: string;
  answers: ChecklistAnswer[];
  Questions: ChecklistSectionQuestions[];
}

export interface ChecklistDetails extends Checklist {
  sections: ChecklistSection[];
}
