import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LocationList from 'Admin/components/Location/LocationList';
import PageHeader from 'Admin/components/PageHeader';
import { Plus } from 'tabler-icons-react';
import {
  Area,
  Floor,
  FormAreaMode,
  FormFloorMode,
  FormLocationMode,
  LocationEditData,
  Location,
} from 'types/Locations';
import CreateEditModal from 'Admin/components/Location/CreateEditModal';
import {
  addArea,
  addFloor,
  addLocation,
  deleteArea,
  deleteFloor,
  deleteLocation,
  fetchAreas,
  fetchFloors,
  fetchLocations,
  updateArea,
  updateFloor,
  updateLocation,
} from 'Shared/data/Locations';
import { showErrorToast, showOkToast } from 'Shared/helpers/ui';
import DeleteModal from 'Admin/components/DeleteModal';
import { useJsApiLoader } from '@react-google-maps/api';
import { defaultTheme } from 'Shared/helpers/mapTheme';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import LocationSearch from 'Admin/components/Location/LocationSearch';
import { useStore } from 'Shared/data/Store';
import useDebounce from 'Shared/hooks/useDebounce';

const notificationMessages = {
  [FormLocationMode.AddLocation]:
    'The new location has been added successfully',
  [FormLocationMode.EditLocation]: 'Location details saved successfully',
  [FormLocationMode.DeleteLocation]:
    'The location has been deleted successfully',
  [FormFloorMode.AddFloor]: 'The new floor has been added successfully',
  [FormFloorMode.EditFloor]: 'Floor details saved successfully',
  [FormFloorMode.DeleteFloor]: 'The floor has been deleted successfully',
  [FormAreaMode.AddArea]: 'The new area has been added successfully',
  [FormAreaMode.EditArea]: 'Area details saved successfully',
  [FormAreaMode.DeleteArea]: 'The area has been deleted successfully',

  error:
    'Error encountered. Please try again or contact the administrator if the issue persists.',
};

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const Locations = () => {
  const { locationId, areaId, floorId } = useParams();
  const [libraries] = useState<['places']>(['places']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries: libraries,
  });
  const defaultEditData = { item: undefined, mode: undefined };
  const [locationBeingEdited, setLocationBeingEdited] =
    useState<LocationEditData>(defaultEditData);
  const [openDetails, setOpenDetails] = useState<number | undefined>(undefined);
  const [openFloorDetails, setOpenFloorDetails] = useState<number>(0);
  const [openAreaDetails, setOpenAreaDetails] = useState<number>(0);
  const [filtredFloor, setFiltredFloor] = useState<Floor[] | []>([]);
  const [allAreas, setAllAreas] = useState<Area[] | []>([]);
  const [filtredArea, setFiltredArea] = useState<Area[] | []>([]);
  const [files, setFiles] = useState<any>('');
  const [selectedFloor, setSelectedFloor] = useState<Floor | undefined>(
    undefined
  );
  const [currentLocation, setCurrentLocation] = useState<Location | undefined>(
    undefined
  );
  const [deletedImages, setDeletedImages] = useState<string[] | []>([]);
  const center = {
    lat: 51.5072178,
    lng: -0.1275862,
  };
  const [locations, setLocations] = useState<Location[] | []>([]);
  const [searchResult, setSearchResult] = useState<Location[] | []>([]);

  const perPage = 20;
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchPageNumber, setSearchPageNumber] = useState<number>(1);
  const [skipSearch, setSkipSearch] = useState<number>(
    perPage * (searchPageNumber - 1)
  );
  const [searchHasMore, setSearchHasMore] = useState<boolean>(false);
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [areaData, setAreaData] = useState<Area[] | undefined>(undefined);
  const [floorData, setFloorData] = useState<Floor[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deferredSearchQuery = useDebounce(
    useDeferredValue(searchParams.get('search') || ''),
    500
  );

  const { currentUser } = useStore((state) => ({
    queryStaleTimeMs: state.members.queryStaleTimeMs,
    currentUser: state.user,
  }));

  const locationsEdited =
    locationBeingEdited.mode === FormLocationMode.AddLocation ||
    locationBeingEdited.mode === FormLocationMode.EditLocation;
  const locationDeleted =
    locationBeingEdited.mode === FormLocationMode.DeleteLocation;
  const floorsEdited =
    locationBeingEdited.mode === FormFloorMode.AddFloor ||
    locationBeingEdited.mode === FormFloorMode.EditFloor;
  const floorDeleted = locationBeingEdited.mode === FormFloorMode.DeleteFloor;
  const areasEdited =
    locationBeingEdited.mode === FormAreaMode.AddArea ||
    locationBeingEdited.mode === FormAreaMode.EditArea;
  const areaDeleted = locationBeingEdited.mode === FormAreaMode.DeleteArea;

  const getCurrentType = useCallback(() => {
    if (locationsEdited) return 'locations';
    if (floorsEdited) return 'floors';
    if (areasEdited) return 'areas';

    return '';
  }, [locationBeingEdited]);

  const openDeleteModal = useCallback(() => {
    switch (locationBeingEdited.mode) {
      case FormLocationMode.DeleteLocation:
        return 'location';
      case FormFloorMode.DeleteFloor:
        return 'floor';
      case FormAreaMode.DeleteArea:
        return 'area';
      default:
        return '';
    }
  }, [locationBeingEdited]);

  const getTitle = () => {
    switch (locationBeingEdited.mode) {
      case FormLocationMode.AddLocation:
        return 'Add new location';
      case FormLocationMode.EditLocation:
        return 'Edit location';
      case FormFloorMode.AddFloor:
        return 'Add new floor';
      case FormFloorMode.EditFloor:
        return 'Edit floor';
      case FormAreaMode.AddArea:
        return 'Create floor area';
      case FormAreaMode.EditArea:
        return 'Edit floor area';
      default:
        return '';
    }
  };

  const { isFetching, isError, error, refetch } = useQuery<
    { value: Location[]; ['@odata.count']: number },
    Error
  >(
    ['locations'],
    () =>
      fetchLocations({
        searchElement: deferredSearchQuery,
        skip: searchParams.get('search') ? skipSearch : 0,
        top: searchParams.get('search')
          ? perPage
          : Number(searchParams.get('top') || perPage),
        id: Number(locationId),
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
    }
  );
  const { refetch: refetchFloors } = useQuery<
    { value: Floor[]; ['@odata.count']: number },
    Error
  >(
    ['floors'],
    () =>
      locationId
        ? fetchFloors({ id: Number(locationId) })
        : { value: [], ['@odata.count']: 0 },
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data) {
          setFloorData(data.value);
        }
      },
    }
  );
  const { refetch: refetchAreas } = useQuery<
    { value: Area[]; ['@odata.count']: number },
    Error
  >(
    ['areas'],
    () =>
      locationId
        ? fetchAreas({ id: Number(locationId) })
        : { value: [], ['@odata.count']: 0 },
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data) {
          setAreaData(data.value);
        }
      },
    }
  );

  const setData = useCallback(
    async ({
      value: data,
      ['@odata.count']: dataCount,
    }: {
      value: Location[];
      ['@odata.count']: number;
    }) => {
      setIsLoading(true);
      if (data && !searchParams.get('search')) {
        setLocations([...(data || [])]);
        setHasMore(dataCount > Number(searchParams.get('top') || perPage));
      }

      if (searchParams.get('search')) {
        if (!locations?.length) {
          const response = await fetchLocations({
            top: Number(searchParams.get('top') || perPage),
            skip: 0,
          });
          if (response && response.value) {
            setLocations(response.value);
            setHasMore(dataCount > Number(searchParams.get('top')));
          }
        }
        setSearchResult([
          ...(skipSearch === 0 ? [] : searchResult || []),
          ...(data || []),
        ]);
        setSearchHasMore(dataCount > skipSearch + perPage);
      }
      setIsLoading(false);
    },
    [locations, searchParams.get('search'), searchPageNumber, skipSearch]
  );

  const observer: any = useRef();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isRefetch || searchParams.get('search') || locationId) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && node !== observer.current) {
          searchParams.set(
            'top',
            String(perPage * (Number(searchParams.get('page') || 1) + 1))
          );
          searchParams.set(
            'page',
            String(Number(searchParams.get('page') || 1) + 1)
          );
          setSearchParams(searchParams);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isRefetch, hasMore]
  );

  const observerSearch: any = useRef();
  const lastSearchElementRef = useCallback(
    (node: any) => {
      if (isRefetch || !searchParams.get('search')) return;
      if (observerSearch.current) observerSearch.current.disconnect();
      observerSearch.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          searchHasMore &&
          node !== observerSearch.current
        ) {
          setSearchPageNumber(searchPageNumber + 1);
          setSkipSearch(perPage * searchPageNumber);
        }
      });
      if (node) observerSearch.current.observe(node);
    },
    [isRefetch, searchHasMore, searchParams.get('search')]
  );

  const mutationRequst = (params: LocationEditData) => {
    delete params.item?.Attachments;
    delete params.item?.Images;
    switch (locationBeingEdited.mode) {
      case FormLocationMode.AddLocation: {
        setFiles([]);
        return addLocation(params, files);
      }
      case FormLocationMode.EditLocation: {
        setFiles([]);
        setDeletedImages([]);
        return updateLocation(params, files, deletedImages);
      }
      case FormLocationMode.DeleteLocation: {
        return deleteLocation(params.item?.LocationId);
      }
      case FormFloorMode.AddFloor: {
        setFiles([]);
        return addFloor(params, files, currentLocation?.LocationId);
      }
      case FormFloorMode.EditFloor: {
        setFiles([]);
        setDeletedImages([]);
        return updateFloor(params, files, deletedImages);
      }
      case FormFloorMode.DeleteFloor: {
        return deleteFloor(params.item?.FloorId);
      }
      case FormAreaMode.AddArea: {
        setFiles([]);
        return addArea(params, files);
      }
      case FormAreaMode.EditArea: {
        setFiles([]);
        setDeletedImages([]);
        return updateArea(params, files, deletedImages);
      }
      case FormAreaMode.DeleteArea: {
        return deleteArea(params.item?.AreaId);
      }
      default:
        return addLocation(params, files);
    }
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationRequst, {
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
      if (floorsEdited || floorDeleted) {
        queryClient.invalidateQueries(['floors']);
        setLocationBeingEdited({
          item: locationBeingEdited.item,
          mode: undefined,
        });
      }
      if (areasEdited || areaDeleted) {
        queryClient.invalidateQueries(['areas']);
        queryClient.invalidateQueries(['floors']);
        setLocationBeingEdited({
          item: locationBeingEdited.item,
          mode: undefined,
        });
      }
      if (locationsEdited || locationDeleted) {
        setLocationBeingEdited(defaultEditData);
      }
    },
  });

  const onSubmit = (data: LocationEditData) => {
    mutation.mutate({
      item: {
        ...data.item,
        LocationId: data.item?.LocationId,
        FloorId: selectedFloor?.FloorId || data.item?.FloorId,
      },
      mode: data.mode,
    });
  };

  useMemo(() => {
    if (locationId) {
      const currLocation = locations.find(
        (location) => location.LocationId === Number(locationId)
      );
      setCurrentLocation(currLocation);
      setOpenDetails(Number(locationId));

      if (floorData?.length) {
        const floorFiltred = floorData.filter(
          (floor) => Number(locationId) === floor.LocationId
        );

        setFiltredFloor(floorFiltred);
        if (!selectedFloor) {
          setSelectedFloor(floorFiltred[0]);
        } else {
          setSelectedFloor(
            floorFiltred.find(
              (floor) => floor.FloorId === selectedFloor.FloorId
            )
          );
        }
      }
      if (!floorData?.length) {
        setFiltredFloor([]);
      }
      if (areaData?.length) {
        const areafiltred = areaData.filter(
          (area) => Number(locationId) === area.LocationId
        );
        setAllAreas(areafiltred);
      }
      if (!areaData?.length) {
        setAllAreas([]);
      }
    }
    if (!locationId) {
      setSelectedFloor(undefined);
      setLocationBeingEdited(defaultEditData);
    }
  }, [locationId, areaData, floorData, selectedFloor, locations]);

  useMemo(() => {
    if (floorId && filtredFloor) {
      const currentfloor = filtredFloor.find(
        (floor) => floor.FloorId === Number(floorId)
      );

      setSelectedFloor(currentfloor);
      setOpenFloorDetails(Number(floorId));

      if (areaId && filtredArea) {
        setOpenAreaDetails(Number(areaId));
      }
    }
  }, [floorId, areaId, filtredFloor, filtredArea]);

  const refecthFloorData = async () => {
    await refetchAreas();
    await refetchFloors();
  };

  useEffect(() => {
    if (locationId) {
      refecthFloorData();
    }
  }, [locationId]);

  const updateAreaList = useCallback(() => {
    if (selectedFloor) {
      const areafiltred = allAreas.filter(
        (area) => area.FloorId === selectedFloor.FloorId
      );
      setFiltredArea(areafiltred);
    } else {
      setFiltredArea([]);
    }
  }, [selectedFloor, allAreas]);

  useEffect(() => {
    updateAreaList();
  }, [selectedFloor, allAreas]);

  useEffect(() => {
    if (mutation.variables) {
      const data = mutation.variables;

      if (data.mode && mutation.isSuccess) {
        showOkToast(notificationMessages[data.mode]);
      } else if (mutation.isError) {
        showErrorToast(notificationMessages['error']);
      }
    }
  }, [mutation.variables, mutation.isSuccess, mutation.isError]);

  const refetchData = async () => {
    setIsRefetch(true);
    await refetch();
    setIsRefetch(false);
  };

  useEffect(() => {
    if (!locationId) {
      refetchData();
    }
    if (!deferredSearchQuery) {
      setSearchResult([]);
    }
  }, [searchPageNumber]);

  const deleteMode =
    locationBeingEdited.mode === FormLocationMode.DeleteLocation ||
    locationBeingEdited.mode === FormFloorMode.DeleteFloor ||
    locationBeingEdited.mode === FormAreaMode.DeleteArea;

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    styles: defaultTheme,
    disableDoubleClickZoom: true,
    keyboardShortcuts: false,
  };

  const mapRef = useRef(undefined);

  const onLoad = useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback((map: any) => {
    mapRef.current = undefined;
  }, []);

  return (
    <div>
      <PageHeader pageName={'Locations'}>
        <LocationSearch
          searchResult={searchResult}
          setLocations={setLocations}
          lastElementRef={lastSearchElementRef}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <Button
          className={'bg-brand ml-4'}
          leftIcon={<Plus size={20} />}
          size="md"
          disabled={!currentUser.isAdmin}
          onClick={() =>
            setLocationBeingEdited({
              item: undefined,
              mode: FormLocationMode.AddLocation,
            })
          }
        >
          Add location
        </Button>
      </PageHeader>

      <LocationList
        locations={locations}
        hasOverlay={isFetching || isLoading}
        locationBeingEdited={locationBeingEdited}
        setLocationBeingEdited={setLocationBeingEdited}
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        openAreaDetails={openAreaDetails}
        setOpenAreaDetails={setOpenAreaDetails}
        openFloorDetails={openFloorDetails}
        setOpenFloorDetails={setOpenFloorDetails}
        isError={isError}
        error={error}
        areas={filtredArea}
        floors={filtredFloor}
        selectedFloor={selectedFloor}
        setSelectedFloor={setSelectedFloor}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        isLoaded={isLoaded}
        center={center}
        mapOptions={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
        currentUser={currentUser}
        lastElementRef={lastElementRef}
      />
      {locationBeingEdited.mode && !deleteMode ? (
        <CreateEditModal
          title={getTitle()}
          onSubmit={onSubmit}
          data={locationBeingEdited}
          opened={getCurrentType()}
          onClose={() => {
            setLocationBeingEdited(
              openDetails
                ? { item: locationBeingEdited.item, mode: undefined }
                : defaultEditData
            );
            setFiles([]);
            setDeletedImages([]);
          }}
          isMutating={mutation.isLoading}
          setFiles={setFiles}
          setDeletedImages={setDeletedImages}
          deletedImages={deletedImages}
          isLoaded={isLoaded ?? false}
          center={center}
          mapOptions={mapOptions}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
      ) : (
        ''
      )}
      {deleteMode ? (
        <DeleteModal
          item={locationBeingEdited.item}
          mode={locationBeingEdited.mode}
          opened={openDeleteModal()}
          isMutating={mutation.isLoading}
          hasMutationError={isError}
          onClose={() =>
            setLocationBeingEdited(
              openDetails
                ? { item: locationBeingEdited.item, mode: undefined }
                : defaultEditData
            )
          }
          onSubmit={onSubmit}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Locations;
